import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AuthenticationRoutingModule } from './authentication-routing.module';
import { LoginComponent } from './components/login/login.component';
import { ForgotComponent } from './components/forgot/forgot.component';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { MailLoginComponent } from './components/mail-login/mail-login.component';
import { ReactiveFormsModule } from '@angular/forms';
import { MailRegisterComponent } from './components/mail-register/mail-register.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { LogoutComponent } from './components/logout/logout.component';

@NgModule({
  declarations: [
    LoginComponent,
    ForgotComponent,
    MailLoginComponent,
    MailRegisterComponent,
    LogoutComponent   
  ],
  imports: [
    CommonModule,
    AuthenticationRoutingModule,
    AngularSvgIconModule.forRoot(),
    ReactiveFormsModule,
    NgbModule
  ]
})
export class AuthenticationModule { 
 
}

<div class="container section mt-sm-5">
    <div class="row  mb-1"><div class="col p-0"><div class="topbar"><h2>ΦΙΛΑΘΛΟΙ</h2></div></div></div>
    <div class="row infoContentBox no-gutters">
        <div class="col p-0">
            <div class="infoContainer">
                <ul>
                    <li routerLink="/article/sindesmos-filathlon-vourligans">
                        <svg-icon src="assets/images/info.svg" [svgStyle]="{ 'width.px':26,'height.px':26,'fill':'#E5446D' }"></svg-icon> ΣΥΝΔΕΣΜΟΣ ΦΙΛΑΘΛΩΝ VOURLIGANS
                    </li>
                    <li ngbTooltip="Υπό κατασκευή">
                        <svg-icon src="assets/images/info.svg" [svgStyle]="{ 'width.px':26,'height.px':26,'fill':'#E5446D' }"></svg-icon> ΣΥΝΔΕΣΜΟΣ ΦΙΛΑΘΛΩΝ A.O. ΚΕΡΚΥΡΑ ΑΘΗΝΩΝ
                    </li>                  
                    <li>
                        <a href="https://www.aokerkyra.com/phpBB3_" target="_blank"><svg-icon src="assets/images/info.svg" [svgStyle]="{ 'width.px':26,'height.px':26,'fill':'#E5446D' }"></svg-icon> FORUM ΦΙΛΑΘΛΩΝ</a>
                    </li>
                </ul>
            </div>
        </div>
        <div class="col p-0 d-none d-sm-block">
            <img class="img-fluid" [src]="environment.imagePath+'images/oldfans.webp'" >
        </div>
    </div>
</div>
<!--fans MAP-->
<router-outlet style="height:600px"></router-outlet>
<div class="container section mt-5">
    <div class="row mt-5 mb-1"><div class="col p-0"><div class="topbar"><h2>ΕΘΕΛΟΝΤΙΚΕΣ ΔΡΑΣΕΙΣ</h2></div></div></div>
    <div class="row mt-1">
        <div class="col-md-4" *ngFor="let item of volunteer">
            <app-single-new-preview [newsObj]="item">

            </app-single-new-preview>
        </div>       
    </div>
</div>

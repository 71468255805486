import { FormStyle, getLocaleMonthNames, TranslationWidth } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import * as moment from 'moment';
import { League, MonthMatches } from 'src/app/shared/models/match';
import { ApiMatchesServiceService } from 'src/app/shared/services/api-matches-service.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-listgames',
  templateUrl: './listgames.component.html',
  styleUrls: ['./listgames.component.scss']
})
export class ListgamesComponent implements OnInit {

  constructor(private router:ActivatedRoute,private matchApi:ApiMatchesServiceService, private meta:Meta, private titleService: Title) { }
  months=[new MonthMatches(),new MonthMatches()];
  earliestDate:Date;
  latestDate:Date;
  type="upcoming";
  isChamp=true;
  leagues:League[];
  leagueTables:League[];
  environment=environment;
  ngOnInit(): void {

    

    this.type=this.router.snapshot.data["type"];
    this.months=[];
    //console.log(this.router.data.type);
    this.matchApi.getLeaguesOfSeason(environment.currentSeason).subscribe(_res=>{
      this.leagues=_res;
      this.leagueTables=this.leagues.filter(lea=>(lea.lea_ischamp));
    })

    if(this.type=="upcoming"){     
      var date=new Date();
      var currentMonth=date.getMonth()+1;  
      var year=date.getFullYear();
      
      var nextMonth=moment(date).add(1,'months').month()+1;
      var nextYear=moment(date).add(1,'months').year();
      this.earliestDate=date;
      this.latestDate=moment(date).add(1,'months').toDate();
      this.matchApi.getMatchPerMonth(currentMonth,year).subscribe(_res=>{
        var mm=new MonthMatches();
        mm.monthName=getLocaleMonthNames('el',FormStyle.Standalone,TranslationWidth.Wide)[currentMonth-1];
        mm.matches=_res;
        this.months[0]=mm;
        //console.log(this.months);
      });
      this.matchApi.getMatchPerMonth(nextMonth,nextYear).subscribe(_res=>{
        var mm=new MonthMatches();
        mm.monthName=getLocaleMonthNames('el',FormStyle.Standalone,TranslationWidth.Wide)[nextMonth-1];
        mm.matches=_res;
        this.months[1]=mm;
        //console.log(this.months);
      });
  }else{
    this.router.params.subscribe(_params=>{
      this.months=[];
      var league=_params.league;
      this.matchApi.getLeagueMatches(league).subscribe(_res=>{
        var mm=new MonthMatches();
        mm.monthName=_res[0].league.lea_name;
        mm.matches=_res;
        this.months.push(mm);
        if(mm.matches[0].league.lea_ischamp){
          this.isChamp=true;
        }else{
          this.isChamp=false;
        }
      })
    })   
  }
  this.titleService.setTitle('Πρόγραμμα αγώνων - Α.Ο. Κέρκυρα');
    this.meta.addTags([
      { name: 'keywords', content: '' },
      { name: 'robots', content: 'index, follow' },
      {name:'description',content:'Λίστα με τους αγώνες της σαιζόν για τον Α.Ο. Κέρκυρα'},
      {property:'title', content:'Πρόγραμμα αγώνων - Α.Ο. Κέρκυρα'},
      {property:'og:title',content:'Πρόγραμμα αγώνων - Α.Ο. Κέρκυρα'},
      {property:'og:image',content:'https://www.aokerkyra.com/images/ao_kerkyra_1968_400x400.png'}
    ]);   
}

  getPrevMonth = function(){
    var monthDate=moment(this.earliestDate).subtract(1,'month');
    this.earliestDate=monthDate;
    this.matchApi.getMatchPerMonth(monthDate.month()+1,monthDate.year()).subscribe(_res=>{
      var mm=new MonthMatches();
      mm.monthName=getLocaleMonthNames('el',FormStyle.Standalone,TranslationWidth.Wide)[monthDate.month()];
      mm.matches=_res;
      this.months=[mm].concat(this.months);
      //this.months.push(mm);
      //console.log(this.months);
     });
  }

  getNextMonth = function(){
    var monthDate=moment(this.latestDate).add(1,'month');
    this.latestDate=monthDate;
    this.matchApi.getMatchPerMonth(monthDate.month()+1,monthDate.year()).subscribe(_res=>{
      var mm=new MonthMatches();
      mm.monthName=getLocaleMonthNames('el',FormStyle.Standalone,TranslationWidth.Wide)[monthDate.month()];
      mm.matches=_res;
      this.months.push(mm);
      //console.log(this.months);
     });
  }


}

<div class="featuredNews" [style.background-image]="headerImageUrl"  [ngClass]="{'touch':isMobile,'ghost':(headerTitle=='ghost')}">
    <div class="clicker" routerLink="/article/{{link}}"></div>
    <div class="text-holder" routerLink="/article/{{link}}" >      
        <h3 [innerHtml]="headerTitle"></h3>
    </div>
    <div class="snp-footer">
        <div class="before" *ngIf="!newsObj.new_isfeatured"><svg-icon src="assets/images/clock-svgrepo-com.svg" [svgStyle]="{'width.px':14,'height.px':14,'fill':'#f2f5eaff'}" ></svg-icon><span>Πριν απο {{before}}</span></div>
        <div><svg-icon src="assets/images/share-button-svgrepo-com.svg" [svgStyle]="{'width.px':20,'height.px':20,'fill':'#f2f5eaff'}" (click)="showShareDialogVar=true"></svg-icon></div>
    </div>
    <div class="socialShare" [ngClass]="{'active':showShareDialogVar}"><share-button [theme]="'circles-dark'"
        [button]="'facebook'"
        
        [url]="externalLink"
        [autoSetMeta]="false"
       ></share-button>
       <share-button [theme]="'circles-dark'"
       [button]="'twitter'"
       
       [url]="externalLink"
       [autoSetMeta]="false"
      ></share-button>
      <share-button [theme]="'circles-dark'"
      [button]="'messenger'"
      
      [url]="externalLink"
      [autoSetMeta]="false"
     ></share-button>
     <share-button [theme]="'circles-dark'"
     [button]="'email'"
     
     [url]="externalLink"
     [autoSetMeta]="false"
    ></share-button>
    <share-button [theme]="'circles-dark'"
    [button]="'copy'"
    
    [url]="externalLink"
    [autoSetMeta]="false"
   ></share-button>
    <svg-icon src="assets/images/iconmonstr-x-mark-thin.svg" [svgStyle]="{'width.px':24,'height.px':24,'fill':'#f2f5eaff'}" (click)="showShareDialogVar=false"></svg-icon></div>
</div>
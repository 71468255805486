import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SingleNewPreviewComponent } from 'src/app/news/single-new-preview/single-new-preview.component';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { ShareButtonModule } from 'ngx-sharebuttons/button';
import { RouterModule } from '@angular/router';
import { DatediffPipe, removeTonation } from 'src/app/shared/utils';
import { NgbDropdown, NgbDropdownItem, NgbDropdownModule, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { HammerModule } from '@angular/platform-browser';
import { NgxMasonryModule } from 'ngx-masonry';
import { LightboxModule } from 'ngx-lightbox';
import { PhotostoryStandAloneComponent } from 'src/app/photostories/photostory-stand-alone/photostory-stand-alone.component';



@NgModule({
  declarations: [SingleNewPreviewComponent,removeTonation,DatediffPipe,PhotostoryStandAloneComponent],
  imports: [
    CommonModule,    
    AngularSvgIconModule.forRoot(),
    ShareButtonModule,
    RouterModule,  
    NgxMasonryModule
  ],
  exports:[SingleNewPreviewComponent,removeTonation,DatediffPipe, PhotostoryStandAloneComponent]
})
export class ReusablesModule { }

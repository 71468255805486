import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { News } from 'src/app/shared/models/news';
import { ApiNewsService } from 'src/app/shared/services/api-news.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-fans-page',
  templateUrl: './fans-page.component.html',
  styleUrls: ['./fans-page.component.scss']
})
export class FansPageComponent implements OnInit {
  volunteer:News[]=[News.getGhostNew(),News.getGhostNew(),News.getGhostNew()]
  constructor(private newsApi:ApiNewsService, private meta:Meta, private titleService: Title) { }
  environment=environment;
 
  lat = 36.686365;
  lng = -24.3856037;
  ngOnInit(): void {
    this.newsApi.getNewsPaged(1,'ethelontikes-draseis').subscribe(_news=>{
      this.volunteer=_news.rows;
    })
    this.titleService.setTitle('Φίλαθλοι');
    this.meta.addTags([
      { name: 'keywords', content: 'φίλαθλοι, vourligans' },
      { name: 'robots', content: 'index, follow' },
      {name:'description',content:'Φίλαθλοι'},
      {property:'title', content:'Φίλαθλοι'},
      {property:'og:title',content:'Φίλαθλοι'},
      {property:'og:image',content:'https://aokerkyra.com/images/ao_kerkyra_1968_400x400.png'}
    ]);  
  }

}

import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { TableRow } from 'src/app/shared/models/table-row';
import { ApiMatchesServiceService } from 'src/app/shared/services/api-matches-service.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-maxi-league',
  templateUrl: './maxi-league.component.html',
  styleUrls: ['./maxi-league.component.scss']
})
export class MaxiLeagueComponent implements OnInit {

  constructor(private matchApi:ApiMatchesServiceService, private router:ActivatedRoute, private meta:Meta, private titleService: Title) { }
  ghost=true;
  leagueTable:TableRow[]=[];
  environment=environment;
  ngOnInit(): void {
    let ghostRow=new TableRow();
    ghostRow.tem_name='ghost';
    for(var i=0;i<12;i++){
      this.leagueTable.push(ghostRow);
    }
    this.router.params.subscribe(_params=>{
      this.matchApi.getLeagueTable(_params.league).subscribe(_res=>{
        this.leagueTable=_res;
        console.log(_res);
        this.ghost=false;
      });
    });
    this.titleService.setTitle('Βαθμολογία - Α.Ο. Κέρκυρα');
        this.meta.addTags([
          { name: 'keywords', content: '' },
          { name: 'robots', content: 'index, follow' },
          {name:'description',content:'Βαθμολογικός πίνακας'},
          {property:'title', content:'Βαθμολογία - Α.Ο. Κέρκυρα'},
          {property:'og:title',content:'Βαθμολογία - Α.Ο. Κέρκυρα'},
          {property:'og:image',content:'https://www.aokerkyra.com/images/ao_kerkyra_1968_400x400.png'}
        ]);
    
  }

  parseInt=function(item){
    return parseInt(item);
  }

}

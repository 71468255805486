import { Images } from "./images";

export class SliderNews {
    sli_id:number;
    Landscape:Images;
    Portrait:Images;
    sli_order:number;
    sli_title:string;
    sli_url:string;
}


/*

Landscape: {img_id: 6808, img_title: "euxes landscape 2021", img_path: "images/euxes2021slider.jpg",…}
Portrait: {img_id: 6809, img_title: "euxes portrait 2021", img_path: "images/euxes2021slider_portrait.jpg",…}
sli_id: 1
sli_landscape_img_id: "6808"
sli_order: 1
sli_portrait_img_id: "6809"
sli_title: "Ευχές απο την οικογένεια του Α.Ο. Κέρκυρα για το 2021"
sli_url: "/article/euxes-apo-ton-ao-kerkyra-gia-to-2021-video"

*/
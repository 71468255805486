import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/shared/services/auth.service';
import { MailchimpService } from 'src/app/shared/services/mailchimp.service';
import { ToastService } from 'src/app/shared/services/toast-service.service';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss'],
 })
export class RegisterComponent implements OnInit {

  
  
  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private toastApi:ToastService,
    private authApi:AuthService,
    private mc:MailchimpService
  ) { }

  form: FormGroup;
  loading = false;
  submitted = false;
  alerts=[];
    backpath='';
    backroute='';
  ngOnInit(): void {
    this.form = this.formBuilder.group({
      email: ['', [Validators.required,Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$")]],
      password:['',[Validators.required]],
      acceptTerms:[false,[Validators.requiredTrue]],
     acceptNewsletter:[false]
  });
  this.backpath=this.route.snapshot.data['back'];
  if(this.backpath=="mvp"){
    let matchid=this.route.parent.parent.parent.snapshot.params.id;
     this.backroute="/match/"+matchid+"/mvp/vote";
     console.log(this.backroute);
   }else if(this.backpath=="fans"){
     this.backroute='/fans/addPoint';
   }
  }

  get f() { return this.form.controls; }


  onSubmit(){
    this.loading=true;   
    this.submitted = true;    
    if(this.form.invalid){
      console.log(this.form);
      this.loading=false;
      return;
    }
    this.authApi.MailRegister(this.form.value.email,this.form.value.password).then(_data=>{
      //console.log(_data);
      if(this.form.controls.acceptNewsletter.value)
        this.mc.subscribe(this.form.value.email).subscribe(_res=>{
          
        });
    }).then(_user=>{
      this.router.navigateByUrl(this.backroute);
    }).catch(error=>{     
       if(error.code=="auth/email-already-in-use"){
         this.alerts.push({type:'warning',message:'Αυτό το email χρησιμοποιείται ήδη!'});
       }
    })
   
  }

}

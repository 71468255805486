import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import { filter, first } from 'rxjs';
import { MatchPlayers } from 'src/app/shared/models/match';
import { MVPRow, Player } from 'src/app/shared/models/player';
import { AnalyticsService } from 'src/app/shared/services/analytics.service';
import { ApiMatchesServiceService } from 'src/app/shared/services/api-matches-service.service';
import { ApiPlayersService } from 'src/app/shared/services/api-players.service';
import { AuthService } from 'src/app/shared/services/auth.service';

@Component({
  selector: 'app-mvp-vote-screen',
  templateUrl: './mvp-vote-screen.component.html',
  styleUrls: ['./mvp-vote-screen.component.scss']
})
export class MvpVoteScreenComponent implements OnInit {

  constructor(private route:ActivatedRoute, private playerApi:ApiPlayersService, private matchApi:ApiMatchesServiceService, private auth:AuthService, private router:Router, private formBuilder: FormBuilder, private analyticsService:AnalyticsService) { }
  mvps:MatchPlayers[]=[];
  total=0;
  form: FormGroup;
  loading=false;
  showButton=false;
  submitted=false;
  backroute="";
  votepassed=false;
  canuser=false;
  loaded=false;
  loggedin=this.auth.loginObservable;
  matchid=0;

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      player: ['', [Validators.required]],
    });
    this.matchid=this.route.parent.parent.snapshot.params.id;
    //console.log(matchid);
    this.backroute="/match/"+this.matchid+"/mvp/auth/logout";
    //console.log(this.auth.user);
    this.route.parent.parent.params.subscribe(_params=>{
      let matchid=_params.id;
      this.matchApi.getMatchDetails(matchid).subscribe(_res=>{
        console.log(moment(_res.mch_date).diff(moment(new Date()), "days"));
        if(moment(_res.mch_date).diff(moment(new Date()), "days")<7){          
          this.playerApi.getPlayersOfMatch(matchid).subscribe(_mvps=>{           
            this.mvps=_mvps.players;
            this.showButton=true;
            //console.log( this.mvps);
            //this.total=_mvps.reduce((acc:number,cur)=>acc+Number(cur.total_amount),0);       
            if(!!this.auth.getUserFromLocalStorage()){
              this.playerApi.canUserVote(matchid,this.auth.getUserFromLocalStorage().uid).subscribe(_can=>{
                this.loaded=true;
                if(_can){
                  this.canuser=true;
                }else{
                  this.canuser=false;
                }
              })
            }else{

            }
          });
        }else{
          //can't vote 
          this.votepassed=true;
        }
      })
    })
  }
  get f() { return this.form.controls; }
  numSequence(n: number): Array<number> { 
    return Array(n); 
  } 

  parseInt=function(item){
    return parseInt(item);
  }

  logout(){
    console.log(this.backroute)
    this.auth.logout(this.backroute);
    this.loggedin.subscribe(e=>{
      if(!e){
       console.log('on init');
       this.ngOnInit();
      }
    })
  }

  diff(a,b){
    //console.log(a);
    //console.log(b);
    return a-b;
  }

  onSubmit(){
    if(this.canuser){
    this.loading=true;   
    this.submitted = true;    

    this.auth.afAuth.idToken.subscribe(_tok=>{
      this.playerApi.addUserVote({matchid:this.matchid,player:this.form.value.player,uid:this.auth.user.uid},_tok).subscribe((_res)=>{
        this.analyticsService.trackEvent('User mvp','voting','');
        this.router.navigate(["/match/"+this.matchid+"/mvp/thanks"]);
      })
     
    })
  }
  }
}


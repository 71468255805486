import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { throwError } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Match } from '../models/match';
import { HomePageNews, News, NewsList } from '../models/news';
import { SliderNews } from '../models/slidernews';
import { catchError, retry } from 'rxjs/operators';
import { Webwatch } from '../models/webwatch';


const apiUrl = environment.serverapi;

@Injectable({
  providedIn: 'root'
})
export class ApiNewsService {

  constructor(private http: HttpClient, private router:ActivatedRoute) { }
  handleError(error) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // client-side error
      errorMessage = `Error: ${error.error.message}`;
    } else {
      // server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    //window.alert(errorMessage);
    return throwError(errorMessage);
  }
 

  getNewsPaged(page: number,category:string) {
    var url='';
    if(category=='all'){
      url = `${apiUrl}articles/${page}/`;
    }else{
      url = `${apiUrl}articles/${category}/${page}/`;
    }
    return this.http.get<NewsList>(url)
    .pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  getNewsTop() {
    var url='';   
    url = `${apiUrl}articles/top/`;    
    return this.http.get<NewsList>(url)
    .pipe(
      retry(1),
      catchError(this.handleError)
    );
  }
  getNewDetails(newid:number) {
    var url='';   
    url = `${apiUrl}articledetails/${newid}`;    
    return this.http.get<News>(url)
    .pipe(
      retry(1),
      catchError(this.handleError)
    );
  }
  getNewDetailsBySlug(slug:string) {
    var url='';   
    url = `${apiUrl}articledetailsSlug/${slug}`;    
    return this.http.get<News>(url)
    .pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  getSlider(){
    var url='';   
    url = `${apiUrl}slider`;    
    return this.http.get<SliderNews[]>(url)
    .pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  getNewsOfMatch(matchid:number){
    var url='';   
    url = `${apiUrl}articlesOf/${matchid}`;    
    return this.http.get<Match>(url)
    .pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  getHomePageNews(){
    var url='';   
    url = `${apiUrl}homepagenews/`;    
    return this.http.get<HomePageNews[]>(url)
    .pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  getWebWatch(){
    var url='';   
    url = `${apiUrl}webwatch/`;    
    return this.http.get<Webwatch[]>(url)
    .pipe(
      retry(1),
      catchError(this.handleError)
    );
  }
}

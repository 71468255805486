import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-followus',
  templateUrl: './followus.component.html',
  styleUrls: ['./followus.component.scss']
})
export class FollowusComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}

import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Player, StatsRow } from 'src/app/shared/models/player';
import { ApiMatchesServiceService } from 'src/app/shared/services/api-matches-service.service';
import { ApiPlayersService } from 'src/app/shared/services/api-players.service';

@Component({
  selector: 'app-season-roster',
  templateUrl: './season-roster.component.html',
  styleUrls: ['./season-roster.component.scss']
})
export class SeasonRosterComponent implements OnInit {

  constructor(private router:ActivatedRoute, private playerApi:ApiPlayersService, private matchApi:ApiMatchesServiceService) { }
  oldplayers:Player[]=[];
  statsRows:StatsRow[]=[];
  ngOnInit(): void {
    this.router.parent.params.subscribe(_params=>{
      let seasonid=_params.id;      
      this.playerApi.getOldPlayers(seasonid).subscribe(_plrs=>{
        this.oldplayers=_plrs;
        this.matchApi.getStatsOfSeason(seasonid).subscribe(_stts=>{
          this.oldplayers.forEach(element => {
            if(!_stts.find(it=>it.plr_id==element.plr_id)){
              let ntst=new StatsRow();
              ntst.changes=0;
              ntst.matches1=0;
              ntst.matches1=0;
              ntst.plr_id=element.plr_id;
              ntst.plr_name=element.plr_name;
              ntst.red=0;
              ntst.plr_surname=element.plr_surname;
              ntst.plr_slug=element.plr_slug;
              ntst.yellow=0;
              
              _stts.push(ntst);
              
            }else{
              _stts.find(it=>it.plr_id==element.plr_id).shirtnumber=element.seasons[0].playerseasons.pss_number;
              
            }
            this.statsRows=_stts;
          });
        })
      })
    })
  }

}

import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ForgotComponent } from './components/forgot/forgot.component';
import { LoginComponent } from './components/login/login.component';
import { LogoutComponent } from './components/logout/logout.component';
import { MailLoginComponent } from './components/mail-login/mail-login.component';
import { MailRegisterComponent } from './components/mail-register/mail-register.component';

const routes: Routes = [ 
    {path:'',redirectTo:'login',pathMatch:'full'},
    {path:'login',component:LoginComponent,
    children:[
      {path:'',component:MailLoginComponent},
      {path:'forgot',component: ForgotComponent},
      {path:'register',component: MailRegisterComponent},
     
    ]},
    {path:'logout',component:LogoutComponent}
    

];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AuthenticationRoutingModule { }

<div class="container mt-1 mt-lg-5">
    <div class="row topbar mb-1"><h2>ΤΕΡΜΑΤΟΦΥΛΑΚΕΣ</h2></div>
    <div class="row mb-1" >
        <div class="col-6 col-lg-3 col-sm-4 p-0" *ngFor="let item of goalies">
            <div class="playerProfile" routerLink="/player/{{item.plr_slug}}">
                <img src="{{environment.imagePath}}{{item.SmallImage.img_path || 'images/silhuette.png'}}" *ngIf="item.SmallImage" class="img-fluid playerFace">
                <img src="{{environment.imagePath}}images/silhuette.png" *ngIf="item.SmallImage==null" class="img-fluid playerFace">
                <div class="playerInfo">
                    <h4>{{item.plr_name}}</h4>
                    <h3>{{item.plr_surname}}</h3>
                    <span><img width="17px" src="{{environment.imagePath}}{{item.country.image.img_path}}">{{item.country.ctr_name | removeTonation | uppercase}}</span>
                </div>
            </div>
        </div>       
    </div>    
    <div class="row topbar mb-1"><h2>ΑΜΥΝΤΙΚΟΙ</h2></div>
    <div class="row mb-1">
        <div class="col-6 col-lg-3 col-sm-4 p-0" *ngFor="let item of defenders">
            <div class="playerProfile" routerLink="/player/{{item.plr_slug}}">
                <img src="{{environment.imagePath}}{{item.SmallImage.img_path}}" *ngIf="item.SmallImage" class="img-fluid playerFace">
                <img src="{{environment.imagePath}}images/silhuette.png" *ngIf="item.SmallImage==null" class="img-fluid playerFace">
                <div class="playerInfo">
                    <h4>{{item.plr_name}}</h4>
                    <h3>{{item.plr_surname}}</h3>
                    <span><img width="17px" src="{{environment.imagePath}}{{item.country.image.img_path}}">{{item.country.ctr_name | removeTonation | uppercase}}</span>
                </div>
            </div>
        </div>       
    </div>    
    <div class="row topbar mb-1"><h2>ΜΕΣΟΙ</h2></div>
    <div class="row mb-1">
        <div class="col-6 col-lg-3 col-sm-4 p-0" *ngFor="let item of midfielders">
            <div class="playerProfile" routerLink="/player/{{item.plr_slug}}">
                <img src="{{environment.imagePath}}{{item.SmallImage.img_path}}" *ngIf="item.SmallImage" class="img-fluid playerFace">
                <img src="{{environment.imagePath}}images/silhuette.png" *ngIf="item.SmallImage==null" class="img-fluid playerFace">
                <div class="playerInfo">
                    <h4>{{item.plr_name}}</h4>
                    <h3>{{item.plr_surname}}</h3>
                    <span><img width="17px" src="{{environment.imagePath}}{{item.country.image.img_path}}">{{item.country.ctr_name | removeTonation | uppercase}}</span>
                </div>
            </div>
        </div>       
    </div>    
    <div class="row topbar"><h2>ΕΠΙΘΕΤΙΚΟΙ</h2></div>
    <div class="row mb-1">
        <div class="col-6 col-lg-3 col-sm-4 p-0" *ngFor="let item of forwards">
            <div class="playerProfile" routerLink="/player/{{item.plr_slug}}">
                <img src="{{environment.imagePath}}{{item.SmallImage.img_path}}" *ngIf="item.SmallImage" class="img-fluid playerFace">
                <img src="{{environment.imagePath}}images/silhuette.png" *ngIf="item.SmallImage==null" class="img-fluid playerFace">
                <div class="playerInfo">
                    <h4>{{item.plr_name}}</h4>
                    <h3>{{item.plr_surname}}</h3>
                    <span><img width="17px" src="{{environment.imagePath}}{{item.country.image.img_path}}">{{item.country.ctr_name | removeTonation | uppercase}}</span>
                </div>
            </div>
        </div>       
    </div>   
    <div class="row topbar"><h2>ΤΕΧΝΙΚΟ TEAM</h2></div>
    <div class="row mb-1">
        <div class="col-6 col-lg-3 col-sm-4 p-0" *ngFor="let item of staff">
            <div class="playerProfile" routerLink="/player/{{item.plr_slug}}">
                <img src="{{environment.imagePath}}{{item.SmallImage.img_path}}" *ngIf="item.SmallImage" class="img-fluid playerFace">
                <img src="{{environment.imagePath}}images/silhuette.png" *ngIf="item.SmallImage==null" class="img-fluid playerFace">
                <div class="playerInfo">
                    <h4>{{item.plr_name}}</h4>
                    <h3>{{item.plr_surname}}</h3>
                    <span><img width="17px" src="{{environment.imagePath}}{{item.country.image.img_path}}">{{item.country.ctr_name | removeTonation | uppercase}}</span>
                </div>
            </div>
        </div>       
    </div>     
</div>
<div class="container section mt-sm-5">
    <div class="row  mb-1"><div class="col"><div class="topbar"><h2>ΕΙΣΙΤΗΡΙΑ</h2></div></div></div>
    <div class="row infoContentBox mt-3 no-gutters">
        <div class="col">
            <div class="infoContainer">
                <ul>
                    <li routerLink="/article/kykloforhsan-oi-kartes-diarkeias-toy-ao-kerkyra">
                        <svg-icon src="assets/images/info.svg" [svgStyle]="{ 'width.px':26,'height.px':26,'fill':'#E5446D' }"></svg-icon> ΕΙΣΙΤΗΡΙΑ ΔΙΑΡΚΕΙΑΣ 2022-2023
                    </li>
                    <li ngbTooltip="Δεν επιτρέπεται η είσοδος φιλάθλων, λόγω του COVID-19">
                        <svg-icon src="assets/images/info.svg" [svgStyle]="{ 'width.px':26,'height.px':26,'fill':'#E5446D' }" ></svg-icon> ΤΙΜΕΣ ΕΙΣΙΤΗΡΙΩΝ
                    </li>
                    <li ngbTooltip="Δεν επιτρέπεται η είσοδος φιλάθλων, λόγω του COVID-19">
                        <svg-icon src="assets/images/info.svg" [svgStyle]="{ 'width.px':26,'height.px':26,'fill':'#E5446D' }" ></svg-icon> ΠΡΟΣΒΑΣΙΜΟΤΗΤΑ
                    </li>
                    <li ngbTooltip="Δεν επιτρέπεται η είσοδος φιλάθλων, λόγω του COVID-19">
                        <svg-icon src="assets/images/info.svg" [svgStyle]="{ 'width.px':26,'height.px':26,'fill':'#E5446D' }" ></svg-icon> ΠΡΟΑΓΟΡΑ ΕΙΣΙΤΗΡΙΩΝ
                    </li>
                </ul>
            </div>
        </div>
        <div class="col p-0 d-none d-sm-block">
            <img class="img-fluid" src="https://aokerkyra.com/images/kerkyra9.webp">
        </div>
    </div>
</div>
<div class="container section mt-5">
    <div class="row mt-5 mb-1"><div class="col"><div class="topbar"><h2>ΜΕΛΗ</h2></div></div></div>
    <div class="row infoContentBox mt-3 no-gutters">
        <div class="col">
            <div class="infoContainer">
                <ul>
                    <li routerLink="/members/register">
                        <svg-icon src="assets/images/info.svg" [svgStyle]="{ 'width.px':26,'height.px':26,'fill':'#E5446D' }"></svg-icon> ΕΓΓΡΑΦΗ ΜΕΛΩΝ
                    </li>
                    <li routerLink="/article/ananeosi-sindromis">
                        <svg-icon src="assets/images/info.svg" [svgStyle]="{ 'width.px':26,'height.px':26,'fill':'#E5446D' }"></svg-icon> ΑΝΑΝΕΩΣΗ ΣΥΝΔΡΟΜΗΣ
                    </li>
                    <li routerLink="/article/syllogos-palaimaxon">
                        <svg-icon src="assets/images/info.svg" [svgStyle]="{ 'width.px':26,'height.px':26,'fill':'#E5446D' }"></svg-icon> ΣΥΛΛΟΓΟΣ ΠΑΛΑΙΜΑΧΩΝ
                    </li>                   
                </ul>
            </div>
        </div>
        <div class="col p-0 d-none d-sm-block">
            <img class="img-fluid" src="https://aokerkyra.com/images/dimotiko_theatro.webp">
        </div>
    </div>
</div>

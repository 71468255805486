import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ApiMatchesServiceService } from 'src/app/shared/services/api-matches-service.service';

@Component({
  selector: 'app-league-table',
  templateUrl: './league-table.component.html',
  styleUrls: ['./league-table.component.scss']
})
export class LeagueTableComponent implements OnInit {

  constructor(private router:ActivatedRoute, private matchApi:ApiMatchesServiceService) { }
  tableHtml:string;
  ngOnInit(): void {
    this.router.params.subscribe(_params=>{
      let leaid=_params.id;
      this.matchApi.getLeagueDetails(leaid).subscribe(_lea=>{
        this.tableHtml=_lea.lea_table;
      })
    })
  }

}

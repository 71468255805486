<div class="container mt-lg-5">
   
    <div class="text-holder" *ngIf="newObject!=null">
        <h4>{{category| removeTonation | uppercase}}</h4>
        <h3 [innerHtml]="title"></h3>
        
        <div class="entryDate" *ngIf="!newObject.new_isfeatured">{{date | date:'d.M.y hh:mm a' }}</div>
    </div>
    <div class="image-holder" *ngIf="featImage!='' && featImage!=null">
        <img src="{{featImage}}" />
    </div>
</div>
<div class="container" *ngIf="newObject!=null">
    <div class="row justify-content-center">
        <div class="col-lg-8 col-md-12 text-content mt-5" [innerHtml]="content">
        </div>
    </div>
    <div>
        <app-photostory-stand-alone *ngIf="imc_id!=null && imc_id>0" [imcObj]="imc_id"></app-photostory-stand-alone>
    </div>
    <div class="row justify-content-center">
        <div class="socialShare text-center mt-3">
            <share-button [theme]="'circles-dark'" [button]="'facebook'" [size]="-4" [autoSetMeta]="true">
            </share-button>
            <share-button [theme]="'circles-dark'" [button]="'twitter'" [size]="-4" [autoSetMeta]="false">
            </share-button>
            <share-button [theme]="'circles-dark'" [button]="'messenger'" [size]="-4" [autoSetMeta]="false">
            </share-button>
            <share-button [theme]="'circles-dark'" [button]="'email'" [size]="-4" [autoSetMeta]="false"></share-button>
            <share-button [theme]="'circles-dark'" [button]="'copy'" [size]="-4" [autoSetMeta]="false"></share-button>

        </div>
    </div>
</div>
<p *ngFor="let alert of alerts">
    <ngb-alert [type]="alert.type" (close)="close(alert)">{{ alert.message }}</ngb-alert>
</p>
<form [formGroup]="form" (ngSubmit)="onSubmit()" *ngIf="!showMessage">
    Αν έχετε ήδη πραγματοποιήσει εγγραφή συμπληρώστε το e-mail σας παρακάτω και θα σας αποσταλούν οδηγίες για την αλλαγή του Password.<br/><br/>
    <div class="form-row">
        <label for="username">E-mail</label>
        <input type="email" formControlName="email" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.email.errors }" />
        <div *ngIf="submitted && f.email.errors" class="invalid-feedback">     
                
            <div *ngIf="f.email.errors.required">Tο e-mail είναι απαραίτητο</div>
            <div *ngIf="f.email.errors.pattern">Tο e-mail δεν είναι έγκυρο</div>
        </div>
    </div>    
    <div class="form-row">
        <button [disabled]="loading" class="swipeButton">
            <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
            <span>ΑΠΟΣΤΟΛΗ</span>
        </button>      
    </div>
</form>
<div *ngIf="showMessage">
    Σας έχουμε στείλει ενα e-mail με οδηγίες για την αλλαγή του password σας.
    <a routerLink="/fans/login" class="btn btn-link">Επιστροφή</a>
</div>
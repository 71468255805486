import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AnalyticsService } from 'src/app/shared/services/analytics.service';
import { AuthService } from 'src/app/shared/services/auth.service';
import { ToastService } from 'src/app/shared/services/toast-service.service';

@Component({
  selector: 'app-map-login',
  templateUrl: './map-login.component.html',
  styleUrls: ['./map-login.component.scss']
})
export class MapLoginComponent implements OnInit {
  constructor(private authApi:AuthService, private router:Router, private analyticsService:AnalyticsService){}
  
  ngOnInit(): void {
    
  }

  loginFacebook(){
    this.authApi.loginWithFacebook('/fans/addPoint');
    this.analyticsService.trackEvent('Fans map','Login','facebook');
  }

  loginGoogle(){
    
    this.authApi.loginWithGoogle('/fans/addPoint');    
    this.analyticsService.trackEvent('Fans map','Login','google');
  }

  loginTwitter(){
    this.authApi.loginWithTwitter('/fans/addPoint');
    this.analyticsService.trackEvent('Fans map','Login','twitter');
  }

}

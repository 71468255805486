import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Meta, Title } from '@angular/platform-browser';
import { RECAPTCHA_V3_SITE_KEY } from 'ng-recaptcha';
import { NgRecaptcha3Service } from 'ng-recaptcha3';
import { environment } from 'src/environments/environment';
import { AnalyticsService } from '../shared/services/analytics.service';
import { ContactService } from '../shared/services/contact.service';


@Component({
  selector: 'app-contact-page',
  templateUrl: './contact-page.component.html',
  styleUrls: ['./contact-page.component.scss']
})
export class ContactPageComponent implements OnInit {

  constructor(
    private formBuilder: FormBuilder,
    private contactService:ContactService,
    private rcp:NgRecaptcha3Service,
    private meta:Meta, 
    private titleService: Title,
    private analyticsService:AnalyticsService
  ) { }
  form: FormGroup;
  loading = false;
  submitted = false;
  alerts=[];
  environment=environment;
  lat = 39.612086535774345;
  lng = 19.91690013337743;
    notsubmitted=true;
  ngOnInit(): void {
    
    this.rcp.init('6Lf1ceUUAAAAAEAjwaDJXUApGRNKdO5PWJ4tWXoH').then(status=>{console.log(status)});
    this.form = this.formBuilder.group({
      email: ['', [Validators.required,Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$")]],
      name: ['', [Validators.required,]],
      subject: ['', [Validators.required,]],
      message: ['', [Validators.required,]], 
      tel: ['', []],        
    });
    this.titleService.setTitle('Φόρμα επικοινωνίας - Α.Ο. Κέρκυρα');
    this.meta.addTags([
      { name: 'keywords', content: 'φόρμα επικοινωνίας' },
      { name: 'robots', content: 'index, follow' },
      {name:'description',content:'Φόρμα επικοινωνίας'},
      {property:'title', content:'Φόρμα επικοινωνίας - Α.Ο. Κέρκυρα'},
      {property:'og:title',content:'Φόρμα επικοινωνίας - Α.Ο. Κέρκυρα'},
      {property:'og:image',content:'https://aokerkyra.com/images/ao_kerkyra_1968_400x400.png'}
    ]);  
  }

  get f() { return this.form.controls; }
  public ngOnDestroy() {
    this.rcp.destroy();
  }
  onSubmit(){
    //console.log(this.form.value);
    this.loading=true;
    this.submitted = true;    
    
    if(this.form.invalid){
      //console.log
      this.loading=false;
      return;
    }
    this.rcp.getToken().then(token => {
      const formData = this.form.value;
      formData.recaptchaToken = token;
      //console.log(formData);
      this.contactService.sendContactForm(formData).subscribe(_res=>{
        this.loading=false;
        this.notsubmitted=false;
        this.analyticsService.trackEvent('Contact form','Sent','');
      },_err=>{
        this.loading=false;
        console.log(_err);
      })
      // send data with token to backend
      //this.http.post(url,formData) ....
    })
  }  
}

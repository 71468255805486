<div class="container section mt-sm-5">
    <div class="row  mb-1">
        <div class="col">
            <div class="topbar">
                <h2>ΦΟΡΜΑ ΕΓΓΡΑΦΗΣ ΜΕΛΟΥΣ</h2>
            </div>
        </div>
    </div>
    <div class="row infoContentBox mt-3 no-gutters">
        <div class="col">
            <div class="infoContainer" *ngIf="notsubmitted">                
                <p><b>Συμπληρώστε την φόρμα εγγραφής μέλους. 
                    <br/>Ένας συνεργάτης μας θα επικοινωνήσει μαζί σας για περισσότερες λεπτομέρειες:</b></p>
                <form [formGroup]="form" (ngSubmit)="onSubmit()">
                    <div class="form-row row">
                        <div class="form-group col-md-6">
                            <label for="surname">Επώνυμο*</label>
                            <input type="text" formControlName="surname" class="form-control"
                                [ngClass]="{ 'is-invalid': submitted && f.surname.errors }" />
                            <div *ngIf="submitted && f.surname.errors" class="invalid-feedback">
                                <div *ngIf="f.surname.errors.required">Tο επώνυμο είναι απαραίτητο</div>
                            </div>
                        </div>
                        <div class="form-group col-md-6">
                            <label for="name">Όνομα*</label>
                            <input type="text" formControlName="name" class="form-control"
                                [ngClass]="{ 'is-invalid': submitted && f.name.errors }" />
                            <div *ngIf="submitted && f.name.errors" class="invalid-feedback">
                                <div *ngIf="f.name.errors.required">Tο όνομα είναι απαραίτητο</div>
                                
                            </div>
                        </div>
                    </div>
                    <div class="form-row row">
                        <div class="form-group col-md-6">
                            <label for="fathername">Όνομα πατέρα*</label>
                            <input type="text" formControlName="fathername" class="form-control"
                                [ngClass]="{ 'is-invalid': submitted && f.fathername.errors }" />
                            <div *ngIf="submitted && f.fathername.errors" class="invalid-feedback">
                                <div *ngIf="f.fathername.errors.required">Tο όνομα πατέρα είναι απαραίτητο</div>
                            </div>
                        </div>
                        <div class="form-group col-md-6">
                            <label for="mothername">Όνομα μητέρας*</label>
                            <input type="text" formControlName="mothername" class="form-control"
                                [ngClass]="{ 'is-invalid': submitted && f.mothername.errors }" />
                            <div *ngIf="submitted && f.mothername.errors" class="invalid-feedback">
                                <div *ngIf="f.mothername.errors.required">Tο όνομα μητέρας είναι απαραίτητο</div>
                               
                            </div>
                        </div>
                    </div>
                    <div class="form-row row">
                        <div class="form-group col-md-4">
                            <label for="birthyear">Έτος γέννησης*</label>
                            <input type="text" formControlName="birthyear" class="form-control"
                                [ngClass]="{ 'is-invalid': submitted && f.birthyear.errors }" />
                            <div *ngIf="submitted && f.birthyear.errors" class="invalid-feedback">
                                <div *ngIf="f.birthyear.errors.required">Tο έτος γέννησης είναι απαραίτητο</div>
                            </div>
                        </div>
                        <div class="form-group col-md-4">
                            <label for="birthplace">Τόπος γέννησης</label>
                            <input type="email" formControlName="birthplace" class="form-control"
                                [ngClass]="{ 'is-invalid': submitted && f.birthplace.errors }" />
                            
                        </div>
                        <div class="form-group col-md-4">
                            <label for="profession">Επάγγελμα</label>
                            <input type="email" formControlName="profession" class="form-control"
                                [ngClass]="{ 'is-invalid': submitted && f.profession.errors }" />                            
                        </div>
                    </div>
                    <h2>Στοιχεία διεύθυνσης</h2>
                    <div class="form-row row">                        
                        <div class="form-group col-md-6">
                            <label for="street">Οδός*</label>
                            <input type="text" formControlName="street" class="form-control"
                                [ngClass]="{ 'is-invalid': submitted && f.street.errors }" />
                            <div *ngIf="submitted && f.street.errors" class="invalid-feedback">
                                <div *ngIf="f.street.errors.required">Η οδός είναι απαραίτητη</div>
                            </div>
                        </div>
                        <div class="form-group col-md-3">
                            <label for="streetNo">Αριθμός*</label>
                            <input type="text" formControlName="streetNo" class="form-control"
                                [ngClass]="{ 'is-invalid': submitted && f.streetNo.errors }" />
                            <div *ngIf="submitted && f.streetNo.errors" class="invalid-feedback">
                                <div *ngIf="f.streetNo.errors.required">Ο αριθμός είναι απαραίτητος</div>                                
                            </div>
                        </div>
                        <div class="form-group col-md-3">
                            <label for="zip">Ταχ. κώδικας*</label>
                            <input type="text" formControlName="zip" class="form-control"
                                [ngClass]="{ 'is-invalid': submitted && f.zip.errors }" />
                            <div *ngIf="submitted && f.zip.errors" class="invalid-feedback">
                                <div *ngIf="f.zip.errors.required">Ο ταχυδρομικός κώδικας είναι απαραίτητος</div>                                
                            </div>
                        </div>
                    </div>
                    <div class="form-row row">
                        <div class="form-group col-md-6">
                            <label for="area">Περιοχή*</label>
                            <input type="text" formControlName="area" class="form-control"
                                [ngClass]="{ 'is-invalid': submitted && f.area.errors }" />
                            <div *ngIf="submitted && f.area.errors" class="invalid-feedback">
                                <div *ngIf="f.area.errors.required">Η περιοχή είναι απαραίτητη</div>
                            </div>
                        </div>
                        <div class="form-group col-md-6">
                            <label for="city">Πόλη*</label>
                            <input type="text" formControlName="city" class="form-control"
                                [ngClass]="{ 'is-invalid': submitted && f.city.errors }" />
                            <div *ngIf="submitted && f.city.errors" class="invalid-feedback">
                                <div *ngIf="f.city.errors.required">Η πόλη είναι απαραίτητη</div>

                            </div>
                        </div>
                    </div>
                    <h2>Στοιχεία επικοινωνίας</h2>
                    <div class="form-row row">
                        <div class="form-group col-md-4">
                            <label for="tel">Τηλέφωνο επικοινωνίας 1*</label>
                            <input type="tel" formControlName="tel" class="form-control"
                                [ngClass]="{ 'is-invalid': submitted && f.tel.errors }" />
                            <div *ngIf="submitted && f.tel.errors" class="invalid-feedback">
                                <div *ngIf="f.tel.errors.required">Tο τηλέφωνο είναι απαραίτητο</div>
                            </div>
                        </div>
                        <div class="form-group col-md-4">
                            <label for="tel2">Τηλέφωνο επικοινωνίας 2</label>
                            <input type="tel" formControlName="tel2" class="form-control"
                                [ngClass]="{ 'is-invalid': submitted && f.tel2.errors }" />
                          
                        </div>
                        <div class="form-group col-md-4">
                            <label for="tel3">Τηλέφωνο επικοινωνίας 3</label>
                            <input type="tel" formControlName="tel3" class="form-control"
                                [ngClass]="{ 'is-invalid': submitted && f.tel3.errors }" />                          
                        </div>
                    </div>
                    <div class="form-row row">
                    <div class="form-group col-md-4">
                        <label for="emial">E-mail*</label>
                        <input type="text" formControlName="email" class="form-control"
                            [ngClass]="{ 'is-invalid': submitted && f.email.errors }" />
                        <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                            <div *ngIf="f.email.errors.required">Tο e-mail είναι απαραίτητο</div>
                            <div *ngIf="f.email.errors.pattern">Tο e-mail δεν είναι έγκυρο</div>
                        </div>
                    </div>
                   </div>
                   <div class="form-group">
                    <div class="form-check form-switch">
                        <input class="form-check-input" formControlName="acceptTerms" type="checkbox" [ngClass]="{ 'is-invalid': submitted && f.acceptTerms.errors }" id="gridCheck1">
                        <label class="form-check-label" for="gridCheck1">
                          Έχω διαβάσει και αποδέχομαι την πολιτική απορρήτου
                        </label>  
                        <div *ngIf="submitted && f.acceptTerms.errors" class="invalid-feedback">
                            <div *ngIf="f.acceptTerms.errors.required">Η αποδοχή των όρων είναι απαραίτητη</div>
                        </div>          
                      </div>
                     
                </div>
                <div class="form-group">
                    <div class="form-check form-switch">
                        <input class="form-check-input" formControlName="acceptTerms2" type="checkbox" [ngClass]="{ 'is-invalid': submitted && f.acceptTerms.errors }" id="gridCheck2">
                        <label class="form-check-label" for="gridCheck2">
                          Έχω διαβάσει και αποδέχομαι το καταστατικό του Σωματείου και αποδέχομαι πλήρως όλες τις υποχρεώσεις μου, που απορρέουν από αυτό. Ιδιαίτερα ρητά και υπέυθυνα δηλώνω οτι, στο πρόσωπό μου δεν συντρέχει ουδέν απο τα κολλύματα του
                          άρθρου 3 του καταστατικού. 
                        </label>  
                        <div *ngIf="submitted && f.acceptTerms2.errors" class="invalid-feedback">
                            <div *ngIf="f.acceptTerms2.errors.required">Η αποδοχή των όρων του καταστατικού είναι απαραίτητη</div>
                        </div>          
                      </div>                    
                </div>
                <div class="form-group mb-3">
                    <div class="form-check form-switch">
                        <input class="form-check-input" formControlName="acceptNewsletter" type="checkbox" [ngClass]="{ 'is-invalid': submitted && f.acceptNewsletter.errors }" id="gridCheck3">
                        <label class="form-check-label" for="gridCheck3">
                          Θέλω να λαμβάνω ενημερώσεις απο τον Α.Ο. Κέρκυρα
                        </label>  
                              
                      </div>
                     
                </div>
                    
                    <div class="form-group text-center ">
                        <button [disabled]="loading" class="swipeButton">
                            <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
                            <span>ΑΠΟΣΤΟΛΗ</span>
                        </button>
                    </div>
                </form>
            </div>
            <div class="infoContainer" *ngIf="!notsubmitted">       
                <h3>Ευχαριστoύμε</h3>
                για την εγγραφή σας! <br/>Ένας συνεργάτης μας θα επικοινωνήσει μαζί σας για να οριστικοποιηθεί η εγγραφή σας στον Σύλλογο.
            </div>
        </div>        
    </div>
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Observable, of } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ImageCategory } from '../models/images';


const apiUrl = environment.serverapi;

@Injectable({
  providedIn: 'root'
})
export class ApiPhotosService {

  constructor(private http: HttpClient, private router:ActivatedRoute) { }
  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead
      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }

  getFrontPageStories() {
    var url='';
    url = `${apiUrl}topgalleries/`;   
    return this.http.get<ImageCategory[]>(url);
  }

  getPhotostory(id:number){
    var url='';
    url = `${apiUrl}gallery/${id}`;   
    return this.http.get<ImageCategory>(url);
  }

}

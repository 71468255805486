import { Injectable } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import { AnalyticsService } from './analytics.service';
import { ToastService } from './toast-service.service';

@Injectable({ providedIn: 'root' })
export class PwaUpdateService {


  constructor(private readonly updates: SwUpdate, private toastApi:ToastService, private analyticsService:AnalyticsService) {  
    //console.log(this.templateRef) 
    //this.componentFactoryResolver.resolveComponentFactory()
    //this.toastApi.show('Η εφαρμογή έχει μια νέα έκδοση.',{prompt:'Ανανέωση',action:'doAppUpdate',caller:this,class:['bg-dark','text-light']}); 
   this.updates.available.subscribe(event => {
      this.showAppUpdateAlert();
    });
   
  }

  showAppUpdateAlert() {
    const header = 'App Update available';
    const message = 'Choose Ok to update';
    const action = this.doAppUpdate;
    const caller = this;
    // Use MatDialog or ionicframework's AlertController or similar
    //presentAlert(header, message, action, caller);
    //this.modalService.open("updateavailable");
    this.toastApi.show('Η εφαρμογή έχει μια νέα έκδοση.',{prompt:'Ανανέωση',action:'doAppUpdate',caller:this,class:'bg-yale text-timberwolf'}); 
  }
  doAppUpdate() {
      this.analyticsService.trackEvent('PWA','updated','');
      this.updates.activateUpdate().then(() => document.location.reload());
    }
  }



  
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { ArticleListComponent } from './article-list/article-list.component';
import { ArticleComponent } from './article/article.component';
import { BrowserModule } from '@angular/platform-browser';
import { SingleNewPreviewComponent } from '../news/single-new-preview/single-new-preview.component';
import { AppModule } from '../app.module';
import { ReusablesModule } from '../modules/reusables/reusables.module';
import { removeTonation } from '../shared/utils';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { PhotostoriesComponent } from '../homepage/photostories/photostories.component';
import { PhotostoryStandAloneComponent } from '../photostories/photostory-stand-alone/photostory-stand-alone.component';

const routes: Routes = [
  {path:':slug',component: ArticleComponent,data:{type:'newsPage'}},
  {path:'category/:category',component:ArticleListComponent,data:{type:'newsPage'}},
];

@NgModule({
  declarations: [ArticleComponent,ArticleListComponent],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    ReusablesModule,    
    NgbModule    
  ],
  exports:[ArticleComponent,ArticleListComponent]
})
export class ArticleModule { }

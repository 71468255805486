import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { throwError } from 'rxjs';

const apiUrl = 'https://aokerkyra.us7.list-manage.com/subscribe/post-json?u=27437dd6926e88e8e691e94dd&amp;id=12d2a9ba30&';

interface MailChimpResponse {
  result: string;
  msg: string;
}


@Injectable({
  providedIn: 'root'
})
export class MailchimpService {

  constructor(private http: HttpClient, private router:ActivatedRoute) { }
  handleError(error) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // client-side error
      errorMessage = `Error: ${error.error.message}`;
    } else {
      // server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    //window.alert(errorMessage);
    return throwError(errorMessage);
  }

  subscribe(subscriber:any){
    const params = new HttpParams()
				.set('EMAIL', subscriber.email)
				.set('b_27437dd6926e88e8e691e94dd_12d2a9ba30', ''); // hidden input name

			const mailChimpUrl = apiUrl + params.toString();

      // 'c' refers to the jsonp callback param key. This is specific to Mailchimp
			return this.http.jsonp<MailChimpResponse>(mailChimpUrl, 'c');
  }

  
}

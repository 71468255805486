import { Component, OnInit } from '@angular/core';
import { CookieService } from 'src/app/shared/services/cookie.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-mvp-vote',
  templateUrl: './mvp-vote.component.html',
  styleUrls: ['./mvp-vote.component.scss']
})
export class MvpVoteComponent implements OnInit {

  constructor(private cookieService:CookieService) { }
  cookiePack={cookiesAccepted:false,statisticsAccepted:false,thirdpartyAccepted:false,preferenceAccepted:false};
  environment=environment;
  ngOnInit(): void {
    this.cookieService.cookiePack.subscribe(_cp=>{
      this.cookiePack=_cp;
    })
  }

}

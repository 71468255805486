<p *ngFor="let alert of alerts">
    <ngb-alert [type]="alert.type" (close)="close(alert)">{{ alert.message }}</ngb-alert>
</p>
<form [formGroup]="form" (ngSubmit)="onSubmit()">
    Αν έχετε ήδη πραγματοποιήσει εγγραφή συμπληρώστε τα στοιχεία σας παρακάτω<br /><br />
    <div class="form-row row mb-3">
        <div class="form-group">
            <label for="username">E-mail</label>
            <input type="email" formControlName="email" class="form-control"
                [ngClass]="{ 'is-invalid': submitted && f.email.errors }" />
            <div *ngIf="submitted && f.email.errors" class="invalid-feedback">

                <div *ngIf="f.email.errors.required">Tο e-mail είναι απαραίτητο</div>
                <div *ngIf="f.email.errors.pattern">Tο e-mail δεν είναι έγκυρο</div>
            </div>
        </div>
    </div>
    <div class="form-row row mb-3">
        <div class="form-group">
            <label for="password">Password</label>
            <input type="password" formControlName="password" class="form-control"
                [ngClass]="{ 'is-invalid': submitted && f.password.errors }" />
            <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
                <div *ngIf="f.password.errors.required">To password είναι απαραίτητο</div>
            </div>
        </div>
    </div>
    <div class="form-row row mb-3">
        <div class="form-group">
            <button [disabled]="loading" class="swipeButton">
                <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
                <span>ΕΙΣΟΔΟΣ</span>
            </button>
            <a routerLink="register" class="btn btn-link">ΕΓΓΡΑΦΗ</a>
            <a routerLink="forgot" class="btn btn-link">Ξέχασα το password μου :(</a>
        </div>
    </div>
</form>
import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Validators } from '@angular/forms';
import { FormBuilder } from '@angular/forms';
import { AnalyticsService } from 'src/app/shared/services/analytics.service';
import { MailchimpService } from 'src/app/shared/services/mailchimp.service';

@Component({
  selector: 'app-newsletter',
  templateUrl: './newsletter.component.html',
  styleUrls: ['./newsletter.component.scss']
})
export class NewsletterComponent implements OnInit {

  constructor(
    private formBuilder: FormBuilder, private mailchimp:MailchimpService, private analyticsService:AnalyticsService) { }
    form: FormGroup;
    loading = false;
    submitted = false;
    thank=false;
    alerts=[];
  ngOnInit(): void {
    this.form = this.formBuilder.group({
      email: ['', [Validators.required,Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$")]],
    });
  }
  get f() { return this.form.controls; }
  onSubmit(){
    this.loading=true;   
    this.submitted = true;
    if(this.form.invalid){
      this.loading=false;
      return;
    }
    this.mailchimp.subscribe({email:this.form.controls.email.value}).subscribe(_rs=>{
      //console.log(_rs);
      if(_rs.result=='success')
        this.thank=true;
        this.loading=false;
        this.analyticsService.trackEvent('Newsletter','Newsletter subscription','Front page');
      if(_rs.result=='error' && _rs.msg.includes('already')){
        this.alerts.push({type:'warning',message:'To email '+this.f.email.value+' υπάρχει ήδη στην λίστα μας!'})
        this.analyticsService.trackEvent('Newsletter','Newsletter subscription','Front page');
      }
    }, error => {
      //console.error(error);
      //this.error = 'Sorry, an error occurred.';
     
      this.loading=false;
    })


  }
}

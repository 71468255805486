<div class="container-fluid topbar" >
    <div class="coverImage">
        <img [src]="headerImage" />
    </div>
    <div class="container">
       
        <div class="row align-items-center">
            <div class="col text-center match-tabs">
                <ul>
                    <li routerLink="monthly" routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}">ΠΡΟΓΡΑΜΜΑ</li>
                    <li *ngFor="let lg of leagues" [routerLink]="'program/'+lg.lea_id" routerLinkActive="active">ΠΡΟΓΡΑΜΜΑ<br/>{{lg.lea_name | removeTonation | uppercase}}</li>
                    <li *ngFor="let lg of leagueTables" [routerLink]="'leagueTable/'+lg.lea_id" routerLinkActive="active">ΒΑΘΜΟΛΟΓΙΑ <br/>{{lg.lea_name | removeTonation | uppercase}}</li>
                    <li [routerLink]="'stats/'+season" routerLinkActive="active">ΣΤΑΤΙΣΤΙΚΑ</li>
                </ul>
            </div>
        </div>
    </div>
</div>
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { ToastService } from './toast-service.service';

interface CookiePack{
  cookiesAccepted:boolean;
  statisticsAccepted:boolean;
  thirdpartyAccepted:boolean;
  preferenceAccepted:boolean;
}

@Injectable({
  providedIn: 'root'
})


export class CookieService { 
  public statistics:boolean=false;
  public thirdparty:boolean=false;
  public forcePopup:boolean=false;
  cookiePack:BehaviorSubject<CookiePack>;
  
  constructor(private toastApi:ToastService) {
      this.cookiePack=new BehaviorSubject<CookiePack>({cookiesAccepted:false,statisticsAccepted:false,thirdpartyAccepted:false,preferenceAccepted:false});      
      if(this.getCookie('cookiesAccepted')!=null && this.getCookie('cookiesAccepted')!=''){              
        this.cookiePack.next(JSON.parse(this.getCookie('cookiesAccepted')));
      }
      
  }

  public acceptSome(){  
    if(this.getCookie('cookiesAccepted')!=null && this.getCookie('cookiesAccepted')!=""){
       let currentPack=JSON.parse(this.getCookie('cookiesAccepted'));
       currentPack.cookiesAccepted=true;
       this.setCookie('cookiesAccepted',JSON.stringify(currentPack),365,'/');
       this.cookiePack.next(currentPack); 
    }else{
      let currentPack={cookiesAccepted:true,statisticsAccepted:false,thirdpartyAccepted:false,preferenceAccepted:false};
      this.setCookie('cookiesAccepted',JSON.stringify(currentPack),365,'/');
      this.cookiePack.next(currentPack); 
    } 
    this.toastApi.show('Οι ρυθμίσεις σας αποθηκεύτηκαν',{class:'bg-yale text-timberwolf',message:"Οι ρυθμίσεις σας αποθηκεύτηκαν"});
  }

  public acceptAll(){   
     let currentPack={cookiesAccepted:true,statisticsAccepted:true,thirdpartyAccepted:true,preferenceAccepted:true};
     this.setCookie('cookiesAccepted',JSON.stringify(currentPack),365,'/');
     this.cookiePack.next(currentPack);     
     this.toastApi.show('Οι ρυθμίσεις σας αποθηκεύτηκαν',{class:'bg-yale text-timberwolf',message:"Οι ρυθμίσεις σας αποθηκεύτηκαν"});
  }

  public setCookieStatistics(val:boolean){    
    if(this.getCookie('cookiesAccepted')!=null && this.getCookie('cookiesAccepted')!=""){
       let currentPack=JSON.parse(this.getCookie('cookiesAccepted'));
       currentPack.statisticsAccepted=val;
       this.setCookie('cookiesAccepted',JSON.stringify(currentPack),365,'/');
       this.cookiePack.next(currentPack);       
    }else{
      let currentPack={cookiesAccepted:false,statisticsAccepted:val,thirdpartyAccepted:false,preferenceAccepted:false};
      this.setCookie('cookiesAccepted',JSON.stringify(currentPack),365,'/');
      this.cookiePack.next(currentPack); 
    } 
    if(!val){
      this.delete_cookie('__utma','/','');
      this.delete_cookie('__utmc','/','');
      this.delete_cookie('__utmz','/','');
    }
  
  }
  public setCookieThird(val:boolean){
    if(this.getCookie('cookiesAccepted')!=null && this.getCookie('cookiesAccepted')!=""){
      let currentPack=JSON.parse(this.getCookie('cookiesAccepted'));
      currentPack.thirdpartyAccepted=val;
      this.setCookie('cookiesAccepted',JSON.stringify(currentPack),365,'/');
      this.cookiePack.next(currentPack); 
   }else{
     let currentPack={cookiesAccepted:false,statisticsAccepted:false,thirdpartyAccepted:val,preferenceAccepted:false};
     this.setCookie('cookiesAccepted',JSON.stringify(currentPack),365,'/');
     this.cookiePack.next(currentPack); 
   } 
  }
  public setCookiePreferences(val:boolean){
    if(this.getCookie('cookiesAccepted')!=null && this.getCookie('cookiesAccepted')!=""){
      let currentPack=JSON.parse(this.getCookie('cookiesAccepted'));
      currentPack.preferenceAccepted=val;
      this.setCookie('cookiesAccepted',JSON.stringify(currentPack),365,'/');
      this.cookiePack.next(currentPack); 
   }else{
     let currentPack={cookiesAccepted:false,statisticsAccepted:false,thirdpartyAccepted:false,preferenceAccepted:val};
     this.setCookie('cookiesAccepted',JSON.stringify(currentPack),365,'/');
     this.cookiePack.next(currentPack); 
   } 
  }
  public setCookie(name: string, value: string, expireDays: number, path: string = '') {
    const d: Date = new Date();
    d.setTime(d.getTime() + expireDays * 24 * 60 * 60 * 1000);
    const expires = `expires=${d.toUTCString()}`;
    const cpath = '';//path ? `; path=${path}` : '';
    document.cookie = `${name}=${value}; ${expires}${cpath}; SameSite=Lax`;
}

public delete_cookie( name, path, domain ) {
 
  if( this.getCookie( name ) ) {
    //console.log(this.getCookie( name ));
    document.cookie = name + "=" +
      ((path) ? ";path="+path:"")+
      ((domain)?";domain="+domain:"") +
      ";expires=Thu, 01 Jan 1970 00:00:01 GMT";
  }
}

public getCookie(name: string) {
  const ca: Array<string> = decodeURIComponent(document.cookie).split(';');
  const caLen: number = ca.length;
  const cookieName = `${name}=`;
  let c: string;

  for (let i  = 0; i < caLen; i += 1) {
      c = ca[i].replace(/^\s+/g, '');
      if (c.indexOf(cookieName) === 0) {
          return c.substring(cookieName.length, c.length);
      }
  }
  return '';
}

  public cookieStatus():Observable<CookiePack>{
     return this.cookiePack.asObservable();
  }
}

<div class="game" [ngClass]="{'ghost':ghost}" >
    <h6>Επόμενος Αγώνας</h6>
    <div class="row" *ngIf="matchObj!=null">
        <div class="col"><img src="{{homeBadge || imagePath+'images/badge-outline-md.png'}}"/>
        <h3>{{home || 'LOADING' | removeTonation | uppercase }}</h3></div>
        <div class="col">
            <h4>{{date | date:'EE.  d/M' | removeTonation |  uppercase}}</h4>
            <h2>{{(time | slice:0:5) || ' '}}</h2>            
        </div>
        <div class="col"><img src="{{awayBadge || imagePath+'images/badge-outline-md.png'}}"/><h3>{{away || 'LOADING' | removeTonation | uppercase}}</h3></div>
        <div class="col-12 text-center"><h5>{{league || 'LOADING' | removeTonation | uppercase }} {{leagueDay}}</h5>
            <h4>{{venue || 'LOADING' | removeTonation | uppercase}}</h4></div>
    </div>
    <div class="text-center" *ngIf="matchObj!=null"><a class="swipeButton" routerLink="{{linkout}}" *ngIf="!ghost"><span>ΣΕΛΙΔΑ ΑΓΩΝΑ</span></a></div>
    <div *ngIf="cancelled" class="text-center"><span class="badge badge-secondary">O αγώνας έχει αναβληθεί</span></div>
    <div  class="text-center" *ngIf="matchObj==null"><span class="badge badge-secondary"> Δεν έχει οριστεί ο επόμενος αγώνας της ομάδας</span></div>
</div>
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { throwError } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

const apiUrl = environment.serverapi;

@Injectable({
  providedIn: 'root'
})
export class NotificationSubscribeServiceService {

  constructor(private http: HttpClient) { }

  handleError(error) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // client-side error
      errorMessage = `Error: ${error.error.message}`;
    } else {
      // server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    //window.alert(errorMessage);
    return throwError(errorMessage);
  }

  addSubscriber(obj:any){
    var url='';   
    url = `${apiUrl}subscribe`; 
    return this.http.post(url,obj)
    .pipe(
      retry(1),
      catchError(this.handleError)
    );
  }
 
}

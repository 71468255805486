<div class="container mt-5">
    
    <div *ngFor="let monthitem of months">
   
    <div *ngFor="let item of monthitem.matches">
        <app-gamerow [item]="item"></app-gamerow>
    </div>
    </div>   
   
    
</div>
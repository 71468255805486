import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import * as moment from 'moment';
import { Match } from 'src/app/shared/models/match';
import { ApiMatchesServiceService } from 'src/app/shared/services/api-matches-service.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-fullpage',
  templateUrl: './fullpage.component.html',
  styleUrls: ['./fullpage.component.scss'],  
})
export class FullpageComponent implements OnInit {

  constructor(private matchApi:ApiMatchesServiceService, private router:ActivatedRoute, private meta:Meta, private titleService: Title) { }
  matchObj:Match;
  matchid:number;
  home="";
  away="";
  homeBadge="";
  awayBadge="";
  date:Date=null;
  league="";
  leagueDay="";
  venue="";
  linkout:string="";
  homescore:Number;
  awayscore:Number;
  time:string="";
  homescorers="";
  awayscorers="";
  report:number;
  photostory:number;
  headerImage="https://aokerkyra.com/images/game_header.webp";
  extrahome:number;
  extraaway:number;
  penhome:number;
  penaway:number;
  ngOnInit(): void {
    this.router.params.subscribe(params=>{
        this.matchid=params.id;
        this.matchApi.getMatchDetails(this.matchid).subscribe(_res=>{         
          this.matchObj=_res;
          this.home=this.matchObj.HomeTeam.tem_name;
          this.away=this.matchObj.AwayTeam.tem_name;
          this.homeBadge=environment.imagePath+this.matchObj.HomeTeam.image.img_path;
          this.awayBadge=environment.imagePath+this.matchObj.AwayTeam.image.img_path;
          this.date=this.matchObj.mch_date;
          this.league=this.matchObj.league.lea_name;
          this.venue=this.matchObj.mch_venue;
          this.homescore=this.matchObj.mch_homescore;
          this.awayscore=this.matchObj.mch_awayscore;
          this.homescorers=this.matchObj.mch_homescorers;
          this.awayscorers=this.matchObj.mch_awayscorers;
          this.report=this.matchObj.mch_report_new_id;
          this.photostory=this.matchObj.mch_imc_id;
          this.penhome=this.matchObj.mch_penhomescore;
          this.penaway=this.matchObj.mch_penawayscore;
          this.extraaway=this.matchObj.mch_extraawayscore;
          this.extrahome=this.matchObj.mch_extrahomescore;
          console.log(this.extrahome);
          if(this.matchObj.league.lea_ischamp){
            this.leagueDay=this.matchObj.mch_leagueday+"η αγωνιστική";
          }else if(!this.matchObj.league.lea_ischamp && this.matchObj.mch_leagueday!=null){
              this.leagueDay=this.matchObj.mch_leagueday+"ος γύρος";            
          }else{
            this.leagueDay='';
          }
          this.time=this.matchObj.mch_time.toString();
          console.log(this.time);
          this.linkout="/match/"+this.matchObj.mch_id+"/report/";  
          this.titleService.setTitle(this.home+' - '+this.away+'  '+moment(this.date.toString()).format("DD/MM/YYYY"));
          this.meta.addTags([
            { name: 'keywords', content: '' },
            { name: 'robots', content: 'index, follow' },
            {name:'description',content:'Σελίδα αγώνα '+this.home+' - '+this.away+'  '+moment(this.date.toString()).format("DD/MM/YYYY")},
            {property:'title', content:this.home+' - '+this.away+'  '+moment(this.date.toString()).format("DD/MM/YYYY")},
            {property:'og:title',content:this.home+' - '+this.away+'  '+moment(this.date.toString()).format("DD/MM/YYYY")},
            {property:'og:image',content:'https://www.aokerkyra.com/images/ao_kerkyra_1968_400x400.png'}
          ]);  
        })
    });
  
  }

}

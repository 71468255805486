<div class="row matchrow align-items-center" @fade>
<div class="date text-center col-2 col-sm-1"><div><h2>{{item.mch_date | date:'d'}}</h2>{{item.mch_date | date:'MMM' | removeTonation | uppercase}}<span class="year">{{item.mch_date | date:'YYYY' | removeTonation | uppercase}}</span></div></div>
<div class="teamA text-end col-4 col-sm-4 col-lg-3 col-md-5 "><span class="teamName">{{item.HomeTeam.tem_name}}</span>&nbsp;<img src="https://aokerkyra.com/{{item.HomeTeam.image.img_path}}"/></div>
<div class="col-2 col-lg-1 col-md-1 score" *ngIf="item.mch_homescore!=null">{{item.mch_homescore}}-{{item.mch_awayscore}}
    <div *ngIf="item.mch_extraawayscore!=null" class="extratime">Παράταση<br/>{{item.mch_extrahomescore}} - {{item.mch_extraawayscore}}</div>
    <div *ngIf="item.mch_penhomescore!=null" class="extratime">Πέναλτι<br/>{{item.mch_penhomescore}} - {{item.mch_penawayscore}}</div>
</div>
<div class="col-2 col-lg-1 col-md-1 score vs" *ngIf="item.mch_homescore==null">VS</div>
<div class="teamB text-left col-4 col-sm-4 col-md-5 col-lg-3"><img src="https://aokerkyra.com/{{item.AwayTeam.image.img_path}}"/>&nbsp;<span class="teamName">{{item.AwayTeam.tem_name}}</span></div>
<div class="stad col-5 col-sm"><span class="league">{{item.league.lea_name}} <span *ngIf="item.mch_leagueday">{{" - "+item.mch_leagueday || ""}}{{(item.league.lea_ischamp)?'η αγωνιστική':'ος γύρος'}}</span></span><br/><span class="stadium">{{item.mch_venue || "Δ/Α"}}</span>, <span class="time">{{item.mch_time | slice:0:5 || "Δ/Α"}}</span></div>
<div class="icons col-7 col-sm text-center text-sm-right mt-2 mt-sm-0"><a routerLink="/match/{{item.mch_id}}/report" *ngIf="item.mch_report_new_id!=0 && item.mch_report_new_id!=null" ngbTooltip="Report Αγώνα"><svg-icon src="assets/images/file-svgrepo-com.svg" [svgStyle]="{ 'width.px':26,'height.px':26,'fill':'#E5446D' }"></svg-icon></a>
    <a routerLink="/match/{{item.mch_id}}/related" ngbTooltip="Σχετικά άρθρα" *ngIf="item.Related!=null && item.Related.length>0"><svg-icon src="assets/images/newspaper.svg" [svgStyle]="{ 'width.px':26,'height.px':26,'fill':'#E5446D' }"></svg-icon></a> 
    <a routerLink="/match/{{item.mch_id}}/mvp" ngbTooltip="Ψηφιστε τον MVP" *ngIf="(this.hasMvpVotes$ | async)!=null"><svg-icon src="assets/images/cup.svg" [svgStyle]="{ 'width.px':26,'height.px':26,'fill':'#E5446D' }"></svg-icon></a>
    <a routerLink="/match/{{item.mch_id}}/photostory" ngbTooltip="Photostory" *ngIf="item.mch_imc_id!=0 && item.mch_imc_id!=null"><svg-icon src="assets/images/camera.svg" [svgStyle]="{ 'width.px':26,'height.px':26,'fill':'#E5446D' }"></svg-icon></a>
    <div *ngIf="item.mch_cancelled" class="text-center"><span class="badge badge-secondary">O αγώνας έχει αναβληθεί</span></div>
</div>

    </div>
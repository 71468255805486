import { Component, OnInit } from '@angular/core';
import { TableRow } from 'src/app/shared/models/table-row';
import { ApiMatchesServiceService } from 'src/app/shared/services/api-matches-service.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-mini-league',
  templateUrl: './mini-league.component.html',
  styleUrls: ['./mini-league.component.scss']
})
export class MiniLeagueComponent implements OnInit {

  constructor(private matchApi:ApiMatchesServiceService) { }
  ghost=true;
  leagueTable:TableRow[]=[];
  ngOnInit(): void {
    let ghostRow=new TableRow();
    ghostRow.tem_name='ghost';
    for(var i=0;i<12;i++){
      this.leagueTable.push(ghostRow);
    }
    this.matchApi.getLeagueTable(environment.currentLeague).subscribe(_res=>{
      this.leagueTable=_res;      
      this.ghost=false;
    });
  }

}

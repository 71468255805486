<div class="container today p-0">
   <div class="row align-items-center" *ngFor="let item of rows">
    <div class="col-1 year"><h3>{{item.mch_date | date:'y'}}</h3></div>
    <div class="col-5 with-logo"><img src="{{item.HomeTeam.image.img_path}}">{{item.HomeTeam.tem_name | removeTonation | uppercase}} <br/>
      <img src="{{item.AwayTeam.image.img_path}}">{{item.AwayTeam.tem_name | removeTonation | uppercase}} </div>
    <div class="col-1 score">{{item.mch_homescore}}<br/>{{item.mch_awayscore}}</div> 
    <div class="col-3 col-xl-4 text-center venueleague">{{item.mch_venue}}<br/><span class="league">{{item.league.lea_name}}</span></div>
    <div class="col-1"><a routerLink="match/{{item.mch_id}}/report" *ngIf="item.mch_report_new_id"><svg-icon src="assets/images/file-svgrepo-com.svg" [svgStyle]="{ 'width.px':16,'height.px':16,'fill':'#E5446D' }"></svg-icon></a></div>   
   </div>   
   <div class="row" *ngIf="rows.length==0"><div class="col">Δεν βρέθηκαν αγώνες σαν σήμερα!</div></div>
</div>

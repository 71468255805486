import { Component, OnInit } from '@angular/core';
import { Match } from 'src/app/shared/models/match';
import { ApiMatchesServiceService } from 'src/app/shared/services/api-matches-service.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-next-game',
  templateUrl: './next-game.component.html',
  styleUrls: ['./next-game.component.scss']
})
export class NextGameComponent implements OnInit {

  constructor(private matchService:ApiMatchesServiceService) { }
  matchObj:Match;
  home="";
  away="";
  homeBadge="";
  awayBadge="";
  date:Date=null;
  league="";
  leagueDay="";
  venue="";
  linkout:string="";
  time:string="";
  imagePath=environment.imagePath;
  ghost=true;
  cancelled:boolean;
  ngOnInit(): void {
    this.matchService.getNextMatch().subscribe(_res=>{
      if(_res!=null){
      this.matchObj=_res;
      this.home=this.matchObj.HomeTeam.tem_name;
      this.away=this.matchObj.AwayTeam.tem_name;
      this.homeBadge=environment.imagePath+this.matchObj.HomeTeam.image.img_path;
      this.awayBadge=environment.imagePath+this.matchObj.AwayTeam.image.img_path;
      this.date=this.matchObj.mch_date;
      this.league=this.matchObj.league.lea_name;
      this.venue=this.matchObj.mch_venue;
    
      if(this.matchObj.league.lea_ischamp==1){
        this.leagueDay="("+this.matchObj.mch_leagueday+"η αγωνιστική"+")";
      }
      if(this.matchObj.league.lea_iscup=='1'){
        this.leagueDay="("+this.matchObj.mch_leagueday+"ος γύρος"+")";
      }
      
      
      
      this.cancelled=this.matchObj.mch_cancelled;
      if(this.matchObj.mch_time)
        this.time=this.matchObj.mch_time.toString();
      //console.log(this.time);
      this.linkout="/match/"+this.matchObj.mch_id+"/related";
      this.ghost=false;
    }else{
      this.ghost=false;
    }
    });
  }

}

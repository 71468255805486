import { NumberSymbol } from "@angular/common";
import { Country } from "./country";
import { Images } from "./images";
import { Match, Season } from "./match";
import { News } from "./news";

export class Player {
    plr_id:number;
    plr_name: string;
    plr_surname: string;
    plr_number:number;
    plr_active:boolean;
    plr_birthdate:Date;
    plr_history:string;
    SmallImage:Images;
    LargeImage:Images;
    positions:Positions[];
    country:Country;
    plr_slug:string;    
    matches:Match[];
    seasons:Season[];
    news:News[];
}
export class Positions{
    playerpositions:PlayerPositions;
    pos_name:string;
}
export class PlayerPositions{
    plp_order:number;
}

export class StatsRow extends Player{
    matches1:number;
    changes:number;
    yellow:number;
    red:number;
    goals:NumberSymbol;
    minutes:number;
    shirtnumber:number;
}

export class MVPRow{
    total_amount:number;
    player:Player;
}
/*
country: {ctr_id: 1, ctr_name: "Ελλάδα", ctr_flag_img_id: 69, ctr_sflag_img_id: 1394}
plr_active: 1
plr_birthdate: "1990-01-06"
plr_ctr_id: 1
plr_history: null
plr_id: 183
plr_img_id: 6417
plr_name: "Περικλής"
plr_name_en: "Periklis"
plr_number: 0
plr_surname: "Σκεύης"
plr_surname_en: "Skevis"
positions: [{pos_id: 6, pos_name: "Επιθετικός", pos_name_en: "Striker",…}]
*/
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { IAlbum, Lightbox } from 'ngx-lightbox';
import { ApiMatchesServiceService } from 'src/app/shared/services/api-matches-service.service';
import { ApiPhotosService } from 'src/app/shared/services/api-photos.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-photostory-page',
  templateUrl: './photostory-page.component.html',
  styleUrls: ['./photostory-page.component.scss']
})
export class PhotostoryPageComponent implements OnInit {

  constructor(private photoApi:ApiPhotosService,private route: ActivatedRoute,private _lightbox: Lightbox,private matchApi:ApiMatchesServiceService) { }
  myImages=[];
  myImagesAlbum:IAlbum[];
  title="";
  myConfig={'masonry':true};
  ngOnInit(): void {
    if(this.route.snapshot.data['type']=="standalone"){
      this.route.paramMap.subscribe(params => {
        let imcid=parseInt(params.get('id'));
       
        this.photoApi.getPhotostory(imcid).subscribe(_imc=>{
          this.title=_imc.imc_title;
          this.myImages=_imc.images.map(imcitem=>({'full':environment.imagePath+imcitem.img_path,'preview':environment.imagePath+imcitem.img_path.replace('originals','thumbs')}));
          this.myImagesAlbum=_imc.images.map(imcitem=>({'src':environment.imagePath+imcitem.img_path,'thumb':environment.imagePath+imcitem.img_path.replace('originals','thumbs')}));
          //console.log(this.myImages);
        })
      })
    }else if(this.route.snapshot.data['type']=="matchPage"){
      this.route.parent.paramMap.subscribe(_param=>{
        let matchid=parseInt(_param.get('id'));
        
        this.matchApi.getMatchDetails(matchid).subscribe(_mch=>{
          let phstoryid=_mch.mch_imc_id;
          this.title="PHOTOSTORY";
          this.photoApi.getPhotostory(phstoryid).subscribe(_imc=>{
            this.myImages=_imc.images.map(imcitem=>({'full':environment.imagePath+imcitem.img_path,'preview':environment.imagePath+imcitem.img_path.replace('originals','thumbs')}));
            this.myImagesAlbum=_imc.images.map(imcitem=>({'src':environment.imagePath+imcitem.img_path,'thumb':environment.imagePath+imcitem.img_path.replace('originals','thumbs')}));
          });
        })
      })
    }
  }

  open(ind:number){
    
    this._lightbox.open(this.myImagesAlbum,ind);
    
  }

}

import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'app-tickets-members-page',
  templateUrl: './tickets-members-page.component.html',
  styleUrls: ['./tickets-members-page.component.scss']
})
export class TicketsMembersPageComponent implements OnInit {

  constructor( private meta:Meta, private titleService: Title) { }

  ngOnInit(): void {
    this.titleService.setTitle('Εισητήρια & Μέλη - A.O. Κέρκυρα');
    this.meta.addTags([
      { name: 'keywords', content: 'εισιτήρια' },
      { name: 'robots', content: 'index, follow' },
      {name:'description',content:'Εισιτήρια και μέλη'},
      {property:'title', content:'Εισιτήρια και μέλη - A.O. Κέρκυρα'},
      {property:'og:title',content:'Εισιτήρια και μέλη - A.O. Κέρκυρα'},
      {property:'og:image',content:'https://www.aokerkyra.com/images/ao_kerkyra_1968_400x400.png'}
    ]);  
  }

}

<div class="game" [ngClass]="{'ghost':ghost}">
    <h6>Τελευταίος Αγώνας</h6>
    <div class="row">
        <div class="col"><img src="{{homeBadge || imagePath+'images/badge-outline-md.png'}}"/>
        <h3>{{home || 'LOADING TEAM' | removeTonation | uppercase}}</h3></div>
        <div class="col">
            <h4>{{date | date:'EE.  d/M' | removeTonation |  uppercase}} {{time | slice:0:5}}</h4>
            <h2>{{homescore}}-{{awayscore}}</h2>
            <div *ngIf="extraaway!=null" class="extratime">Παράταση<br/>{{extrahome}} - {{extraaway}}</div>
            <div *ngIf="penaway!=null" class="extratime">Πέναλτι<br/>{{penhome}} - {{penaway}}</div>
        </div>
        <div class="col"><img src="{{awayBadge || imagePath+'images/badge-outline-md.png'}}"/><h3>{{away || 'LOADING TEAM' | removeTonation | uppercase}}</h3></div>
        <div class="col-12 text-center"><h5>{{league | removeTonation | uppercase}} {{leagueDay}}</h5>
            <h4>{{venue | removeTonation | uppercase}}</h4></div>
    </div>
    <div class="text-center"><a class="swipeButton" [routerLink]="linkout" *ngIf="!ghost"><span>ΣΕΛΙΔΑ ΑΓΩΝΑ</span></a></div>
</div>
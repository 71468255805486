import { environment } from "src/environments/environment";

export class Images {
    $img_id:number;
    img_title:string;
    img_path:string;
    img_isthumb:boolean;
    newsimages:any;
    public static getGhostImage():Images{
        let im=new Images();
        im.img_path='images/unnamed.webp';
        
        im.img_isthumb=false;   
        im.newsimages={nwi_isthumb:false};     
        return im;
    }
    public getImagePath():string{

        return environment.imagePath+this.img_path;
    }
}

export class ImageCategory{
    imc_id:number;
    imc_title:string;
    imc_order:number;
    images:Images[];
   
}

/*"img_id":6769,"img_title":"stavros kentro welcome 453","img_path":"images/playerwelcome_stavro_kentro_453.jpg","img_isthumb":"0"*/
<div class="toasts">
    <ngb-toast *ngFor="let toast of toastApi.toasts" [ngClass]="toast.class" [animate]="true" [autohide]="true"
        [delay]="toast.delay || 5000" (hide)="toastApi.remove(toast)">
        {{ toast.textOrTpl }}
        <div *ngIf="toast.prompt!=null">
            <button type="button" class="btn btn-link" (click)="toast.caller[toast.action]()">{{toast.prompt}}</button>
        </div>
    </ngb-toast>
</div>
<div class="pillButtonHolder">
    <div class="shopButton" >
        <a href="https://store.aokerkyra.com/" target="_blank">
        <svg-icon src="assets/images/shoppingcart.svg" [svgStyle]="{ 'width.px':40,'height.px':40,'fill':'#5F1A37' }">
        </svg-icon>
        </a>
    </div>
    <div class="cookieButton" *ngIf="!showCookiePop && !cookieService.forcePopup" (click)="cookieService.forcePopup=true">
        <svg-icon src="assets/images/cookie-bite.svg" [svgStyle]="{ 'width.px':40,'height.px':40,'fill':'#5F1A37' }">
        </svg-icon>
    </div>
    <div class="notifButton" *ngIf="showNotifs" (click)="subscribeToNotifications()">
        <svg-icon src="assets/images/bell.svg" [svgStyle]="{ 'width.px':40,'height.px':40,'fill':'#5F1A37' }">
        </svg-icon>
    </div>
</div>
<div class="cookieModal p-1 p-sm-5" *ngIf="showCookiePop || cookieService.forcePopup">
    <div class="row justify-content-center align-items-center">
        <div class="col-2">
            <svg-icon src="assets/images/cookie-bite.svg"
                [svgStyle]="{ 'width.px':40,'height.px':40,'fill':'#5F1A37' }">
            </svg-icon>
        </div>
        <div class="col-10 text-left">
            <h4>Αυτή η σελίδα χρησιμοποιεί cookies</h4>
        </div>
    </div>
    <hr>
    <div class="row pt-2">
        <div class="col descr">Χρησιμοποιούμε cookie για την εξατομίκευση περιεχομένου, την παροχή λειτουργιών
            κοινωνικών μέσων και την ανάλυση της επισκεψιμότητάς μας. Επιπλέον, μοιραζόμαστε πληροφορίες που αφορούν τον
            τρόπο που χρησιμοποιείτε τον ιστότοπό μας με συνεργάτες κοινωνικών μέσων, διαφήμισης και αναλύσεων, οι
            οποίοι ενδεχομένως να τις συνδυάσουν με άλλες πληροφορίες που τους έχετε παραχωρήσει ή τις οποίες έχουν
            συλλέξει σε σχέση με την από μέρους σας χρήση των υπηρεσιών τους.</div>
    </div>
    <div class="row pt-2 pb-2">
        <div class="col-6">
            <div class="form-switch form-check">
                <input type="checkbox" class="custom-control-input form-check-input" disabled id="customSwitch1" checked>
                <label class="form-check-label" for="customSwitch1">Απαραίτητα cookies</label>
            </div>
        </div>
        <div class="col-6">
            <div class="form-switch form-check">
                <input type="checkbox" class="custom-control-input form-check-input" id="customSwitch2"
                    [checked]="cookiePack.statisticsAccepted"
                    (change)="cookieService.setCookieStatistics($event.target.checked)">
                <label class="form-check-label" for="customSwitch2">Στατιστικά</label>
            </div>
        </div>
        <div class="col-6">
            <div class="form-switch form-check">
                <input type="checkbox" class="custom-control-input form-check-input" id="customSwitch3"
                    [checked]="cookiePack.thirdpartyAccepted"
                    (change)="cookieService.setCookieThird($event.target.checked)">
                <label class="form-check-label" for="customSwitch3">Cookies τρίτων</label>
            </div>
        </div>
        <div class="col-6">
            <div class="form-switch form-check">
                <input type="checkbox" class="custom-control-input form-check-input" id="customSwitch4"
                    [checked]="cookiePack.preferenceAccepted"
                    (change)="cookieService.setCookiePreferences($event.target.checked)">
                <label class="form-check-label" for="customSwitch4">Προτιμήσεις</label>
            </div>
        </div>
    </div>
    <div class="row pt-2">
        <div class="col text-center"><button class="swipeButton"
                (click)="cookieService.acceptAll();cookieService.forcePopup=false;"><span>ΑΠΟΔΟΧΗ ΟΛΩΝ</span></button>
        </div>
        <div class="col text-center"><button class="swipeButton"
                (click)="cookieService.acceptSome();cookieService.forcePopup=false;"><span>ΑΠΟΔΟΧΗ
                    ΕΠΙΛΕΓΜΕΝΩΝ</span></button>
        </div>
    </div>
    <div class="row pt-2">
        <div class="col text-center learn-more">
            <a href="/article/cookie-policy">Μάθε περισσότερα για τα cookies</a>
        </div>
    </div>
</div>
<div class="container-fluid footer">
    <div class="container sponsores pb-5">
        <div class="row">
            <div class="col text-center">

                <h2>ΧΟΡΗΓΟΙ</h2>
            </div>
        </div>

        <div class="row align-items-center justify-content-center mt-3 ">
            
            <div class="col-md-3 col-6 text-center" *ngFor="let item of tier1Sponsors">
                <a href="{{item.spo_link}}" target="_blank"><img
                        src="{{ environment.imagePath+item.image.img_path}}" class="img-fluid" /></a>
            </div>
          
            
        </div>
        <div class="row align-items-center justify-content-center">
            <div class="col-md-2 col-4 text-center"  *ngFor="let item of tier2Sponsors">
                <a href="{{item.spo_link}}" target="_blank"><img
                        src="{{environment.imagePath+item.image.img_path}}" class="img-fluid" /></a>
            </div>
            
           
        </div>
        <div class="row mt-5">
            <div class="col text-center">

                <h2>ΥΠΟΣΤΗΡΙΚΤΕΣ</h2>
            </div>
        </div>
        <div class="row align-items-center justify-content-center">        

            <div class="col-xl-1 col-lg-2 col-md-2 col-3 text-center mb-1" *ngFor="let item of tier3Sponsors">
                <a href="{{item.spo_link}}" target="_blank">
                    <img src="{{environment.imagePath+item.image.img_path}}" class="img-fluid"></a>
            </div>
           
                                    
        </div>
    </div>
    <hr />
    <div class="container bottomlinks text-center p-3">
        <div class="row">
            <div class="col">
                <a routerLink="/article/privacy-policy">ΠΟΛΙΤΙΚΗ ΠΡΟΣΤΑΣΙΑΣ ΠΡΟΣΩΠΙΚΩΝ ΔΕΔΟΜΕΝΩΝ</a> |
                <a routerLink="/article/cookie-policy">COOKIES</a> |
                <a routerLink="/contact">ΕΠΙΚΟΙΝΩΝΙΑ</a>
            </div>
        </div>
    </div>
</div>
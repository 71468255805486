
<div class="container section mt-5">
    <div class="row  mb-1"><div class="col p-0"><div class="topbar"><h2>ΨΗΦΟΦΟΡΙΑ MVP</h2></div></div></div>
    <div class="row mt-1 no-gutters" *ngIf="loaded && canuser">
        <div class="text-right p-2"><span class="small logout" (click)="logout()">Έξοδος <svg-icon src="assets/images/logout.svg" [svgStyle]="{ 'width.px':16,'height.px':16,'fill':'#E5446D' }"></svg-icon></span></div>
        <div class="col-12 col-md-12 map-container  what-is text-center">           
            <h3>Επίλεξε τον MVP της αγωνιστικής!</h3>
            <form [formGroup]="form" (ngSubmit)="onSubmit()">
                <div class="row justify-content-center align-items-center mt-1 no-gutters" >
                   
                    
                    <label class="col-md-4 col-lg-3 col-sm-6 col-12 playerTile playerName"  *ngFor="let item of mvps;let i=index"><input type="radio" name="player" formControlName="player" [value]="item.plr_id"><i class="selected"></i> {{item.plr_name}} {{item.plr_surname}} 
                        <svg-icon src="assets/images/clock-svgrepo-com.svg" [svgStyle]="{ 'height.px':16,'width.px':16,'margin-left':'10px'}" [ngbTooltip]="'Χρόνος συμμετοχής'"></svg-icon> {{diff(parseInt(item.matchplayers.mpl_gotout),parseInt(item.matchplayers.mpl_gotin))}}'
                        <svg-icon src="assets/images/publicdomainq-ball.svg" [svgStyle]="{ 'width.px':16,'height.px':16,'fill':'#E5446D'}" *ngFor="let ik of numSequence(item.matchplayers.mpl_goals)" [ngbTooltip]="'Γκόλ!'"></svg-icon> 
                        <span style="background-color:yellow;width:12px;height:16px;display:inline-block;margin-left:10px;box-shadow: #00000012 1px 1px;vertical-align: middle;" *ngFor="let ik of numSequence(item.matchplayers.mpl_isyellow)" [ngbTooltip]="'Κίτρινη κάρτα'"></span>
                        <span style="background-color:red;width:12px;height:16px;display:inline-block;margin-left:10px;box-shadow: #00000012 1px 1px;vertical-align: middle;" *ngFor="let ik of numSequence(item.matchplayers.mpl_isred)" [ngbTooltip]="'Κόκκινη κάρτα'"></span>
                        <svg-icon src="assets/images/publicdomainq-ball-red.svg" [svgStyle]="{ 'width.px':16,'height.px':16,'fill':'red','margin-left':'10px'}" *ngFor="let ik of numSequence(item.matchplayers.mpl_owngoal)" [ngbTooltip]="'Αυτογκόλ'"></svg-icon>
                        <svg-icon src="assets/images/gloves-svgrepo-com.svg" [svgStyle]="{ 'height.px':20,'width.px':23,'margin-left':'10px'}" *ngFor="let ik of numSequence(item.matchplayers.mpl_isgk)" [ngbTooltip]="'Τερματοφύλακας'"></svg-icon>
                        <svg-icon src="assets/images/icon-penalty-shootout-missed.svg" [svgStyle]="{ 'height.px':20,'width.px':23,'margin-left':'10px'}" *ngFor="let ik of numSequence(item.matchplayers.mpl_penaltycatch)" [ngbTooltip]="'Τερματοφύλακας'"></svg-icon>
                        <a href="/player/{{item.plr_slug}}"><svg-icon src="assets/images/info.svg" [svgStyle]="{ 'height.px':20,'width.px':23,'margin-left':'10px'}" [ngbTooltip]="'Προφίλ παίκτη'"></svg-icon></a></label>                   
                      
                </div>     
                <div class="form-group">
                    <button class="swipeButton" *ngIf="showButton && f.player.valid">
                        <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
                        <span>ΥΠΟΒΟΛΗ</span>
                    </button>                                   
                </div>           
            </form>     

        </div>
    </div>
    <div class="row mt-1 no-gutters" *ngIf="loaded && !canuser">
        <div class="col-12 col-md-12 map-container  what-is text-center"> 
            <div class="text-right p-2"><span class="small logout" (click)="logout()">Έξοδος <svg-icon src="assets/images/logout.svg" [svgStyle]="{ 'width.px':16,'height.px':16,'fill':'#E5446D' }"></svg-icon></span></div>  
                <h3>Έχετε ήδη ψηφίσει σε αυτήν την ψηφοφορία</h3>
            </div>
            </div>
</div>
<div class="container section mt-5">
    <div class="row  mb-1"><div class="col p-0"><div class="topbar"><h2>ΧΑΡΤΗΣ ΦΙΛΑΘΛΩΝ</h2></div></div></div>
    <div class="row mt-1 no-gutters">
        <div class="col-lg-9 col-sm-12 map-container p-0">
            <agm-map [latitude]="lat" [longitude]="lng" [zoom]="2" class="map" [streetViewControl]="false" [gestureHandling]="'cooperative'" >
                <agm-marker [latitude]="marker.lat" [longitude]="marker.lng" animation="DROP" [title]="marker.label" [openInfoWindow]="true" [iconUrl]="'/images/mappin_aok.png'" *ngFor="let marker of points">
                    <agm-info-window>{{marker.label}}</agm-info-window>
                </agm-marker>                
               
              </agm-map>
        </div>
        <div class="col-lg-3 col-sm-12 what-is p-2">
            <h3>Τι είναι ο χάρτης φιλάθλων;</h3>
            Ο χάρτης φιλάθλων είναι μια προσπάθεια αποτύπωσης των απανταχού φιλάθλων, πρεσβευτών της ιδέας και των ιδανικών του Α.Ο. Κέρκυρα.
            <h3>Πως μπορώ και εγώ να αφήσω το στίγμα μου;</h3>
            <p *ngIf="cookiePack.preferenceAccepted && cookiePack.cookiesAccepted">Συμπληρώνοντας τα στοιχεία και την τοποθεσία σας κάνοντας κλικ στο κουμπί "ΕΓΓΡΑΦΗ" παρακάτω</p>
            <p *ngIf="!cookiePack.preferenceAccepted || !cookiePack.cookiesAccepted">Για να μπορέσετε να δηλώσετε την τοποθεσία σας στο χάρτη θα πρέπει να έχετε ενεργοποιήσει τα cookies "Προτιμήσεων"</p>
            <div class="text-center p-3" *ngIf="cookiePack.preferenceAccepted && cookiePack.cookiesAccepted">
                <button class="swipeButton" routerLink="addPoint"><span>ΕΓΓΡΑΦΗ</span></button>
            </div>
            <div class="text-center p-3" *ngIf="!cookiePack.preferenceAccepted || !cookiePack.cookiesAccepted">
                <button class="swipeButton" (click)="cookieService.forcePopup=true"><span>ΠΡΟΒΟΛΗ COOKIES</span></button>
            </div>
        </div>
    </div>
</div>
import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { ImageCategory, Images } from 'src/app/shared/models/images';
import { HomePageNews, News } from 'src/app/shared/models/news';
import { ApiNewsService } from 'src/app/shared/services/api-news.service';
import { ApiPhotosService } from 'src/app/shared/services/api-photos.service';


@Component({
  selector: 'app-page',
  templateUrl: './page.component.html',
  styleUrls: ['./page.component.scss']
})
export class PageComponent implements OnInit {
  ghostNew:News=new News();
  
  primeNew:HomePageNews=new HomePageNews();
  
  primeNews:HomePageNews[];
  feautured:HomePageNews[];
  photostories:ImageCategory[];
  constructor(private newsApi:ApiNewsService, private photoApi:ApiPhotosService,  private meta:Meta, private titleService: Title) { }

  ngOnInit(): void {
    
    this.ghostNew.new_title='ghost';
    this.primeNew.news=this.ghostNew;
    let ghostImage=new Images();
    ghostImage.img_path="images/unnamed.webp";
    ghostImage.img_isthumb=false;
    ghostImage.newsimages={nwi_isthumb:false};
    this.ghostNew.images=[ghostImage];

    let ghostPhotoStory=new ImageCategory();
    ghostPhotoStory.imc_title="ghost";
    ghostPhotoStory.images=[ghostImage];

    this.photostories=[ghostPhotoStory,ghostPhotoStory,ghostPhotoStory,ghostPhotoStory];
    this.primeNews=[this.primeNew,this.primeNew,this.primeNew];
    this.feautured=[this.primeNew,this.primeNew,this.primeNew];
    this.newsApi.getHomePageNews().subscribe(_res=>{
      this.primeNews=_res.filter(hpn=>(hpn.hpn_place=='top'));
      this.feautured=_res.filter(hpn=>(hpn.hpn_place=='featured'));
    },error=>{
      //this.toastApi.show('Σφάλμα',{message:'Σφάλμα σύνδεσης με βάση δεδομένων για τα άρθρα!',class:['bg-danger','text-light']})
    })
    this.photoApi.getFrontPageStories().subscribe(_res=>{
      this.photostories=_res;
    },error=>{
      //this.toastApi.show('Σφάλμα',{message:'Σφάλμα σύνδεσης με βάση δεδομένων για τις φωτογραφίες!',class:['bg-danger','text-light']})
    })
    this.titleService.setTitle('Αρχική σελίδα - Α.Ο. Κέρκυρα');
    this.meta.addTags([
      { name: 'keywords', content: '' },
      { name: 'robots', content: 'index, follow' },
      {name:'description',content:'Η ομάδα η δικιά μας λέγεται ΑΟ ΚΕΡΚΥΡΑ, ιδρύθηκε το 1968, έμβλημα της είναι η «Απήδαλος ναύς» και τα χρώματα της είναι το μπλέ και το γκρενά. Ενώ, ως έδρα χρησιμοποιεί το ΕΑΚ Κέρκυρας.'},
      {property:'title', content:'Αρχική σελίδα - Α.Ο. Κέρκυρα'},
      {property:'og:title',content:'Αρχική σελίδα - Α.Ο. Κέρκυρα'},
      {property:'og:image',content:'https://www.aokerkyra.com/images/ao_kerkyra_1968_400x400.png'}
    ]);
  }

}

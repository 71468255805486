import { Time } from "@angular/common";
import { Images } from "./images";
import { News } from "./news";
import { Player } from "./player";

export class Match {
    mch_id:number;
    mch_leagueday:number;
    mch_date:Date;
    HomeTeam:Team;
    AwayTeam:Team;
    league:League;
    mch_venue:string;
    mch_time:Date;
    mch_homescore:number;
    mch_awayscore:number;
    mch_penhomescore:number;
    mch_penawayscore:number;
    mch_extrahomescore:number;
    mch_extraawayscore:number;
    mch_report_new_id:number;
    mch_homescorers:string;
    mch_awayscorers:string;
    mch_imc_id:number;
    news:News;
    mch_cancelled:boolean;
    Related:News[];
    matchplayers:MatchPlayers[];
    mvpvotes:any[];
    Votecount:number;
    Relatedcount:number;
    players:MatchPlayers[];
}

export class Team{
    $tem_id:number;
    tem_name:string;
    image:Images;
    //"tem_name":"Α.Ο. Κέρκυρα","tem_badge_img_id":6441,"tem_descr":null,"tem_name_en":"Kerkyra","image"
}

export class League{
    $lea_id:number;
    lea_name:string;
    lea_ischamp:number;
    lea_iscup:string;
    season:Season;
    lea_season:number;
    lea_table:string;
}

export class MonthMatches{
    monthName:string;
    matches:Match[];
}

export class Season{
    ses_id:number;
    ses_title:string;
    ses_startdate:Date;
    ses_current:boolean;
    image:Images;
    players:Player[];
    leagues:League[];
    playerseasons: any;
    //"ses_id":10,"ses_title":"2020-2021","ses_startdate":"2020-06-01T00:00:00.000Z","ses_current":1
    public static getGhostSeason():Season{
        let ses = new Season();
        ses.ses_title="ghost";
        ses.image=Images.getGhostImage();
        return ses;
    }
}

export class MatchPlayers{
    mpl_assist: number;
    mpl_conceded: number;
    mpl_goals: number;
    mpl_gotin: number;
    mpl_gotout: number;
    mpl_isgk: boolean;
    mpl_isred: boolean;
    mpl_isyellow: boolean
    //mpl_mch_id: 2509
    mpl_owngoal: boolean;
    mpl_penaltycatch: boolean;
    //mpl_plr_id: 186
}
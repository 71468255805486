import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import * as moment from 'moment';
import { MVPRow } from 'src/app/shared/models/player';
import { ApiMatchesServiceService } from 'src/app/shared/services/api-matches-service.service';
import { ApiPlayersService } from 'src/app/shared/services/api-players.service';
import { AuthService } from 'src/app/shared/services/auth.service';

@Component({
  selector: 'app-mvp',
  templateUrl: './mvp.component.html',
  styleUrls: ['./mvp.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class MvpComponent implements OnInit {

  constructor(private route:ActivatedRoute, private playerApi:ApiPlayersService, private matchApi:ApiMatchesServiceService, private auth:AuthService) { }
  mvps:MVPRow[]=[];
  votemode=false;
  total:number;
  loggedin$=this.auth.loginObservable
  ngOnInit(): void {
    this.route.parent.params.subscribe(_params=>{
      let matchid=_params.id;
      this.matchApi.getMatchDetails(matchid).subscribe(_res=>{
        console.log(moment(_res.mch_date));
        console.log(moment(_res.mch_date).diff(moment(new Date()), "days"));
        if(moment(_res.mch_date).diff(moment(new Date()), "days")>-7){
          this.votemode=true;
          
        }else{
          this.votemode=false;
        }
        this.playerApi.getMVPOfMatch(matchid).subscribe(_mvps=>{
          this.mvps=_mvps.sort((mvp1,mvp2)=>mvp2.total_amount-mvp1.total_amount);
          this.total=_mvps.reduce((acc:number,cur)=>acc+Number(cur.total_amount),0);            
        });
      })
    })
  }

  ngOnDestroy():void{
    
  }

}

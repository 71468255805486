<div class="section container">
<app-slider></app-slider>
</div>
<div class="section container">
    <div class="row">
        <div class="col-md-4" *ngFor="let item of primeNews">
            <app-single-new-preview [newsObj]="item.news">

            </app-single-new-preview>
        </div>
        
    </div>
</div>
<div class="section container-fluid games-row">
    <div class="container">
    <div class="row">
        <div class="col-lg-4 order-lg-1 order-md-2 col-md-6 mt-5 mb-5">
            <app-next-game></app-next-game>
        </div>
        <div class="col-lg-5 col-md-12 order-lg-2 order-md-1 mt-5 mb-5">
            <app-prev-game></app-prev-game>
        </div>
        <div class="col-lg-3 col-md-6 order-lg-3 order-md-3  mt-5 mb-5">
            <app-mini-league></app-mini-league>           
        </div>    
    </div>
</div>
</div>
<div class="section container featured-row">
    <div class="row">
        <div class="col-md-4" *ngFor="let item of feautured">
            <app-featured [newsObj]="item.news">

            </app-featured>
        </div>
       
    </div>
</div>
<div class="section container stories">
    <h2>PHOTOSTORIES</h2>
    <div class="row">
        <div class="col-lg-3 col-md-6 position-relative" *ngFor="let item of photostories">
            <app-photostories [imcObj]="item"></app-photostories>
        </div>
        
</div>
</div>
<div class="section container astoday">
    <div class="row">
        <div class="col-lg-6 col-md-8">
            <h2>ΣΑΝ ΣΗΜΕΡΑ</h2>   
            <app-today></app-today>
        </div>
        <div class="col-lg-6 col-md-4">
            <h2>FOLLOW US</h2>
            <app-followus></app-followus>
            <hr>
            <h2>NEWSLETTER</h2>
            <app-newsletter></app-newsletter>
        </div>
       
    </div>
</div>
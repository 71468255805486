import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { IAlbum, Lightbox } from 'ngx-lightbox';
import { ImageCategory } from 'src/app/shared/models/images';
import { ApiMatchesServiceService } from 'src/app/shared/services/api-matches-service.service';
import { ApiPhotosService } from 'src/app/shared/services/api-photos.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-photostory-stand-alone',
  templateUrl: './photostory-stand-alone.component.html',
  styleUrls: ['../photostory-page/photostory-page.component.scss']
})
export class PhotostoryStandAloneComponent implements OnInit {

  constructor(private photoApi:ApiPhotosService,private route: ActivatedRoute,private _lightbox: Lightbox,private matchApi:ApiMatchesServiceService) { }
  @Input()
  imcObj:number;
  myImages=[];
  myImagesAlbum:IAlbum[];
  title="";
  
  myConfig={'masonry':true};
  ngOnInit(): void {
    
      //let imcid=this.imcObj.imc_id;
     
      this.photoApi.getPhotostory(this.imcObj).subscribe(_imc=>{
        this.title=_imc.imc_title;
        this.myImages=_imc.images.map(imcitem=>({'full':environment.imagePath+imcitem.img_path,'preview':environment.imagePath+imcitem.img_path.replace('originals','thumbs')}));
        this.myImagesAlbum=_imc.images.map(imcitem=>({'src':environment.imagePath+imcitem.img_path,'thumb':environment.imagePath+imcitem.img_path.replace('originals','thumbs')}));
        //console.log(this.myImages);
      })
    
  }

  open(ind:number){
    console.log(this.myImagesAlbum[ind]);
    this._lightbox.open(this.myImagesAlbum,ind);
  }

}

import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';
/*
 * Raise the value exponentially
 * Takes an exponent argument that defaults to 1.
 * Usage:
 *   value | exponentialStrength:exponent
 * Example:
 *   {{ 2 | exponentialStrength:10 }}
 *   formats to: 1024
*/
@Pipe({name: 'removeTonation'})
export class removeTonation implements PipeTransform {
  transform(value: string): string {
      if(value!=null){
              value = value.replace(/Ά|Α|ά/g, 'α')
    .replace(/Έ|Ε|έ/g, 'ε')
    .replace(/Ή|Η|ή/g, 'η')
    .replace(/Ί|Ι|ί|ΐ|ϊ/g, 'ι')
    .replace(/Ό|Ο|ό/g, 'ο')
    .replace(/Ύ|Υ|ύ|ΰ|ϋ/g, 'υ')
    .replace(/Ώ|Ω|ώ/g, 'ω')
    .replace(/Σ|ς/g, 'σ');
return value;
      }else{
          return;
      }
  }
}

@Pipe({
    name: 'datediff'
  })
  export class DatediffPipe implements PipeTransform {
  
    transform(date1: any, date2?: any): any {
      const daydiff = moment(date1).diff(moment(date2), "years");
      return Math.abs(daydiff);
    }
  }
  export class Utils {
    
  public static timeSince = function(date:Date) {
    var today = new Date();
    
    var seconds = Math.floor((today.getTime() - date.getTime()) / 1000);
    var interval = seconds / 31536000;
  
    if (interval > 1) {
      if(Math.floor(interval)==1){
        return Math.floor(interval) + " έτος";
      }else{
        return Math.floor(interval) + " έτη";
      }
    }
    interval = seconds / 2592000;
    if (interval > 1) {
      if(Math.floor(interval)==1){
        return Math.floor(interval) + " μήνα";
      }else{
        return Math.floor(interval) + " μήνες";
      }
    }
    interval = seconds / 86400;
    if (interval > 1) {
      if(Math.floor(interval)==1){
        return Math.floor(interval) + " μέρα";
      }else{
        return Math.floor(interval) + " μέρες";
      }
    }
    interval = seconds / 3600;
    if (interval > 1) {
      if(Math.floor(interval)==1){
       return Math.floor(interval) + " ώρα";
      }else{
        return Math.floor(interval) + " ώρες";
      }
    }
    interval = seconds / 60;
    if (interval > 1) {
      if(Math.floor(interval)==1){
       return Math.floor(interval) + "  λεπτό";
      }else{
        return Math.floor(interval) + "  λεπτά";
      }
    }
    return Math.floor(seconds) + " δευτερόλεπτα";
  }
}
import { BrowserModule, HammerGestureConfig, HammerModule,HAMMER_GESTURE_CONFIG } from '@angular/platform-browser';
import { Injectable, LOCALE_ID, NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './structural/header/header.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientJsonpModule, HttpClientModule } from '@angular/common/http';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { SliderComponent } from './homepage/slider/slider.component';
import { PageComponent } from './homepage/page/page.component';
import { SingleNewPreviewComponent } from './news/single-new-preview/single-new-preview.component';
import { ShareButtonModule } from 'ngx-sharebuttons/button';
import { ShareIconsModule } from 'ngx-sharebuttons/icons';
import { NextGameComponent } from './games/next-game/next-game.component';
import { PrevGameComponent } from './games/prev-game/prev-game.component';
import { MiniLeagueComponent } from './games/mini-league/mini-league.component';
import { FeaturedComponent } from './news/featured/featured.component';
import { PhotostoriesComponent } from './homepage/photostories/photostories.component';
import { TodayComponent } from './homepage/today/today.component';
import { FollowusComponent } from './home/followus/followus.component';
import { FooterComponent } from './structural/footer/footer.component';
import { NewsletterComponent } from './home/newsletter/newsletter.component';
import { FormsModule } from '@angular/forms';


import * as Hammer from 'hammerjs'; 
import { FullpageComponent } from './games/fullpage/fullpage.component';
import { ListgamesComponent } from './games/listgames/listgames.component';
import { PlayerListComponent } from './players/player-list/player-list.component';
import { PlayerProfilePageComponent } from './players/player-profile-page/player-profile-page.component';
import { FansPageComponent } from './fans/fans-page/fans-page.component';
import { TicketsMembersPageComponent } from './fans/tickets-members-page/tickets-members-page.component';
import localeEl from '@angular/common/locales/el';
import { registerLocaleData } from '@angular/common';
import { NgxMasonryModule } from 'ngx-masonry';
import { PhotostoryPageComponent } from './photostories/photostory-page/photostory-page.component';
import { LightboxModule } from 'ngx-lightbox';
import { ClubPageComponent } from './club/club-page/club-page.component';

import { HistoryPageComponent } from './history/history-page/history-page.component';
import { SeasonItemComponent } from './history/season-item/season-item.component';
import { SeasonPageComponent } from './history/season-page/season-page.component';
import { MaxiLeagueComponent } from './games/maxi-league/maxi-league.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';

import { GamerowComponent } from './games/gamerow/gamerow.component';
import { TopbarComponent } from './games/topbar/topbar.component';
import { GamesCenterComponent } from './games/games-center/games-center.component';
import { StatsComponent } from './games/stats/stats.component';
import { MvpComponent } from './games/mvp/mvp.component';
import { AgmCoreModule } from '@agm/core';
import { MapRegisterComponent } from './fans/map-register/map-register.component';
import { MapLoginComponent } from './fans/map-login/map-login.component';
import { AngularFireModule} from '@angular/fire/compat'
import { AngularFireAuthModule } from '@angular/fire/compat/auth';
import { ForgotComponent } from './fans/forgot/forgot.component';
import { ConfirmComponent } from './fans/confirm/confirm.component';
import { FansCenterComponent } from './fans/fans-center/fans-center.component';
import { ReactiveFormsModule } from '@angular/forms';
import { MapViewComponent } from './fans/map-view/map-view.component';
import { LoginComponent } from './fans/Email/login/login.component';
import { RegisterComponent } from './fans/Email/register/register.component';
import { MapThanksComponent } from './fans/map-thanks/map-thanks.component';
import { SeasonMatchesComponent } from './history/season-matches/season-matches.component';
import { LeagueTableComponent } from './history/league-table/league-table.component';
import { SeasonRosterComponent } from './history/season-roster/season-roster.component';
import { ContactPageComponent } from './contact-page/contact-page.component';
import { RECAPTCHA_V3_SITE_KEY } from 'ng-recaptcha';
import { MembersFormComponent } from './members-form/members-form.component';
import { Notfound404Component } from './notfound404/notfound404.component';
import { MvpVoteComponent } from './games/mvp/mvp-vote/mvp-vote.component';
import { MvpVoteScreenComponent } from './games/mvp/mvp-vote-screen/mvp-vote-screen.component';
import { MvpThanksComponent } from './games/mvp/mvp-thanks/mvp-thanks.component';
import { AuthenticationModule } from './modules/authentication/authentication.module';
import { ArticleModule } from './articles/article.module';
import { ReusablesModule } from './modules/reusables/reusables.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';


registerLocaleData(localeEl, 'el');


  @Injectable() 
export class MyHammerConfig extends HammerGestureConfig { 
  overrides = <any> {
     
    swipe: { direction: Hammer.DIRECTION_ALL }, 
  }; 
} 

var config = {
  apiKey: "AIzaSyB4NMx3MPibVB3QuZtWhjXmVMufujY8i_M",
  authDomain: "aokerkyra-6089a.firebaseapp.com",
  projectId: "aokerkyra-6089a",
  storageBucket: "aokerkyra-6089a.appspot.com",
  messagingSenderId: "760805687398",
  appId: "1:760805687398:web:a9bd08d5a04a23ae7a6da7",
  measurementId: "G-NBWP0H0HZK"
};

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    SliderComponent,
    PageComponent,    
     
    NextGameComponent, 
    PrevGameComponent, 
    MiniLeagueComponent, 
    FeaturedComponent, 
    PhotostoriesComponent, 
    TodayComponent, 
    FollowusComponent, FooterComponent, NewsletterComponent,FullpageComponent, ListgamesComponent, PlayerListComponent, PlayerProfilePageComponent, FansPageComponent, TicketsMembersPageComponent,
    PhotostoryPageComponent, ClubPageComponent, HistoryPageComponent, SeasonItemComponent, SeasonPageComponent, MaxiLeagueComponent, GamerowComponent, TopbarComponent, GamesCenterComponent, StatsComponent, MvpComponent, 
    MapRegisterComponent, MapLoginComponent, ForgotComponent, ConfirmComponent, FansCenterComponent, MapViewComponent, LoginComponent, RegisterComponent, MapThanksComponent, SeasonMatchesComponent, LeagueTableComponent, SeasonRosterComponent, ContactPageComponent, 
    MembersFormComponent, Notfound404Component, MvpVoteComponent, MvpVoteScreenComponent, MvpThanksComponent
  ],
  imports: [
    FormsModule,
    ReactiveFormsModule,    
    BrowserModule,
    AppRoutingModule,  
    ShareIconsModule,
    BrowserAnimationsModule,
    ShareButtonModule,
    HttpClientModule,   
    HttpClientJsonpModule,
    HammerModule,    
    NgxMasonryModule,
    AngularSvgIconModule.forRoot(),
    LightboxModule,
    NgbModule,
     ServiceWorkerModule.register('aok-service-worker.js', { enabled: environment.production, registrationStrategy: 'registerImmediately' }),
     AgmCoreModule.forRoot({
      apiKey: 'AIzaSyAPZGbNbvi7FUXJ07oQOiVOgiH9BuLwhkc'
    }),
    AngularFireModule.initializeApp(config),
    AngularFireAuthModule,
    AuthenticationModule,
    ArticleModule,
    ReusablesModule
  ],
  providers: [ 
    { 
      provide: HAMMER_GESTURE_CONFIG, 
      useClass: MyHammerConfig, 
      
    }, 
    { provide: LOCALE_ID, useValue: "el-GR" },
    { provide: RECAPTCHA_V3_SITE_KEY, useValue: "6LfYLzoaAAAAAPt6HevM9jjH6UJBYmwX-TDqu7VA" },
  ],
  bootstrap: [AppComponent]
})

export class AppModule { }


import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AnalyticsService } from 'src/app/shared/services/analytics.service';
import { AuthService } from 'src/app/shared/services/auth.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  constructor(private authApi:AuthService, private router:Router, private route:ActivatedRoute, private analyticsService:AnalyticsService) { }
  backroute=this.authApi.backPath;
  ngOnInit(): void {
    //let matchid=this.route.parent.parent.snapshot.params.id;
    //console.log(matchid);
    //this.backroute="/match/"+matchid+"/mvp/vote";
  }

  loginFacebook(){
    this.authApi.loginWithFacebook(this.backroute);
    this.analyticsService.trackEvent('Mvp vote','Login','facebook');
  }

  loginGoogle(){
    this.authApi.loginWithGoogle(this.backroute);    
    this.analyticsService.trackEvent('Mvp vote','Login','google');
  }

  loginTwitter(){
    this.authApi.loginWithTwitter(this.backroute);
    this.analyticsService.trackEvent('Mvp vote','Login','twitter');
  }


}

<div class="container section mt-5">
    <div class="row  mb-1"><div class="col p-0"><div class="topbar"><h2>ΧΑΡΤΗΣ ΦΙΛΑΘΛΩΝ</h2></div></div></div>
    <div class="row mt-1 no-gutters ">
        <div class="col-md-8 map-container p-0">
            <agm-map [latitude]="lat" [longitude]="lng" [zoom]="3" [streetViewControl]="false" class="map" [gestureHandling]="'cooperative'" [styles]="['mapokerkyra']">
                <agm-marker [latitude]="lat" [longitude]="lng" [markerDraggable]="true" (dragEnd)="dragEnd($event)"></agm-marker>
            </agm-map>
        </div>
        <div class="col-md-4 what-is p-2 ">      
            
            <div class="text-right p-2"><span class="small logout" (click)="logout()">Έξοδος <svg-icon src="assets/images/logout.svg" [svgStyle]="{ 'width.px':16,'height.px':16,'fill':'#E5446D' }"></svg-icon></span></div>
            <div *ngIf="previous">Μπορείτε να διορθώσετε το στίγμα ή το όνομά σας.<br/><br/></div>
            Τοποθετήστε με το <span *ngIf="!isMobile">ποντίκι</span><span *ngIf="isMobile">δάκτυλό</span> σας την πινέζα στο σημείο του χάρτη, που βρίσκεστε. Δεν είναι απαραίτητο να δηλώσετε την ακριβή τοποθεσία σας.
            <br/><br/>
            Συμπληρώστε το όνομα με το οποιο θέλετε να φαίνεστε στον χάρτη.<br/><br/>
            <form [formGroup]="form" (ngSubmit)="onSubmit()">
                <div class="form-row mb-3">
                    <label for="nickname">Όνομα (στο χάρτη)</label>
                    <input type="nickname" formControlName="nickname" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.nickname.errors }" />
                    <div *ngIf="submitted && f.nickname.errors" class="invalid-feedback">                                 
                        <div *ngIf="f.nickname.errors.required">Tο όνομα αυτό είναι απαραίτητο</div>
                    </div>
                </div>
                <div class="form-row mb-3">
                    <button [disabled]="loading" class="swipeButton">
                        <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
                        <span>ΥΠΟΒΟΛΗ</span>
                    </button>                                   
                </div>
                <p *ngFor="let alert of alerts">
                    <ngb-alert [type]="alert.type" (close)="close(alert)">{{ alert.message }}</ngb-alert>
                </p>     
            </form>
            
        </div>
    </div>
</div>
import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbDropdownItem } from '@ng-bootstrap/ng-bootstrap';
import { News, NewsList } from 'src/app/shared/models/news';
import { ApiNewsService } from 'src/app/shared/services/api-news.service';




@Component({
  selector: 'app-article-list',
  templateUrl: './article-list.component.html',
  styleUrls: ['./article-list.component.scss']
})
export class ArticleListComponent implements OnInit {

  constructor(private newsApi:ApiNewsService,private route: ActivatedRoute, private router:Router, private meta:Meta, private titleService: Title) { }
  news:NewsList;
  rows:News[]=[];
  page=1;
  count=0;
  morePage=false;
  categorySlug='all';
  categories={'all':'Όλα','nea-ths-proponhshs':'Νέα προπόνησης','dioikhtika-nea':'Διοικητικά Νέα','metagrafes-apodesmeyseis':'Μεταγραφές - Αποδεσμεύσεις','synenteykseis':'Συνεντεύξεις'};
  newsList=false;
  ddLabel='Όλα'
  ngOnInit(): void {     
    if(this.route.snapshot.data['type']=="newsPage"){
      //this.getData(this.page);     
        this.newsList=true;
        this.route.paramMap.subscribe(params => {
          
          if(params.get('category')!=this.categorySlug){
            this.rows=[];
            this.page=1;
            this.categorySlug = params.get('category');
            this.ddLabel=this.categories[this.categorySlug];            
          }       
          this.titleService.setTitle('Λίστα νέων: '+this.ddLabel+' - Α.Ο. Κέρκυρα');
          this.meta.addTags([
            { name: 'keywords', content: '' },
            { name: 'robots', content: 'index, follow' },
            {name:'description',content:'Λίστα με τα τελευταία άρθρα και ειδήσεις του Α.Ο. Κέρκυρα'},
            {property:'title', content:'Λίστα νέων: '+this.ddLabel+' - Α.Ο. Κέρκυρα'},
            {property:'og:title',content:'Λίστα νέων: '+this.ddLabel+' - Α.Ο. Κέρκυρα'},
            {property:'og:image',content:'https://aokerkyra.com/images/ao_kerkyra_1968_400x400.png'}
          ]);   
           this.getData(this.page);           
        });    
        

      }else if(this.route.snapshot.data['type']=="matchPage"){
        this.morePage=false;
        this.route.parent.paramMap.subscribe(params=>{
          let match=parseInt(params.get('id'));
          this.newsApi.getNewsOfMatch(match).subscribe(res=>{
              //console.log(res);
              this.rows=res.Related;             
          });         
        });
      }

      
      
          
  }

  populateGhosts=function(){
    for(var i=0;i<12;i++){
      this.rows.push(News.getGhostNew());
    }
  }

  deleteGhosts=function(){
    this.rows=this.rows.filter(_nw=>(_nw.new_title!='ghost'));
  }

  getData=function(page:number){
    this.populateGhosts();
    this.newsApi.getNewsPaged(this.page,this.categorySlug).subscribe(res=>{
      this.news=res;
      this.rows=this.rows.concat(this.news.rows);
      this.count=this.news.count;
      if(this.count>(this.page-1)*12){
        this.morePage=true;
      }else{
        this.morePage=false;
      }      
      this.deleteGhosts();
    });
  }
  getMoreData = function(){
    if(this.count>(this.page-1)*12){
      this.page++;
      this.getData();
    }
  }
}

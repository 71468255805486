import { Injectable, NgZone } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { Router } from '@angular/router';
import { initializeApp } from 'firebase/app';
import firebase from 'firebase/compat/app';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { ToastService } from './toast-service.service';




@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private _user:firebase.User;
  private _backpath='';

  get user():firebase.User{
    return this._user;
  }

  set user(usr:firebase.User){
    this._user=usr;
  }
   

  constructor(public  afAuth:  AngularFireAuth, public  router:  Router, private ngZone:NgZone, private toast:ToastService) { 
   this.init();
  }

  init(){    
    this.afAuth.authState.subscribe(user => {     
      if (user){
        //console.log('user inserted');
        this._user = user;
        localStorage.setItem('user', JSON.stringify(this._user));
        this.loginStatus.next(true);
        JSON.parse(localStorage.getItem('user'));
      } else {
        //console.log('user killed');
        this._user=null;
        localStorage.setItem('user', null);
        this.loginStatus.next(false);
        JSON.parse(localStorage.getItem('user'));
      }
    })
  }

   mailLogin(email: string, password: string, routeBack:string) {      
    return this.afAuth.signInWithEmailAndPassword(email, password)
    .then((result) => {
      //console.log(result);
      this.ngZone.run(() => {        
        localStorage.setItem('user', JSON.stringify(result));
        this.toast.show('Επιτυχημένη είσοδος στην εφαρμογή',{class:'bg-yale text-timberwolf'});
        this.loginStatus.next(true);
        this.router.navigate([routeBack]);
      });
      
    })
  }

   MailRegister(email:string, password:string){
    return this.afAuth.createUserWithEmailAndPassword(email,password).then((_user)=>{
      _user.user.sendEmailVerification();
    })
  }

  /* sendEmailVerification() {
    return this.afAuth.sendEmailVerification() 
    this.router.navigate(['/fans/confirm']);
  }*/

   sendPasswordResetEmail(passwordResetEmail: string) {
    return this.afAuth.sendPasswordResetEmail(passwordResetEmail);
  }

  getUserFromLocalStorage(){
    return JSON.parse(localStorage.getItem('user')) as firebase.User
  }

  logout(backRoute:string){    
    return this.afAuth.signOut().then(()=>{
        localStorage.setItem('user', null);
        //JSON.parse(localStorage.getItem('user'));
        //console.log(backRoute);
        //this.router.navigate([backRoute]);   
        //console.log(backRoute);       
        //this.router.onSameUrlNavigation('reload');
        this.loginStatus.next(false);
        this.toast.show('Επιτυχημένη έξοδος από την εφαρμογή',{class:'bg-yale text-timberwolf'});
        this.router.navigateByUrl(backRoute,{replaceUrl:true});
       
      })
  }

  public get backPath(){
    return this._backpath;
  }

  public set backPath(path:string){
    this._backpath=path;
  }
  
  private loginStatus:BehaviorSubject<boolean>=new BehaviorSubject<boolean>(false);

  public loginObservable:Observable<boolean>=this.loginStatus.asObservable();
  

  
  get isLoggedIn(): boolean {
    //return (this.afAuth.user!=null);
    const  user  =  JSON.parse(localStorage.getItem('user'));
    return  user  !==  null;
  }

  async  loginWithGoogle(backRoute:string){   
    return  this.afAuth.signInWithPopup(new firebase.auth.GoogleAuthProvider()).then(
      (result)=>{
       
        this.user=result.user;
        localStorage.setItem('user', JSON.stringify(result));
        //console.log(backRoute);
        this.loginStatus.next(true);
        this.toast.show('Επιτυχημένη είσοδος στην εφαρμογή',{class:'bg-yale text-timberwolf'});
        this.router.navigate([backRoute]);
      }
    )
   
  }

  async  loginWithFacebook(backRoute:string){   
    return  this.afAuth.signInWithPopup(new firebase.auth.FacebookAuthProvider()).then(
      (result)=>{
        this.user=result.user;
        localStorage.setItem('user', JSON.stringify(result.user));
        this.loginStatus.next(true);
        this.toast.show('Επιτυχημένη είσοδος στην εφαρμογή',{class:'bg-yale text-timberwolf'});
        this.router.navigate([backRoute]);
      }
    )
   
  }

  

  async  loginWithTwitter(backRoute:string){   
    return  this.afAuth.signInWithPopup(new firebase.auth.TwitterAuthProvider()).then(
      (result)=>{
        this.user=result.user;
        localStorage.setItem('user', JSON.stringify(result.user));
        this.toast.show('Επιτυχημένη είσοδος στην εφαρμογή',{class:'bg-yale text-timberwolf'});
        this.loginStatus.next(true);
        this.router.navigate([backRoute]);
      }
    )
    
  }
}

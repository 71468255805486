export class TableRow {
    tem_id:number;
    tem_name:string;
    hommatches:number;
    totmatches:number;
    win_home:number;
    tie_home:number;
    win_away:number;
    tie_away:number;
    home_goals:number;
    home_agoals:number;
    away_goals:number;
    away_agoals:number;
    points:number;
    goaldiff:number;
}

//{"tem_id":1,"tem_name":"Α.Ο. Κέρκυρα","hommatches":"1","totmatches":"3","win_home":"1","win_away":"2","tie_home":"0","tie_away":"0","home_goals":"3","away_goals":"6","away_agoals":"1","home_agoals":"0","points":"9","goals":"9","goaldiff":"8"}

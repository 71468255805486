import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { League } from 'src/app/shared/models/match';
import { ApiMatchesServiceService } from 'src/app/shared/services/api-matches-service.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-season-page',
  templateUrl: './season-page.component.html',
  styleUrls: ['./season-page.component.scss']
})
export class SeasonPageComponent implements OnInit {

  constructor(private router:ActivatedRoute,private matchApi:ApiMatchesServiceService, private meta:Meta, private titleService: Title) { }
  headerImage:string;
  seasonTitle:string;
  hasPlayers=false;
  leagues:League[];
  
  ngOnInit(): void {
    this.router.params.subscribe(_params=>{      
      var season=_params.id;
      this.matchApi.getSeasonPast(season).subscribe(_res=>{
        if(_res.image!=null)
          this.headerImage=environment.imagePath+_res.image.img_path;
        this.seasonTitle=_res.ses_title;
        this.leagues=_res.leagues.filter(_leg=>(_leg.lea_ischamp===1 && _leg.lea_table!=""));
        
        if(_res.players.length>0){
          this.hasPlayers=true;
        }
        this.titleService.setTitle('Σαιζόν '+this.seasonTitle+' - Α.Ο. Κέρκυρα');
        this.meta.addTags([
          { name: 'keywords', content: 'ιστορία, ύμνος, ίδρυση' },
          { name: 'robots', content: 'index, follow' },
          {name:'description',content:'Αγώνες της σαιζόν '+this.seasonTitle+' - Α.Ο. Κέρκυρα'},
          {property:'title', content:'Σαιζόν '+this.seasonTitle+' - Α.Ο. Κέρκυρα'},
          {property:'og:title',content:'Σαιζόν '+this.seasonTitle+' - Α.Ο. Κέρκυρα'},
          {property:'og:image',content:'https://www.aokerkyra.com/images/ao_kerkyra_1968_400x400.png'}
        ]);  
      })
    })
    
  }

}

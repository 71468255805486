<div class="slider-holder container-fluid" [ngClass]="{'touch':isMobile}" (swiperight)="swipeNext($event)" (swipeleft)="swipePrev($event)">
    <div *ngFor="let item of slides;index as i;" [@openClose]="(curSlide==i) ? 'open' : 'closed'"  [ngClass]="{'ghost':(item.sli_title=='ghost')}">
        <picture>
            <source srcset="{{item.Portrait.img_path}}"
                    media="(orientation:portrait)">
            <img src="{{item.Landscape.img_path}}" alt="" />
        </picture>
        <div class="slideHeader" [@updown]="(curSlide==i) ? 'open' : 'closed'">            
            <h3 data-text="{{item.sli_title}}">{{item.sli_title | removeTonation}}</h3>
            <a routerLink="{{item.sli_url}}" class="sliderButton"><span>ΔΙΑΒΑΣΕ ΠΕΡΙΣΣΟΤΕΡΑ</span></a>
        </div>
    </div>   
    <section class="slider-navigation" *ngIf="slides && slides.length>1">
        <div class="bullets">
            <span *ngFor="let slide of slides;index as i;" (click)="setSlide(i)" [ngClass]="{'active':curSlide==i}"></span>          
        </div>
        <div class="prevArrow" (click)="processNextSlide()">
            <svg-icon src="assets/images/arrow.svg" [svgStyle]="{ 'width.px':30,'height.px':30, 'fill':'#E5446D' }" ></svg-icon>
        </div>
        <div class="nextArrow" (click)="processNextSlide()">
            <svg-icon src="assets/images/arrow.svg" [svgStyle]="{ 'width.px':30,'height.px':30, 'fill':'#E5446D' }" ></svg-icon>
        </div>
    </section>    
</div>

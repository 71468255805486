<div class="container section mt-sm-5">
    <div class="row  mb-1"><div class="col"><div class="topbar"><h2>ΤΑΥΤΟΤΗΤΑ ΣΥΛΛΟΓΟΥ</h2></div></div></div>
    <div class="row infoContentBox mt-3 no-gutters">
        <div class="col">
            <div class="infoContainer p-2">
                <ul>
                    <li class="row">
                        <div class="col-2"><svg-icon src="assets/images/hanging-calendar.svg" [svgStyle]="{ 'width.px':26,'height.px':26,'fill':'#E5446D' }"></svg-icon></div>
                        <div class="col-4 position">ΕΤΟΣ ΙΔΡΥΣΗΣ</div>
                        <div class="col-6 name">1968</div>
                    </li>
                    <li class="row">
                        <div class="col-2"><svg-icon src="assets/images/info.svg" [svgStyle]="{ 'width.px':26,'height.px':26,'fill':'#E5446D' }"></svg-icon></div>
                        <div class="col-4 position">ΠΛΗΡΗΣ ΟΝΟΜΑΣΙΑ</div>
                        <div class="col-6 name">ΑΘΛΗΤΙΚΟΣ ΟΜΙΛΟΣ ΚΕΡΚΥΡΑ</div>
                    </li>
                    <li class="row">
                        <div class="col-2"><svg-icon src="assets/images/home.svg" [svgStyle]="{ 'width.px':26,'height.px':26,'fill':'#E5446D' }"></svg-icon></div>
                        <div class="col-4 position">ΔΙΕΥΘΥΝΣΗ</div>
                        <div class="col-6 name">Εθνικό Στάδιο Κέρκυρας,<br/>Κώστα Γεωργάκη 21<br/>
                            Κέρκυρα<br/>
                            49100</div>
                    </li>
                    <li class="row">
                        <div class="col-2"><svg-icon src="assets/images/call.svg" [svgStyle]="{ 'width.px':26,'height.px':26,'fill':'#E5446D' }"></svg-icon></div>
                        <div class="col-4 position">ΤΗΛΕΦΩΝΑ</div>
                        <div class="col-6 name">26610 24761</div>
                    </li>                    
                </ul>
            </div>
        </div>
        <div class="col p-0 d-none d-sm-block text-center bg-ivory">
            <svg-icon src="assets/images/ao_kerkyra_1968.svg" [svgStyle]="{ 'width':'60%'}"></svg-icon>
        </div>
    </div>
</div>
<div class="container section mt-5">
    <div class="row  mb-1"><div class="col"><div class="topbar"><h2>ΔΙΟΙΚΗΤΙΚΟ ΣΥΜΒΟΥΛΙΟ</h2></div></div></div>
    <div class="row infoContentBox mt-3 no-gutters">
        <div class="col">
            <div class="infoContainer p-2">
                <ul>
                    <li class="row">
                        <div class="col-2"><svg-icon src="assets/images/star.svg" [svgStyle]="{ 'width.px':26,'height.px':26,'fill':'#E5446D' }"></svg-icon></div>
                        <div class="col-4 position">ΠΡΟΕΔΡΟΣ</div>
                        <div class="col-6 name">ΓΙΩΡΓΟΣ ΚΟΛΥΤΑΣ</div>                         
                    </li>
                    <li class="row">
                        <div class="col-2"><svg-icon src="assets/images/star2.svg" [svgStyle]="{ 'width.px':26,'height.px':26,'fill':'#E5446D' }"></svg-icon></div>
                        <div class="col-4 position">ΑΝΤΙΠΡΟΕΔΡΟΣ Α'</div>
                        <div class="col-6 name">ΜΑΤΘΑΙΟΣ ΓΙΑΝΝΟΥΛΗΣ</div>                         
                    </li>
                    <li class="row">
                        <div class="col-2"><svg-icon src="assets/images/star2.svg" [svgStyle]="{ 'width.px':26,'height.px':26,'fill':'#E5446D' }"></svg-icon></div>
                        <div class="col-4 position">ΑΝΤΙΠΡΟΕΔΡΟΣ B'</div>
                        <div class="col-6 name">ΑΝΤΩΝΗΣ ΖΩΤΟΣ</div>                         
                    </li>
                    <li class="row">
                        <div class="col-2"><svg-icon src="assets/images/ancient-scroll.svg" [svgStyle]="{ 'width.px':26,'height.px':26,'fill':'#E5446D' }"></svg-icon></div>
                        <div class="col-4 position">ΓΕΝΙΚΟΣ ΓΡΑΜΜΑΤΕΑΣ</div>
                        <div class="col-6 name">ΔΗΜΗΤΡΗΣ ΜΠΟΡΤΣ</div>                         
                    </li>
                    <li class="row">
                        <div class="col-2"><svg-icon src="assets/images/money.svg" [svgStyle]="{ 'width.px':26,'height.px':26,'fill':'#E5446D' }"></svg-icon></div>
                        <div class="col-4 position">ΤΑΜΙΑΣ</div>
                        <div class="col-6 name">ΓΙΑΝΝΗΣ ΚΑΤΕΡΗΣ</div>                         
                    </li>
                    <li class="row">
                        <div class="col-2"><svg-icon src="assets/images/ancient-scroll.svg" [svgStyle]="{ 'width.px':26,'height.px':26,'fill':'#E5446D' }"></svg-icon></div>
                        <div class="col-4 position">ΕΙΔΙΚΟΣ ΓΡΑΜΜΑΤΕΑΣ</div>
                        <div class="col-6 name">ΙΩΑΝΝΑ ΠΛΑΣΚΑΣΟΒΙΤΗ</div>                         
                    </li>
                    <li class="row">
                        <div class="col-2"><svg-icon src="assets/images/flag.svg" [svgStyle]="{ 'width.px':26,'height.px':26,'fill':'#E5446D' }"></svg-icon></div>
                        <div class="col-4 position">ΓΕΝΙΚΟΣ ΑΡΧΗΓΟΣ</div>
                        <div class="col-6 name">ΕΥΣΤΑΘΙΟΣ ΜΗΛΙΩΤΗΣ</div>                         
                    </li>
                    <li class="row">
                        <div class="col-2"><svg-icon src="assets/images/suit.svg" [svgStyle]="{ 'width.px':26,'height.px':26,'fill':'#E5446D' }"></svg-icon></div>
                        <div class="col-4 position">ΕΦΟΡΟΣ</div>
                        <div class="col-6 name">ΓΕΩΡΓΙΟΣ ΚΑΝΤΑΡΟΣ</div>                         
                    </li>
                    <li class="row">
                        <div class="col-2"><svg-icon src="assets/images/info.svg" [svgStyle]="{ 'width.px':26,'height.px':26,'fill':'#E5446D' }"></svg-icon></div>
                        <div class="col-4 position">ΜΕΛΗ</div>
                        <div class="col-6 name">ΣΠΥΡΙΔΩΝ ΣΚΛΑΒΕΝΙΤΗΣ<br/>ΝΙΚΟΛΑΟΣ ΣΠΑΤΟΥΛΑΣ<br/>ΓΕΩΡΓΙΟΣ ΚΟΣΚΙΕΡΗΣ</div>                         
                    </li>                   
                </ul>
            </div>
        </div>
        <div class="col p-0 d-none d-sm-block">
            <img src="{{environment.imagePath}}images/213619653_353664843047793_3873258242162630280_n.webp" class="img-fluid fit-img">
        </div>
    </div>
</div>
<div class="container section mt-5">
    <div class="row mt-5 mb-1"><div class="col"><div class="topbar"><h2>ΔΙΟΙΚΗΤΙΚΑ ΝΕΑ</h2></div></div></div>
    <div class="row mt-1">
        <div class="col-md-4" *ngFor="let item of rows">
            <app-single-new-preview [newsObj]="item">

            </app-single-new-preview>
        </div>       
    </div>
</div>

import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/shared/services/auth.service';
import {isMobile} from 'detect-touch-device';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { FanmapService } from 'src/app/shared/services/fanmap.service';
import { AnalyticsService } from 'src/app/shared/services/analytics.service';

@Component({
  selector: 'app-map-register',
  templateUrl: './map-register.component.html',
  styleUrls: ['./map-register.component.scss']
})
export class MapRegisterComponent implements OnInit {
  
  lat = 36.686365;
  lng = -24.3856037;
  marker={coords:{lat:36.686365,lng:-24.3856037}}
  center={coords:{lat:36.686365,lng:-24.3856037}}
  isMobile=isMobile;
  form: FormGroup;
  loading = false;
  submitted = false;
  mapCenter;
  previous=false;
  alerts=[];

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private authApi:AuthService,
    private fanmapApi:FanmapService,
    private analyticsService:AnalyticsService
  ) { }

 

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      nickname: ['', [Validators.required]],
    });
    if(localStorage.getItem('user')){
      let uid=JSON.parse(localStorage.getItem('user')).uid;
      //console.log(uid);
      if(uid!=null){
        this.fanmapApi.getMapUser(uid).subscribe(_res=>{
          //console.log(_res);
          if(_res!=null){
            this.lat=JSON.parse(_res.fmp_coords).coords.lat;
            this.lng=JSON.parse(_res.fmp_coords).coords.lng;
            this.form.controls['nickname'].setValue(_res.fmp_name);
            this.previous=true;
          }
        })
      }

    }
    
  }

  get f() { return this.form.controls; }
  dragEnd(event){   
    this.marker=event;
  }
  logout(){
    this.authApi.logout('/fans');
  }

  close(alert) {    
    this.alerts.splice(this.alerts.indexOf(alert), 1);
  }
  onSubmit(){
    this.loading=true;   
    this.submitted = true;    
   
    if(this.marker.coords.lat==this.center.coords.lat && this.marker.coords.lng==this.center.coords.lng){
     
      this.alerts.push({type:'warning',message:'Θα πρέπει να τοποθετήσετε την πινέζα στο σημείο που θέλετε να αφήσετε το στίγμα σας'})
      this.loading=false;
      return;
    }
    if(this.form.invalid){
      
      this.loading=false;
      return;
    }
    this.authApi.afAuth.idToken.subscribe(_tok=>{
      this.fanmapApi.addMapUser(     
        {name:this.form.value.nickname,email:this.authApi.user.email,coords:JSON.stringify(this.marker),userid:this.authApi.user.uid},_tok
      ).subscribe((_res)=>{
        this.analyticsService.trackEvent('Fans map','Pin registration','');
        this.router.navigate(['/fans/thanks']);
      });
    })
    
  }
}

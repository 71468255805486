import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { League } from 'src/app/shared/models/match';
import { ApiMatchesServiceService } from 'src/app/shared/services/api-matches-service.service';

@Component({
  selector: 'app-topbar',
  templateUrl: './topbar.component.html',
  styleUrls: ['./topbar.component.scss']
})
export class TopbarComponent implements OnInit {
  @Input('season')
  season:number;

  leagues:League[];
  leagueTables:League[];
  headerImage="https://aokerkyra.com/images/match_bg.webp";
  headerImageUrl;
  constructor(private matchApi:ApiMatchesServiceService, private sanitizer:DomSanitizer) { }

  ngOnInit(): void {   
    this.headerImageUrl=this.sanitizer.bypassSecurityTrustResourceUrl("url('"+this.headerImage+"')");
    this.matchApi.getLeaguesOfSeason(this.season).subscribe(_res=>{
      this.leagues=_res;
      this.leagueTables=this.leagues.filter(lea=>(lea.lea_ischamp));
    })
  }

}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { throwError } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Sponsor } from '../models/sponsor';

const apiUrl = environment.serverapi;
@Injectable({
  providedIn: 'root'
})


export class SponsorsService {
  
  constructor(private http:HttpClient) { }

  handleError(error) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // client-side error
      errorMessage = `Error: ${error.error.message}`;
    } else {
      // server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    //window.alert(errorMessage);
    return throwError(errorMessage);
  }

  getSponsors(tier:string){
    var url='';
    url = `${apiUrl}sponsors/${tier}`;   
    return this.http.get<Sponsor[]>(url);
  }

}


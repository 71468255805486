import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { StatsRow } from 'src/app/shared/models/player';
import { ApiMatchesServiceService } from 'src/app/shared/services/api-matches-service.service';

@Component({
  selector: 'app-stats',
  templateUrl: './stats.component.html',
  styleUrls: ['./stats.component.scss']
})
export class StatsComponent implements OnInit {

  constructor(private matchApi:ApiMatchesServiceService, private router:ActivatedRoute,private meta:Meta, private titleService: Title) { }
  stats:StatsRow[]=[];
  ngOnInit(): void {
    this.router.params.subscribe(_parms=>{
      this.matchApi.getStatsOfSeason(_parms.season).subscribe(_stats=>{
        this.stats=_stats;
      })
    })
    this.titleService.setTitle('Στατιστικά - Α.Ο. Κέρκυρα');
    this.meta.addTags([
      { name: 'keywords', content: '' },
      { name: 'robots', content: 'index, follow' },
      {name:'description',content:'Στατιστικά σαιζόν'},
      {property:'title', content:'Στατιστικά - Α.Ο. Κέρκυρα'},
      {property:'og:title',content:'Στατιστικά - Α.Ο. Κέρκυρα'},
      {property:'og:image',content:'https://www.aokerkyra.com/images/ao_kerkyra_1968_400x400.png'}
    ]);
  }

}
import { Injectable } from '@angular/core';
import { ReplaySubject, Subscription } from 'rxjs';
import { CookieService } from './cookie.service';

declare const gtag: any;
const GID='G-RBN7K07949';


export interface AnalyticsEvent {
  type: 'PAGEVIEW' | 'EVENT';
  category?: string;
  path?: string;
  action?:string
  label: string;
}

@Injectable({
  providedIn: 'root'
})
export class AnalyticsService {

  eventsQueue$ = new ReplaySubject<AnalyticsEvent>();
  subscription: Subscription;
  constructor(private cookieService:CookieService) {
    this.cookieService.cookiePack.subscribe(_pop=>{      
      if(_pop.cookiesAccepted && _pop.statisticsAccepted){
        window['ga-disable-'+GID] = false;
        this.startTracking();
      }
      if(!_pop.cookiesAccepted || !_pop.statisticsAccepted){
        window['ga-disable-'+GID] = true;
        if(this.subscription!=null){
         this.subscription.unsubscribe();
        }        
        cookieService.delete_cookie('_ga','/','localhost');
        cookieService.delete_cookie('_ga_RBN7K07949','/','localhost');
        //console.log( window['ga-disable-'+GID]);
      }
       
    })
  }

  public startTracking(): void {
    gtag('config', GID);
    this.subscribeToEvents();
  }

  private subscribeToEvents(): void {
   this.subscription = this.eventsQueue$.subscribe((e: AnalyticsEvent) => {
      //console.log(e);
      if (e.type === 'PAGEVIEW') {
        gtag('config', GID, {
            page_title: e.label,
            page_path: e.path,
          });
      } else if (e.type === 'EVENT') {
        gtag('event', e.category, {            
            eventCategory: e.category,
            eventAction: e.action,
            eventLabel: e.label,
          });
      }
    });
  }

  public trackVirtualPageview(name: string,path:string): void {
    this.eventsQueue$.next({type: 'PAGEVIEW', label: name, path:path});
  }

  public trackEvent(category: string, action: string, label: string) {
    this.eventsQueue$.next({type: 'EVENT', category, action, label});
  }
}
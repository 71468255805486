<div class="container section mt-5">
    <div class="row  mb-1">
        <div class="col p-0">
            <div class="topbar">
                <h2>ΕΙΣΟΔΟΣ</h2>
            </div>
        </div>
    </div>
    <div class="row mt-1 no-gutters infoContentBox">
        <div class="col col-md-5 infoContainer " >
            <router-outlet></router-outlet>
            
        </div>
        <div class="infoContainer seperator col-auto col-md-2 text-center" >ή</div>
        <div class="col col-md-5 text-center infoContainer">
            <ul class="login-with-social">
                <li><button class="swipeButton facebook" (click)="loginFacebook()"><span><svg-icon src="assets/images/facebook.svg" [svgStyle]="{'width.px':30,'height.px':30,'fill':'#d6dbd2ff'}"></svg-icon></span>&nbsp;&nbsp;<span>ΕΙΣΟΔΟΣ ΜΕ FACEBOOK</span></button></li>
                <li><button class="swipeButton google" (click)="loginGoogle()"><span><svg-icon src="assets/images/Google__G__Logo.svg" [svgStyle]="{'width.px':30,'height.px':30}"></svg-icon></span>&nbsp;&nbsp;<span>ΕΙΣΟΔΟΣ ΜΕ GOOGLE</span></button></li>
                <li><button class="swipeButton twitter" (click)="loginTwitter()"><span><svg-icon src="assets/images/twitter-svgrepo-com.svg" [svgStyle]="{'width.px':30,'height.px':30,'fill':'#d6dbd2ff'}"></svg-icon></span>&nbsp;&nbsp;<span>ΕΙΣΟΔΟΣ ΜΕ ΤWITTER</span></button></li>
            </ul>          
        </div>
    </div>
</div>
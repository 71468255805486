import { ViewEncapsulation } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { DomSanitizer, Meta, Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { News } from 'src/app/shared/models/news';
import { ApiMatchesServiceService } from 'src/app/shared/services/api-matches-service.service';
import { ApiNewsService } from 'src/app/shared/services/api-news.service';
import { CookieService } from 'src/app/shared/services/cookie.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-article',
  templateUrl: './article.component.html',
  styleUrls: ['./article.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ArticleComponent implements OnInit {

  constructor(private route: ActivatedRoute, private router: Router, private matchApi: ApiMatchesServiceService, private newsApi: ApiNewsService, private sanitizer: DomSanitizer, private cookieService:CookieService, private meta:Meta, private titleService: Title) { }
  newObject: News;
  newId: number;
  matchid: number;

  title = "";
  date: Date;
  category = "";
  content = "";
  featImage = "";
  slug: string;  
  imc_id:Number;

  ngOnInit(): void {
    
    if (this.route.snapshot.data['type'] == "newsPage") {
      this.route.params.subscribe(params => {
        var that = this;
        this.slug = params.slug
        this.newsApi.getNewDetailsBySlug(this.slug).subscribe((_data)=> {
          that.newObject = _data;
          that.populateData();
          this.titleService.setTitle(_data.new_title+' - Α.Ο. Κέρκυρα');
          let img='https://www.aokerkyra.com/images/ao_kerkyra_1968_400x400.png';
          if(_data.images.filter(img => img.newsimages.nwi_isthumb == 0)[0]){
            img=environment.imagePath +_data.images.filter(img => img.newsimages.nwi_isthumb == 0)[0].img_path
          }
          this.meta.addTags([
            { name: 'keywords', content: '' },
            { name: 'robots', content: 'index, follow' },
            {name:'description',content:''},
            {property:'title', content:_data.new_title+' - Α.Ο. Κέρκυρα'},
            {property:'og:title',content:_data.new_title+' - Α.Ο. Κέρκυρα'},
            {property:'og:image',content:img}
          ]);
        });
      });
    } else if (this.route.snapshot.data['type'] == "matchPage") {
      this.route.parent.params.subscribe(params => {
        this.matchid = parseInt(params.id);
        var that = this;
        this.matchApi.getMatchDetails(this.matchid).subscribe(_res => {
          if (_res.news != null) {
            this.newId = _res.news.new_id;
            this.getData().then(function (_data: News) {
              that.newObject = _data;
              that.populateData();
            });
          }
        });
      });
    }
  }

  getDataSlug = function () {
    var promise = new Promise((resolve, reject) => {
      this.newsApi.getNewDetailsBySlug(this.slug).subscribe(_data => {
        resolve(_data);
      })
    });
    return promise;
  }

  getData = function () {
    var promise = new Promise((resolve, reject) => {
      this.newsApi.getNewDetails(this.newId).subscribe(_data => {
        resolve(_data);
      })
    });
    return promise;
  }

  populateData = function () {
    this.title = this.newObject.new_title;   
    if(this.newObject.new_imc_id!=null){
      this.imc_id=this.newObject.new_imc_id;
    }else{
      this.imc_id=null;
    }
    //video check
    this.newObject.new_content.replace('youtube.com','youtube-nocookie.com')
    this.cookieService.cookiePack.subscribe(_cp=>{
     
      if(!_cp.thirdpartyAccepted || !_cp.cookiesAccepted){
        this.newObject.new_content=this.newObject.new_content.replaceAll('youtube.com','youtube-nocookie.com')
      }
      this.content = this.sanitizer.bypassSecurityTrustHtml(this.newObject.new_content);
    })
    
    this.date = this.newObject.new_date;
    if (this.newObject.newscategories.length > 0) {
      this.category = this.newObject.newscategories[0].nwc_title;
    }
    if(this.newObject.images.filter(img => img.img_isthumb == 0).length){
     this.featImage = environment.imagePath + this.newObject.images.filter(img => img.newsimages.nwi_isthumb == 0)[0].img_path;
    }else{
      this.featImage='';
    }
  }
}

<div class="game" [ngClass]="{'ghost':ghost}">
    <h6>Βαθμολογία</h6>
    <div class="row">
        <div class="col">
            <table class="table">
                <thead>
                  <tr>
                    <th scope="col">#</th>
                    <th scope="col"></th>
                    <th scope="col">B</th>
                    <th scope="col">A</th>
                  </tr>
                </thead>
                <tbody >
                  <tr *ngFor="let item of leagueTable;let i = index" [ngClass]="{'aok':(item.tem_id==1)}">
                    <th scope="row">{{i+1}}</th>
                    <td >{{item.tem_name}}</td>
                    <td>{{item.points}}</td>
                    <td>{{item.totmatches}}</td>
                  </tr>                 
                </tbody>
              </table>
        </div>
    </div>
</div>
import { Component, OnInit } from '@angular/core';
import { Match } from 'src/app/shared/models/match';
import { ApiMatchesServiceService } from 'src/app/shared/services/api-matches-service.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-prev-game',
  templateUrl: './prev-game.component.html',
  styleUrls: ['./prev-game.component.scss']
})
export class PrevGameComponent implements OnInit {

  constructor(private matchService:ApiMatchesServiceService) { }
  matchObj:Match;
  home="";
  away="";
  homeBadge="";
  awayBadge="";
  date:Date=null;
  league="";
  leagueDay="";
  venue="";
  linkout:string="";
  homescore:Number;
  awayscore:Number;
  time:string="";
  imagePath=environment.imagePath;
  ghost=true;
  extrahome:number;
  extraaway:number;
  penhome:number;
  penaway:number;
  ngOnInit(): void {
    this.matchService.getLastMatch().subscribe(_res=>{
      this.matchObj=_res;
      this.home=this.matchObj.HomeTeam.tem_name;
      this.away=this.matchObj.AwayTeam.tem_name;
      this.homeBadge=environment.imagePath+this.matchObj.HomeTeam.image.img_path;
      this.awayBadge=environment.imagePath+this.matchObj.AwayTeam.image.img_path;
      this.date=this.matchObj.mch_date;
      this.league=this.matchObj.league.lea_name;
      this.venue=this.matchObj.mch_venue;
      this.homescore=this.matchObj.mch_homescore;
      this.awayscore=this.matchObj.mch_awayscore;
      this.penhome=this.matchObj.mch_penhomescore;
      this.penaway=this.matchObj.mch_penawayscore;
      this.extraaway=this.matchObj.mch_extraawayscore;
      this.extrahome=this.matchObj.mch_extrahomescore;
      if(this.matchObj.league.lea_ischamp){
        this.leagueDay="("+this.matchObj.mch_leagueday+"η αγωνιστική"+")";
      }
      if(this.matchObj.league.lea_iscup){
        this.leagueDay="("+this.matchObj.mch_leagueday+"ος γύρος"+")";
      }
     
      this.time=this.matchObj.mch_time.toString();
      //console.log(this.time);
      this.linkout="/match/"+this.matchObj.mch_id+"/report/";    
      this.ghost=false;
    });
  }

}

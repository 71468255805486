<div class="container mt-5">
    <div class="game" [ngClass]="{'ghost':ghost}">        
        <div class="row">
            <div class="col">
                <div class="table-responsive">
                <table class="table">
                    <thead>
                        <tr>
                            <th scope="col" colspan="4"></th>
                            <th scope="col" colspan="4">ΣΥΝΟΛΙΚΑ</th>
                            <th scope="col" colspan="4">ΕΝΤΟΣ ΕΔΡΑΣ</th>
                            <th scope="col" colspan="4">ΕΚΤΟΣ ΕΔΡΑΣ</th>
                     </tr>
                      <tr>
                        <th scope="col">#</th>
                        <th scope="col"></th>
                        <th scope="col">Bαθμοί</th>
                        <th scope="col">Aγώνες</th>
                        <th scope="col">Ν</th>
                        <th scope="col">Ι</th>
                        <th scope="col">Η</th>
                        <th scope="col">ΓΚΟΛ</th>
                        <th scope="col">Ν</th>
                        <th scope="col">Ι</th>
                        <th scope="col">Η</th>
                        <th scope="col">ΓΚΟΛ</th>
                        <th scope="col">Ν</th>
                        <th scope="col">Ι</th>
                        <th scope="col">Η</th>
                        <th scope="col">ΓΚΟΛ</th>
                      </tr>
                    </thead>
                    <tbody >
                      <tr *ngFor="let item of leagueTable;let i = index" [ngClass]="{'aok':(item.tem_id==1)}">
                        <th scope="row">{{i+1 || 0 }}</th>
                        <td >{{item.tem_name}}</td>
                        <td>{{item.points || 0}}</td>
                        <td>{{item.totmatches || 0}}</td>
                        <td>{{parseInt(item.win_away)+parseInt(item.win_home)  || 0}}</td>
                        <td>{{parseInt(item.tie_home)+parseInt(item.tie_away)  || 0}}</td>
                        <td>{{parseInt(item.totmatches)-parseInt(item.win_away)-parseInt(item.win_home)-parseInt(item.tie_home)-parseInt(item.tie_away)  || 0}}</td>
                        <td>{{parseInt(item.home_goals)+parseInt(item.away_goals)  || 0}} - {{parseInt(item.home_agoals)+parseInt(item.away_agoals)  || 0}} </td>
                        <td>{{parseInt(item.win_home)  || 0}}</td>
                        <td>{{parseInt(item.tie_home)  || 0}}</td>
                        <td>{{parseInt(item.hommatches)-parseInt(item.win_home)-parseInt(item.tie_home)  || 0}}</td>
                        <td>{{parseInt(item.home_goals)  || 0}} - {{parseInt(item.home_agoals)  || 0}} </td>
                        <td>{{parseInt(item.win_away)  || 0}}</td>
                        <td>{{parseInt(item.tie_away)  || 0}}</td>
                        <td>{{parseInt(item.totmatches)-parseInt(item.hommatches)-parseInt(item.win_away)-parseInt(item.tie_away)  || 0}}</td>
                        <td>{{parseInt(item.away_goals)  || 0}} - {{parseInt(item.away_agoals)  || 0}} </td>
                      </tr>                 
                    </tbody>
                  </table>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="container-fluid topbar">
    <div class="coverImage" *ngIf="headerImage!=null">
        <img [src]="headerImage" />
    </div>
    <div class="container">
        <div class="row">
            <div class="col text-center seasonName">
                <h1>{{seasonTitle}}</h1>
            </div>
        </div>
        <div class="row align-items-center">
            <div class="col text-center match-tabs">
                <ul>
                     <li routerLink="matches" routerLinkActive="active">ΑΓΩΝΕΣ</li>
                     <li routerLink="roster" routerLinkActive="active" *ngIf="hasPlayers">ΡΟΣΤΕΡ</li>
                     <li routerLink="table/{{league.lea_id}}" routerLinkActive="active" *ngFor="let league of leagues" >ΒΑΘΜΟΛΟΓΙΑ {{league.lea_name | removeTonation | uppercase}}</li>
                </ul>
            </div>
        </div>
    </div>
</div>

<router-outlet></router-outlet>
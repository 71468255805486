<div class="container section mt-5">
    <div class="row  mb-1"><div class="col p-0"><div class="topbar"><h2>ΨΗΦΙΣΕ ΤΟΝ MVP</h2></div></div></div>
    <div class="row mt-1 no-gutters">       
        <div class="col-lg-12 col-sm-12 what-is p-2">
            <h3>Πως ψηφίζω;</h3>
            Για να ψηφίσεις θα πρέπει να είσαι εγγεγραμμένος χρήστης
           
            <p *ngIf="cookiePack.preferenceAccepted && cookiePack.cookiesAccepted">Συμπληρώνοντας τα στοιχεία σου κάνοντας κλικ στο κουμπί "ΕΓΓΡΑΦΗ / ΕΙΣΟΔΟΣ" παρακάτω</p>
            <p *ngIf="!cookiePack.preferenceAccepted || !cookiePack.cookiesAccepted">Για να μπορέσετε να ψηφίσετε τον MVP της αγωνιστικής θα πρέπει να έχετε ενεργοποιήσει τα cookies "Προτιμήσεων"</p>        
            <div class="text-center p-3" *ngIf="!cookiePack.preferenceAccepted || !cookiePack.cookiesAccepted">
                <button class="swipeButton" (click)="cookieService.forcePopup=true"><span>ΠΡΟΒΟΛΗ COOKIES</span></button>
            </div>
        </div>
    </div>
</div>
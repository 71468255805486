import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-games-center',
  templateUrl: './games-center.component.html',
  styleUrls: ['./games-center.component.scss']
})
export class GamesCenterComponent implements OnInit {

  constructor() { }
  environment=environment;
  ngOnInit(): void {
  }

}

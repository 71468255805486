
<div class="container section mt-5">
    <div class="row  mb-1"><div class="col p-0"><div class="topbar"><h2>ΑΠΟΤΕΛΕΣΜΑΤΑ MVP</h2></div>
</div>
</div>
</div>
<div class="container mt-5">
    <div class="game ">
        <div class="row" >
            <div class="col">
                <div class="row justify-content-center align-items-center mt-1" *ngFor="let item of mvps;let i=index">
                    <div class="col-2 text-center" *ngIf="i>0">{{i+1}}</div>
                    <div class="col-2 text-center" *ngIf="i==0"><svg-icon src="assets/images/cup.svg" [svgStyle]="{ 'width.px':26,'height.px':26,'fill':'#E5446D' }"></svg-icon></div>
                    <div class="col-4 col-sm-5 col-md-4 playerName"><a href="/player/{{item.player.plr_slug}}">{{item.player.plr_name}} {{item.player.plr_surname}}</a></div>
                    <div class="col-4 col-sm-6 col-md-4"><ngb-progressbar [showValue]="false" type="burgunde" [striped]="true" height="40px" value="{{(item.total_amount/total)*100}}" max="100"></ngb-progressbar></div>
                    <div class="col-1">{{(item.total_amount/total) | percent}}</div>
                </div>
                <hr/>
                Σε σύνολο {{total}} ψήφων
            </div>
        </div>
    </div>
</div>
<app-mvp-vote *ngIf="(loggedin$ | async)!==true"></app-mvp-vote>
<router-outlet *ngIf="votemode"></router-outlet>

import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/shared/services/auth.service';

@Component({
  selector: 'app-mvp-thanks',
  templateUrl: './mvp-thanks.component.html',
  styleUrls: ['./mvp-thanks.component.scss']
})
export class MvpThanksComponent implements OnInit {

  constructor(private auth:AuthService, private route:ActivatedRoute) { }
  backroute="";
  matchid=0;
  ngOnInit(): void {
    this.matchid=this.route.parent.parent.snapshot.params.id;
    this.backroute="/match/"+this.matchid+"/mvp";
  }

  logout(){
    console.log(this.backroute)
    this.auth.logout(this.backroute);
  }

}

import { environment } from "src/environments/environment";
import { Images } from "./images";

export class News {
    new_id:number;
    new_title:string;
    new_secondtitle:string;
    new_date:Date;
    new_content:string;
    new_ispublished:boolean;
    new_writer:string;
    new_order:number;
    new_views:number;
    new_islarge:boolean;
    new_slug:string;
    new_isfeatured:boolean;
    images:Images[];
    newscategories:NewsCategories[];
    new_imc_id:number;
    
    public static getGhostNew():News{
        let nw=new News();
        nw.new_title='ghost';
        let im=Images.getGhostImage();       
        nw.images=[im];
        return nw;
    }
    
}

export class NewsCategories{
    $nwc_id:number;
    nwc_title:string;
    nwc_slug:string;
}

export class NewsList{    
    count:number;
    rows:News[];
}

export class HomePageNews{
    hpn_id:number;
    hpn_new_id:number;
    hpn_order:number;
    hpn_place:string;
    news:News;
}


/*"new_id":6926,
"new_title":"Δικός μας και ο Σταύρος Κέντρο",
"new_secondtitle":"Ένας νεαρός και πολλά υποσχόμενος μπακ/χαφ είναι το τελευταίο μεταγραφικό απόκτημα του Α.Ο. Κέρκυρα.",
"new_date":"2020-11-12T00:00:00.000Z",
"new_content":"<p><img mce_src=\"/images/playerwelcome_stavro_kentro.jpg\" alt=\"\" border=\"\" hspace=\"\" vspace=\"\" width=\"\" height=\"\" align=\"\" src=\"/images/playerwelcome_stavro_kentro.jpg\"></p><p>Ο 19χρονος Σταύρος Κέντρο ξεκίνησε απο τις υποδομές της Corfu FC όπου απο την σαιζόν 16-17 βρέθηκε στην ανδρική της ομάδα. Το 2018 μεταπήδησε στον ΝΕΟ 2017, ενώ το πρώτο μισό της περσινή σαιζόν έπαιζε στην Κ19 του Α.Ο. Κασσιώπη. Τελευταία του ομάδα ο Αστέρας Λιαπάδων.</p>",
"new_ispublished":"1",
"new_writer":null,
"new_order":10,
"new_views":373,
"new_islarge":1,
"new_slug":"dikos-mas-kai-o-stayros-kentro",
"new_isfeatured":0,
"images":[
          {"img_id":6769,"img_title":"stavros kentro welcome 453","img_path":"images/playerwelcome_stavro_kentro_453.jpg","img_isthumb":"0","newsimages":{"nwi_new_id":6926,"nwi_img_id":6769,"nwi_legend":"Καλωσήλθες Σταύρο Κέντρο!","nwi_isthumb":"0"}},{"img_id":6770,"img_title":"stavros kentro welcome 453 sq","img_path":"images/playerwelcome_stavro_kentro_453_squ.jpg","img_isthumb":"0","newsimages":{"nwi_new_id":6926,"nwi_img_id":6770,"nwi_legend":"Καλωσήλθες Σταύρο Κέντρο!","nwi_isthumb":"1"}}],
            "newscategories":[{"nwc_id":13,"nwc_title":"Μεταγραφές - Αποδεσμεύσεις","newscategorydetails":{"ncd_new_id":6926,"ncd_nwc_id":13}}]*/
<div class="container section mt-sm-5">
    <div class="row  mb-1"><div class="col"><div class="topbar"><h2>ΙΣΤΟΡΙΚΑ ΣΤΟΙΧΕΙΑ</h2></div></div></div>
    
        <div class="row">
            <div class="col-md-4 position-relative" *ngFor="let item of feautured">                
                <app-featured [newsObj]="item">
    
                </app-featured>
            </div>
           
        </div>
    
</div>
<div class="container section mt-5">
    <div class="row  mb-1"><div class="col"><div class="topbar"><h2>ΑΡΧΕΙΟ</h2></div></div></div>
    <div class="row">
        <div class="col-md-4 col-lg-4 col-sm-6 position-relative" *ngFor="let item of rows">
            <app-season-item [seasonObject]="item"></app-season-item>
        </div>
    </div>
</div>


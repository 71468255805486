<div class="container mt-5">
    <div class="game">
        <div class="row">
            <div class="col">
                <div class="table-responsive">
                    <table class="table">
                        <thead>
                            <tr>
                                <th scope="col"></th>
                                <th scope="col"></th>
                                <th scope="col">Αγώνες</th>
                                <th scope="col">Λεπτά σμτχ.</th>
                                <th scope="col" class="bg-danger yellow"></th>
                                <th scope="col" class="bg-warning"></th>
                                <th scope="col">Γκόλ</th>
                                <th scope="col">Γκόλ/Αγώνα</th>
                            </tr>

                        </thead>
                        <tbody>
                            <tr *ngFor="let item of statsRows;let i = index">
                                <th scope="row">{{item.shirtnumber || 0 }}</th>
                                <td class="plr"><a [routerLink]="'/player/'+item.plr_slug"
                                        [innerHtml]="item.plr_name+' '+item.plr_surname"></a></td>
                                <td>{{item.matches1}}({{item.changes}})</td>
                                <td>{{item.minutes}}'</td>
                                <td>{{item.yellow}}</td>
                                <td>{{item.red}}</td>
                                <td>{{item.goals}}</td>
                                <td>{{item.goals/item.matches1 | number}}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>

    </div>
</div>
<div class="container followus">
    <div class="row">
        <div class="col-4 text-center p-3">
            <a href="https://www.facebook.com/aokerkyra1968" target="_blank"><svg-icon src="assets/images/facebook.svg" [svgStyle]="{'width.px':60,'height.px':60,'fill':'#5f1a37ff'}"></svg-icon></a>
        </div>
        <div class="col-4 text-center  p-3">
            <a href="https://twitter.com/aokerkyra" target="_blank"><svg-icon src="assets/images/twitter-svgrepo-com.svg" [svgStyle]="{'width.px':60,'height.px':60,'fill':'#5f1a37ff'}"></svg-icon></a>
        </div>
        <div class="col-4 text-center  p-3">
            <a href="https://www.instagram.com/aokerkyra1968/" target="_blank"><svg-icon src="assets/images/iconmonstr-instagram-11.svg" [svgStyle]="{'width.px':60,'height.px':60,'fill':'#5f1a37ff'}"></svg-icon></a>
        </div>
        <div class="col-4 text-center  p-3">
            <a href="https://www.youtube.com/channel/UCRzG5WBvvmqw1yzm2Zti5pA" target="_blank"><svg-icon src="assets/images/youtube.svg" [svgStyle]="{'width.px':60,'height.px':60,'fill':'#5f1a37ff'}"></svg-icon></a>
        </div>
        <div class="col-4 text-center  p-3">
            <a href="https://www.linkedin.com/company/ao-kerkyra" target="_blank"><svg-icon src="assets/images/linkedin.svg" [svgStyle]="{'width.px':60,'height.px':60,'fill':'#5f1a37ff'}"></svg-icon></a>
        </div>
    </div>   
</div>

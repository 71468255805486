import { HostListener } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { Router, NavigationStart, NavigationEnd, NavigationError } from '@angular/router';
import { SwPush } from '@angular/service-worker';
import {isMobile} from 'detect-touch-device';
import { Match } from 'src/app/shared/models/match';
import { News, NewsList } from 'src/app/shared/models/news';
import { Player } from 'src/app/shared/models/player';
import { Webwatch } from 'src/app/shared/models/webwatch';
import { ApiMatchesServiceService } from 'src/app/shared/services/api-matches-service.service';
import { ApiNewsService } from 'src/app/shared/services/api-news.service';
import { ApiPlayersService } from 'src/app/shared/services/api-players.service';
import { NotificationSubscribeServiceService } from 'src/app/shared/services/notification-subscribe-service.service';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  scrolledAbit=false;
  public showMenu=false;
  newsRows:News[]=[];
  news:NewsList;
  nextMatches:Match[];
  prevMatches:Match[];
  goalies:Player[];
  defenders:Player[];
  midfielders:Player[];
  forwards:Player[]
  staff:Player[];
  webwatch:Webwatch[];
  notifs=true;
  deferredPrompt;
  promptInstall=false;
  readonly VAPID_PUBLIC_KEY = "BLwHXMXHDEUkR2JSb0isfwHMOFGaKq92sh3vUMUBsD2SjoY2ApN722m87sx4jXFZYggmwzFuEcgvm0XsCmbU6ZE";


  constructor(private router:Router, private newsApi:ApiNewsService, private matchApi:ApiMatchesServiceService, private playerApi:ApiPlayersService,private swPush: SwPush, private notifService:NotificationSubscribeServiceService) {
    router.events.subscribe( (_ev) => {
      
      if (_ev instanceof NavigationStart) {
          this.showMenu = false;
      }

      if (_ev instanceof NavigationEnd) {
          // Hide loading indicator
          this.showMenu = false;
      }

      if (_ev instanceof NavigationError) {
          // Hide loading indicator
          // Present error to user
          //console.log(_ev.error);
      }
    });
    this.newsApi.getNewsTop().subscribe(_res=>{
      this.news=_res;
      this.newsRows=this.news.rows;
    },(error)=>{});
    this.matchApi.getPrevMatches(2).subscribe(_res=>{
      this.prevMatches=_res;
    },(error)=>{});
    this.matchApi.getNextMatches(2).subscribe(_res=>{
      this.nextMatches=_res;
    },(error)=>{});
    this.playerApi.getCurrentPlayers(environment.currentSeason).subscribe(_res=>{
      this.goalies=_res.filter(_player=>_player.positions.filter(_pos=>_pos.pos_name=="Τερματοφύλακας").length);
      this.defenders=_res.filter(_player=>_player.positions.filter(_pos=>_pos.pos_name=="Αμυντικός").length);
      this.midfielders=_res.filter(_player=>_player.positions.filter(_pos=>_pos.pos_name=="Μέσος").length);
      this.forwards=_res.filter(_player=>_player.positions.filter(_pos=>_pos.pos_name=="Επιθετικός").length);
      this.staff=_res.filter(_player=>_player.positions.filter(_pos=>_pos.pos_name=="Τεχνικός").length).sort((a,b)=>(a.positions[0].pos_name=="Προπονητής")?-1:1);
      //console.log(this.goalies);
    },(error)=>{})
    this.newsApi.getWebWatch().subscribe(_res=>{
      this.webwatch=_res;
    },(error)=>{})
   }
  isMobile=isMobile;
  
  ngOnInit(): void {
    
  }

  

  @HostListener('window:scroll', ['$event']) onScrollEvent($event){
    const verticalOffset = window.pageYOffset 
          || document.documentElement.scrollTop 
          || document.body.scrollTop || 0;

        if(verticalOffset>50){
          this.scrolledAbit=true;
        }else{
          this.scrolledAbit=false;
        }
  } 

}

<div class="container section mt-sm-5">
    <div class="row  mb-1">
        <div class="col">
            <div class="topbar">
                <h2>ΦΟΡΜΑ ΕΠΙΚΟΙΝΩΝΙΑΣ</h2>
            </div>
        </div>
    </div>

    <div class="row infoContentBox mt-3 no-gutters">
        <div class="col">
            <div class="infoContainer" *ngIf="notsubmitted">                
                <p><b>Συμπληρώστε την φόρμα για να επικοινωνήσετε μαζί μας:</b></p>
                <form [formGroup]="form" (ngSubmit)="onSubmit()">
                    <div class="form-group mb-3">
                        <label for="username">Ονοματεπώνυμο*</label>
                        <input type="text" formControlName="name" class="form-control"
                            [ngClass]="{ 'is-invalid': submitted && f.name.errors }" />
                        <div *ngIf="submitted && f.name.errors" class="invalid-feedback">
                            <div *ngIf="f.name.errors.required">Tο ονοματεπώνυμο είναι απαραίτητο</div>
                        </div>
                    </div>
                    <div class="form-group mb-3">
                        <label for="username">E-mail*</label>
                        <input type="email" formControlName="email" class="form-control"
                            [ngClass]="{ 'is-invalid': submitted && f.email.errors }" />
                        <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                            <div *ngIf="f.email.errors.required">Tο e-mail είναι απαραίτητο</div>
                            <div *ngIf="f.email.errors.pattern">Tο e-mail δεν είναι έγκυρο</div>
                        </div>
                    </div>
                    <div class="form-group mb-3">
                        <label for="username">Τηλέφωνο επικοινωνίας</label>
                        <input type="tel" formControlName="tel" class="form-control"
                            [ngClass]="{ 'is-invalid': submitted && f.tel.errors }" />
                        <div *ngIf="submitted && f.tel.errors" class="invalid-feedback">
                            <div *ngIf="f.tel.errors.required">Tο e-mail είναι απαραίτητο</div>
                        </div>
                    </div>
                    <div class="form-group mb-3">
                        <label for="username">Θέμα*</label>
                        <input type="text" formControlName="subject" class="form-control"
                            [ngClass]="{ 'is-invalid': submitted && f.subject.errors }" />
                        <div *ngIf="submitted && f.subject.errors" class="invalid-feedback">
                            <div *ngIf="f.subject.errors.required">Tο θέμα είναι απαραίτητο</div>
                        </div>
                    </div>
                    <div class="form-group mb-3">
                        <label for="exampleFormControlTextarea1">Το μήνυμά σας*</label>
                        <textarea class="form-control" formControlName="message"
                            [ngClass]="{ 'is-invalid': submitted && f.message.errors }" rows="3"></textarea>
                        <div *ngIf="submitted && f.message.errors" class="invalid-feedback">
                            <div *ngIf="f.message.errors.required">Tο μήνυμα είναι απαραίτητο</div>
                        </div>
                    </div>
                    
                    <div class="form-group mb-3 text-center">
                        <button [disabled]="loading" class="swipeButton">
                            <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
                            <span>ΑΠΟΣΤΟΛΗ</span>
                        </button>
                    </div>
                </form>
            </div>
            <div class="infoContainer" *ngIf="!notsubmitted">       
                <h3>Ευχριστoύμε</h3>
                που επικοινωνήσατε μαζί μας! <br/>
            </div>
        </div>
        <div class="col p-0 d-none d-sm-block">
            <img src="{{environment.imagePath}}images/contact_us_side.webp" class="img-fluid">
        </div>
    </div>

</div>

<div class="container section mt-sm-5">
    <div class="row  mb-1">
        <div class="col">
            <div class="topbar">
                <h2>ΣΤΟΙΧΕΙΑ ΕΠΙΚΟΙΝΩΝΙΑΣ</h2>
            </div>
        </div>
    </div>

    <div class="row infoContentBox mt-3 no-gutters">
        <div class="col">
            <div class="infoContainer">               
                <ul>
                  <li class="row">
                        <div class="col-1"><svg-icon src="assets/images/home.svg" [svgStyle]="{ 'width.px':26,'height.px':26,'fill':'#E5446D' }"></svg-icon></div>
                        <div class="col-4 position">ΔΙΕΥΘΥΝΣΗ</div>
                        <div class="col-7 name">Εθνικό Στάδιο Κέρκυρας,<br/>Κώστα Γεωργάκη 21<br/>
                            Κέρκυρα<br/>
                            49100</div>
                    </li>
                    <li class="row">
                        <div class="col-1"><svg-icon src="assets/images/call.svg" [svgStyle]="{ 'width.px':26,'height.px':26,'fill':'#E5446D' }"></svg-icon></div>
                        <div class="col-4 position">ΤΗΛΕΦΩΝΑ</div>
                        <div class="col-7 name">26610 24761</div>
                    </li>    
                    <li class="row">
                        <div class="col-1"><svg-icon src="assets/images/letter.svg" [svgStyle]="{ 'width.px':26,'height.px':26,'fill':'#E5446D' }"></svg-icon></div>
                        <div class="col-4 position">Ε-MAIL</div>
                        <div class="col-7 name">info@aokerkyra.com</div>
                    </li>
                                  
                </ul>
            </div>
        </div>
        <div class="col p-0 d-none d-sm-block">
            <agm-map [latitude]="lat" [longitude]="lng" [zoom]="18" class="map"
                [gestureHandling]="'cooperative'">
                <agm-marker [latitude]="lat" [longitude]="lng" animation="DROP" [openInfoWindow]="true"
                    [iconUrl]="'/images/mappin_aok.png'">
                </agm-marker>

            </agm-map>
        </div>
           
       
    </div>
</div>
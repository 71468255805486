<div class="container-fluid topbar">
    <div class="coverImage">
        <img [src]="headerImage" />
    </div>
    <div class="container">
        <div class="row stadiumAndDate">
            <div class="col text-left stadium">
                {{venue}}
            </div>
            <div class="col text-center league">
                {{league}}<br/>
                {{leagueDay}}
            </div>
            <div class="col text-right datetime">
                {{date | date:'d/M/y'}} {{time | slice:0:5}}
            </div>
        </div>
        <div class="row align-items-start teamsAndScore">
            <div class="col-6 col-lg teamHome text-center order-1 order-lg-1">
                <img src="{{homeBadge}}">
            </div>
            <div class="col-6 col-lg teamHome order-4 order-lg-2 text-end">
                <div>
                {{home | removeTonation | uppercase}}
                <div class="scorers">{{homescorers}}</div>
                </div>
            </div>
            <div class="col-12 col-lg-auto score order-3 order-lg-3 text-center">{{homescore}}-{{awayscore}}</div>
            <div class="col-6 col-lg order-5 teamAway order-lg-4 text-start">
                <div>
                {{away | removeTonation | uppercase}}
                <div class="scorers">{{awayscorers}}</div>
                </div>
            </div>
            <div class="col-6 col-lg teamHome text-center order-2 order-lg-5 ">
                <img src="{{awayBadge}}"/>
            </div>
            <div class="extratime text-center col-12 order-lg-100 order-100" *ngIf="extrahome!=null">
                Παράταση<br><span>{{extrahome}} - {{extraaway}}</span>
            </div>
            <div class="penalties text-center col-12 order-lg-100 order-100" *ngIf="penhome!=null">
                Πέναλτις<br>{{penhome}} - {{penaway}}
            </div>
        </div>

       <div class="row align-items-center">
           <div class="col text-center match-tabs">
               <ul>
                    <li *ngIf="report!=null && report!=0" routerLink="report"  routerLinkActive="active">Report αγώνα</li>
                    <li routerLink="related" routerLinkActive="active">Άρθρα για τον αγώνα</li>
                    <li *ngIf="homescore!=null" routerLink="mvp" routerLinkActive="active">MVP</li>
                    <li *ngIf="photostory!=null && photostory!=0" routerLink="photostory" routerLinkActive="active">Photostory</li>
               </ul>
           </div>
       </div>
    </div>
</div>
<div>
    <router-outlet></router-outlet>
</div>


<div class="container mt-lg-5">
    <div class="text-holder">
        <h4>{{position | removeTonation | uppercase}}</h4>
        <h3>{{name}} {{surname}}</h3>       
        <div class="entryDate">Ημ. Γέννησης {{birthdate | date:'d/M/Y'}} ({{birthdate | datediff}})</div>
    </div>
    <div class="image-holder">
        <img src="{{photo}}"/>
    </div>
    </div>
    <div class="container">
            <div class="row justify-content-center">
        <div class="col-lg-8 col-md-12 text-content mt-5" [innerHtml]="history">
            
        </div>
    </div>
    <div class="row justify-content-center" *ngIf="seasons!=null && visibleSeason!=null" >
            <div class="col-lg-8 col-md-12 text-content mt-5">
                <div ngbDropdown class="d-inline-block">
                    <button class="btn btn-outline-primary" id="dropdownBasic1" ngbDropdownToggle >{{visibleSeason.ses_title}}</button>
                    <div ngbDropdownMenu aria-labelledby="dropdownBasic1"  >
                      <button *ngFor="let ses of seasons" ngbDropdownItem (click)="filterSeasonData(ses.ses_id)">{{ses.ses_title}}</button>                    
                    </div>
            </div>
            <div class="table-responsive mt-5">
            
            
            <table class="table table-striped ">
                <thead>
                    <tr ><td colspan="4"><strong>Αγώνας</strong></td><td>&raquo;</td><td>&laquo;</td><td width="9">&nbsp;</td><td width="9">&nbsp;</td><td><strong>Γκόλ</strong></td>
                    <td *ngIf="position=='Τερματοφύλακας'">Π.Πέναλτι</td>
                </tr></thead>
            	<tbody>				
				    <tr *ngFor="let item of visibleMatches">
                        <td>{{item.league.lea_name}}<span *ngIf="item.mch_leagueday">{{" - "+item.mch_leagueday || ""}}{{(item.league.lea_ischamp)?'η αγ.':'ος γύρος'}}</span></td>
                        <td>{{item.mch_date | date:'d/M/y'}}</td>
                        <td><a routerLink="/match/{{item.mch_id}}">{{item.HomeTeam.tem_name}}-{{item.AwayTeam.tem_name}}</a></td>
                        <td class="nowrap">{{item.mch_homescore}}-{{item.mch_awayscore}}</td>
                        <td>{{item.matchplayers.mpl_gotin>0?item.matchplayers.mpl_gotin:''}}</td>
                        <td>{{item.matchplayers.mpl_gotout<90?item.matchplayers.mpl_gotout:''}}</td>
                        <td [ngStyle]="{backgroundColor:item.matchplayers.mpl_isyellow ? '#ffe221':'transparent'}"></td>
                        <td [ngStyle]="{backgroundColor:item.matchplayers.mpl_isred ? '#b50000':'transparent'}"></td>
                        <td ><svg-icon src="assets/images/publicdomainq-ball.svg" [svgStyle]="{ 'width.px':16,'height.px':16,'fill':'#E5446D'}" *ngFor="let ik of numSequence(item.matchplayers.mpl_goals)"></svg-icon></td>
                        <td *ngIf="position=='Τερματοφύλακας'"><svg-icon src="assets/images/icon-penalty-shootout-missed.svg" [svgStyle]="{ 'width.px':16,'height.px':16}" *ngFor="let ik of numSequence(item.matchplayers.mpl_penaltycatch)"></svg-icon></td>
					</tr>                
                   
                
                        </tbody></table></div>
        </div>
    </div>
</div>

<div class="container">
    <div class="row topbar mb-1"><div class="col"><h2 >ΣΧΕΤΙΚΑ ΝΕΑ</h2></div></div>
    <div class="row" >        
        <div class="col-lg-3 col-md-4" *ngFor="let item of news">
            <app-single-new-preview  [newsObj]="item">
                
            </app-single-new-preview>
        </div>       
    </div>
</div>

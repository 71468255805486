import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { Season } from 'src/app/shared/models/match';
import { News } from 'src/app/shared/models/news';
import { ApiMatchesServiceService } from 'src/app/shared/services/api-matches-service.service';
import { ApiNewsService } from 'src/app/shared/services/api-news.service';
import { ToastService } from 'src/app/shared/services/toast-service.service';

@Component({
  selector: 'app-history-page',
  templateUrl: './history-page.component.html',
  styleUrls: ['./history-page.component.scss']
})
export class HistoryPageComponent implements OnInit {

  constructor(private matchApi:ApiMatchesServiceService, private newsApi:ApiNewsService, private toastApi:ToastService,private meta:Meta, private titleService: Title) { }
  rows:Season[]=[];
  feautured:News[]=[News.getGhostNew(),News.getGhostNew(),News.getGhostNew()]
  ngOnInit(): void {
    for(var i=0;i<12;i++){
      this.rows.push(Season.getGhostSeason());
    }
    this.matchApi.getSeasonsPast().subscribe(_res=>{
      this.rows=_res;
    }) 
    this.newsApi.getNewsPaged(1,'istoria').subscribe(_res=>{
      this.feautured=_res.rows;
    },(error)=>{      
         
    })
    this.titleService.setTitle('Ιστορία - Α.Ο. Κέρκυρα');
    this.meta.addTags([
      { name: 'keywords', content: 'ιστορία, ύμνος, ίδρυση' },
      { name: 'robots', content: 'index, follow' },
      {name:'description',content:'Ιστορία'},
      {property:'title', content:'Ιστορία - Α.Ο. Κέρκυρα'},
      {property:'og:title',content:'Ιστορία - Α.Ο. Κέρκυρα'},
      {property:'og:image',content:'https://www.aokerkyra.com/images/ao_kerkyra_1968_400x400.png'}
    ]);  
  }

}

<p *ngFor="let alert of alerts">
    <ngb-alert [type]="alert.type" (closed)="close(alert)">{{ alert.message }}</ngb-alert>
</p>
<form [formGroup]="form" (ngSubmit)="onSubmit()">
    <div class="form-group mb-3">
        <label for="username">E-mail</label>
        <input type="email" formControlName="email" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.email.errors }" />
        <div *ngIf="submitted && f.email.errors" class="invalid-feedback">                     
            <div *ngIf="f.email.errors.required">Tο e-mail είναι απαραίτητο</div>
            <div *ngIf="f.email.errors.pattern">Tο e-mail δεν είναι έγκυρο</div>
        </div>
    </div>

    <div class="form-group mb-3">
        <label for="password">Password</label>
        <input type="password" formControlName="password" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.password.errors }" />
        <div id="passwordHelpBlock" class="form-text">Το password πρέπει να περιλαμβάνει τουλάχιστον 6 χαρακτήρες</div>
        <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
            <div *ngIf="f.password.errors.required">To password είναι απαραίτητο</div>
            
        </div>
    </div>    
    
    <div class="form-group mb-3">
        <div class="form-check form-switch">
            <input class="form-check-input" formControlName="acceptTerms" type="checkbox" [ngClass]="{ 'is-invalid': submitted && f.acceptTerms.errors }" id="gridCheck1">
            <label class="form-check-label" for="gridCheck1">
              Έχω διαβάσει και αποδέχομαι την πολιτική απορρήτου
            </label>  
            <div *ngIf="submitted && f.acceptTerms.errors" class="invalid-feedback">
                <div *ngIf="f.acceptTerms.errors.required">Η αποδοχή των όρων είναι απαραίτητη</div>
            </div>          
          </div>
         
    </div>
    <div class="form-group mb-3">
        <div class="form-check">
            <input class="form-check-input" formControlName="acceptNewsletter" type="checkbox" id="gridCheck2">
            <label class="form-check-label" for="gridCheck2">
              Επιθυμώ να λαμβάνω newsletter απο τον Α.Ο. Κέρκυρα
            </label>
         
          </div>        
    </div>
    <div class="form-group mb-3">
        <button [disabled]="loading" class="swipeButton">
            <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
            <span>ΕΓΓΡΑΦΗ</span> 
        </button>        
        <a routerLink="../../login" class="btn btn-link">Επιστροφή για είσοδο</a>
    </div>
</form>
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MonthMatches } from 'src/app/shared/models/match';
import { ApiMatchesServiceService } from 'src/app/shared/services/api-matches-service.service';

@Component({
  selector: 'app-season-matches',
  templateUrl: './season-matches.component.html',
  styleUrls: ['./season-matches.component.scss']
})
export class SeasonMatchesComponent implements OnInit {

  constructor(private router:ActivatedRoute,private matchApi:ApiMatchesServiceService) { }
  months=[];
  earliestDate:Date;
  latestDate:Date;
  type="upcoming";
  isChamp=true;
  ngOnInit(): void {
    this.router.parent.params.subscribe(_params=>{
      this.months=[];
      var season=_params.id;
      this.matchApi.getSeasonMatches(season).subscribe(_res=>{
        var mm=new MonthMatches();
        mm.monthName=_res[0].league.lea_name;
        mm.matches=_res;
        this.months.push(mm);
        if(mm.matches[0].league.lea_ischamp){
          this.isChamp=true;
        }else{
          this.isChamp=false;
        }
      })
    })
  }

}

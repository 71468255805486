import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { throwError } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { League, Match, Season } from '../models/match';
import { StatsRow } from '../models/player';
import { TableRow } from '../models/table-row';

const apiUrl = environment.serverapi;

@Injectable({
  providedIn: 'root'
})
export class ApiMatchesServiceService {

  constructor(private http: HttpClient) { }
  handleError(error) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // client-side error
      errorMessage = `Error: ${error.error.message}`;
    } else {
      // server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    //window.alert(errorMessage);
    return throwError(errorMessage);
  }

  getNextMatch() {
    var url='';   
    url = `${apiUrl}nextgame/`;    
    return this.http.get<Match>(url)
    .pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  getLastMatch() {
    var url='';   
    url = `${apiUrl}lastgame/`;    
    return this.http.get<Match>(url)
    .pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  getTodayMatches() {
    var url='';   
    url = `${apiUrl}todaygames/`;    
    return this.http.get<Match[]>(url)
    .pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  getPrevMatches(howmany:number) {
    var url='';   
    url = `${apiUrl}lastgames/${howmany}`;    
    return this.http.get<Match[]>(url)
    .pipe(
      retry(1),
      catchError(this.handleError)
    );
  }
  getNextMatches(howmany:number) {
    var url='';   
    url = `${apiUrl}nextgames/${howmany}`;    
    return this.http.get<Match[]>(url)
    .pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  getLeagueMatches(league:number){
    var url='';   
    url = `${apiUrl}leaguegames/${league}`;    
    return this.http.get<Match[]>(url)
    .pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  getMatchDetails(matchid:number) {
    var url='';   
    url = `${apiUrl}gamedetails/${matchid}`;    
    return this.http.get<Match>(url)
    .pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  getMatchPerMonth(month:number,year:number){
    var url='';   
    url = `${apiUrl}monthgames/${month}/${year}`;    
    return this.http.get<Match[]>(url)
    .pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  getLeagueTable(league:number){
    var url='';   
    url = `${apiUrl}leaguetable/${league}`;    
    return this.http.get<TableRow[]>(url)
    .pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  getSeasonsPast(){
    var url='';   
    url = `${apiUrl}seasons/`;    
    return this.http.get<Season[]>(url)
    .pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  getSeasonPast(seasonid:number){
    var url='';   
    url = `${apiUrl}season/${seasonid}`;    
    return this.http.get<Season>(url)
    .pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  getSeasonMatches(season:number){
    var url='';   
    url = `${apiUrl}seasongames/${season}`;    
    return this.http.get<Match[]>(url)
    .pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  getLeaguesOfSeason(season:number){
    var url='';   
    url = `${apiUrl}seasonleagues/${season}`;    
    return this.http.get<League[]>(url)
    .pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  getLeagueDetails(league:number){
    var url='';   
    url = `${apiUrl}league/${league}`;    
    return this.http.get<League>(url)
    .pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  getStatsOfSeason(season:number){
    var url='';
    url = `${apiUrl}statstable/${season}`;    
    return this.http.get<StatsRow[]>(url)
    .pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  getMatchHasMvp(match:number){
    var url='';
    url = `${apiUrl}matchhasmvp/${match}`;    
    return this.http.get<any>(url)
    .pipe(
      retry(1),
      catchError(this.handleError)
    );
  }
}


<header class="container-fluid burgundy"  [ngClass]="{'touch':isMobile}">

    <div class="container top-nav" [ngClass]="{'shring':scrolledAbit}">
        <ul>
            <li><a routerLink="/contact">ΕΠΙΚΟΙΝΩΝΙΑ</a></li>
            
        </ul>
    </div>
    <div class="container main-nav">
        <div class="logoHolder" [ngClass]="{'shring':scrolledAbit}">
            <svg-icon src="assets/images/ao_kerkyra_1968.svg" [svgStyle]="{ 'width.px':110,'height.px':110 }"
                routerLink="/"></svg-icon>
        </div>
        <div class="menu container">

            <ul [ngClass]="{'toggleMobile':showMenu}">
                <li>
                    <div class="tileItem">
                        <a routerLink="/article/category/all">NEA</a>
                    </div>
                    <div class="childMenu container-fluid">
                        <div class="container">
                            <div class="row">
                                <div class="col">
                                </div>
                                <div class="col">
                                    <ul class="catlist">
                                        <li><a routerLink="/article/category/nea-ths-proponhshs">Νέα της προπόνησης</a></li>
                                        <li><a routerLink="/article/category/dioikhtika-nea">Διοικητικά Νέα</a></li>
                                        <li><a routerLink="/article/category/metagrafes-apodesmeyseis">Μεταγραφές -
                                                Αποδεσμεύσεις</a></li>
                                        <li><a routerLink="/article/category/synenteykseis">Συνεντεύξεις</a></li>
                                    </ul>
                                </div>
                                <div class="col">
                                    <h3>ΤΕΛΕΥΤΑΙΑ ΝΕΑ</h3>
                                    <ul class="newlist" *ngFor="let item of newsRows">
                                        <li><a routerLink="/article/{{item.new_slug}}">
                                                <svg-icon src="assets/images/file-svgrepo-com.svg"
                                                    [svgStyle]="{ 'width.px':16,'height.px':16,'fill':'#E5446D' }">
                                                </svg-icon> {{item.new_title}}
                                            </a>&nbsp;<span>{{item.new_date | date:'d/M/y'}}</span></li>

                                    </ul>
                                </div>
                                <div class="col">
                                    <h3>WEBWATCH</h3>
                                    <ul class="webwatch">
                                        <li routerLink="outerlink" *ngFor="let item of webwatch">
                                            <b>{{item.website.wes_title}}</b> <a [href]="item.wen_link"
                                                target="_blank">{{item.wen_title}} </a><span>{{item.wen_date |
                                                date:'d/M/y'}}</span>
                                        </li>
                                    </ul>
                                </div>
                            </div>

                        </div>
                    </div>


                </li>
                <li>
                    <div class="tileItem">
                        <a routerLink="/players">ΟΜΑΔΑ</a>
                    </div>
                    <div class="childMenu container-fluid players">
                        <div class="container">
                            <div class="row">
                                <div class="col">
                                </div>
                                <div class="col">
                                    <h3>ΤΕΡΜΑΤΟΦΥΛΑΚΕΣ</h3>
                                    <ul class="playerlist" *ngFor="let item of goalies">
                                        <li routerLink="/player/{{item.plr_slug}}">
                                            <svg-icon src="assets/images/t-shirt-svgrepo-com.svg"
                                                [svgStyle]="{ 'width.px':18,'height.px':18,'fill':'#5F1A37' }">
                                            </svg-icon> {{item.plr_name | removeTonation | uppercase | slice:0:1}}.
                                            {{item.plr_surname | removeTonation | uppercase}}
                                        </li>
                                    </ul>
                                </div>
                                <div class="col">
                                    <h3>ΑΜΥΝΤΙΚΟΙ</h3>
                                    <ul class="playerlist" *ngFor="let item of defenders">
                                        <li routerLink="/player/{{item.plr_slug}}">
                                            <svg-icon src="assets/images/t-shirt-svgrepo-com.svg"
                                                [svgStyle]="{ 'width.px':18,'height.px':18,'fill':'#5F1A37' }">
                                            </svg-icon> {{item.plr_name | removeTonation | uppercase | slice:0:1}}.
                                            {{item.plr_surname | removeTonation | uppercase}}
                                        </li>
                                    </ul>
                                </div>
                                <div class="col">
                                    <h3>ΜΕΣΟΙ</h3>
                                    <ul class="playerlist" *ngFor="let item of midfielders">
                                        <li routerLink="/player/{{item.plr_slug}}">
                                            <svg-icon src="assets/images/t-shirt-svgrepo-com.svg"
                                                [svgStyle]="{ 'width.px':18,'height.px':18,'fill':'#5F1A37' }">
                                            </svg-icon> {{item.plr_name | removeTonation | uppercase | slice:0:1}}.
                                            {{item.plr_surname | removeTonation | uppercase}}
                                        </li>
                                    </ul>
                                </div>
                                <div class="col">
                                    <h3>ΕΠΙΘΕΤΙΚΟΙ</h3>
                                    <ul class="playerlist" *ngFor="let item of forwards">
                                        <li routerLink="/player/{{item.plr_slug}}">
                                            <svg-icon src="assets/images/t-shirt-svgrepo-com.svg"
                                                [svgStyle]="{ 'width.px':18,'height.px':18,'fill':'#5F1A37' }">
                                            </svg-icon> {{item.plr_name | removeTonation | uppercase | slice:0:1}}.
                                            {{item.plr_surname | removeTonation | uppercase}}
                                        </li>
                                    </ul>
                                </div>
                                <div class="col">
                                    <h3>ΤΕΧΝΙΚΟ TEAM</h3>

                                    <ul class="playerlist" *ngFor="let item of staff">
                                        <li routerLink="/player/{{item.plr_slug}}">
                                            <svg-icon src="assets/images/t-shirt-svgrepo-com.svg"
                                                [svgStyle]="{ 'width.px':18,'height.px':18,'fill':'#d6dbd2' }">
                                            </svg-icon> {{item.plr_name | removeTonation | uppercase | slice:0:1}}.
                                            {{item.plr_surname | removeTonation | uppercase}}
                                        </li>
                                    </ul>

                                </div>
                            </div>
                        </div>
                    </div>
                </li>
                <li>
                    <div class="tileItem">
                        <a routerLink="/matches">ΑΓΩΝΕΣ</a>
                    </div>
                    <div class="childMenu container-fluid games">
                        <div class="container">
                            <div class="row">
                                <div class="col-md-1">
                                </div>
                                <div class="col">
                                    <ul class="catlist">
                                        <li><a routerLink="/matches/program/69">ΑΓΩΝΕΣ ΠΡΩΤΑΘΛΗΜΑΤΟΣ Α' ΕΠΣΚ</a></li>
                                        <li><a routerLink="/matches/leagueTable/69">ΒΑΘΜΟΛΟΓΙΑ Α' ΕΠΣΚ</a></li>
                                        <li><a routerLink="/matches/program/71">ΑΓΩΝΕΣ ΚΥΠΕΛΛΟΥ ΕΠΣΚ</a></li>
                                        <li><a routerLink="/matches/program/70">ΦΙΛΙΚΑ</a></li>
                                    </ul>
                                </div>
                                <div class="col">
                                    <h3>ΤΕΛΕΥΤΑΙΟΙ ΑΓΩΝΕΣ</h3>
                                    <div class="latestMatches" *ngFor="let item of prevMatches">
                                        <div class="match container" routerLink="match/{{item.mch_id}}/report">
                                            <div class="row">
                                                <div class="col matchdate">{{item.mch_date | date:'EEEE d/M/y'}} -
                                                    {{item.mch_time | slice:0:5}}</div>
                                            </div>
                                            <div class="row">
                                                <div class="col matchstadium">{{item.mch_venue}}</div>
                                            </div>
                                            <div class="row">
                                                <div class="col">{{item.HomeTeam.tem_name}}</div>
                                                <div class="col">{{item.mch_homescore}}</div>
                                            </div>
                                            <div class="row">
                                                <div class="col">{{item.AwayTeam.tem_name}}</div>
                                                <div class="col">{{item.mch_awayscore}}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="col">
                                    <h3>ΕΠΟΜΕΝΟΙ ΑΓΩΝΕΣ</h3>
                                    <div class="latestMatches" *ngFor="let item of nextMatches">
                                        <div class="match container" routerLink="match/{{item.mch_id}}/related">
                                            <div class="row">
                                                <div class="col matchdate">{{item.mch_date | date:'EEEE d/M/y'}} -
                                                    {{item.mch_time | slice:0:5}}</div>
                                            </div>
                                            <div class="row">
                                                <div class="col matchstadium">{{item.mch_venue}}</div>
                                            </div>
                                            <div class="row">
                                                <div class="col-md-1 logoMatch"><img
                                                        src="https://aokerkyra.com/{{item.HomeTeam.image.img_path}}">
                                                </div>
                                                <div class="col">{{item.HomeTeam.tem_name}}</div>
                                            </div>
                                            <div class="row">
                                                <div class="col-md-1 logoMatch"><img
                                                        src="https://aokerkyra.com/{{item.AwayTeam.image.img_path}}">
                                                </div>
                                                <div class="col">{{item.AwayTeam.tem_name}}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </li>
                <li>
                    <div class="tileItem">
                        <a routerLink="/tickets">ΜΕΛΗ</a>
                    </div>
                </li>
                <li>
                    <div class="tileItem">
                        <a routerLink="/fans">ΦΙΛΑΘΛΟΙ</a>
                    </div>
                </li>
                <li>
                    <div class="tileItem">
                        <a routerLink="/club">ΣΥΛΛΟΓΟΣ</a>
                    </div>
                </li>
                <li>
                    <div class="tileItem">
                        <a routerLink="/history">ΙΣΤΟΡΙΑ</a>
                    </div>
                </li>
                <li>
                    <div class="tileItem">
                        <a href="https://store.aokerkyra.com/" target="_blank">STORE</a>
                    </div>
                </li>
            </ul>
            <div class="menu--burger">

                <label>
                    <input id="checkbox" type="checkbox" [(ngModel)]="showMenu" name="burger-check">
                    <svg viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
                        <circle cx="50" cy="50" r="30"></circle>
                        <path class="line--1 line--debug" d="M0 40h62c13 0 6 28-4 18L35 35"></path>
                        <path class="line--1" d="M0 40h62c13 0 6 28-4 18L35 35"></path>
                        <path class="line--2 line--debug" d="M0 50h70"></path>
                        <path class="line--2" d="M0 50h70"></path>
                        <path class="line--3 line--debug" d="M0 60h62c13 0 6-28-4-18L35 65"></path>
                        <path class="line--3" d="M0 60h62c13 0 6-28-4-18L35 65"></path>
                    </svg>
                </label>
            </div>
        </div>

    </div>

</header>
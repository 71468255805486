import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Observable, of } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Match } from '../models/match';
import { MVPRow, Player } from '../models/player';

const apiUrl = environment.serverapi;

@Injectable({
  providedIn: 'root'
})
export class ApiPlayersService {

  constructor(private http: HttpClient, private router:ActivatedRoute) { }

  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead
      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }

  getCurrentPlayers(seasonid:number) {
    var url='';   
    url = `${apiUrl}players/${seasonid}/`;    
    return this.http.get<Player[]>(url);
  }

  getOldPlayers(seasonid:number) {
    var url='';   
    url = `${apiUrl}players/${seasonid}/old`;    
    return this.http.get<Player[]>(url);
  }

  getPlayer(playerSlug:string) {
    var url='';   
    url = `${apiUrl}player/${playerSlug}/`;    
    return this.http.get<Player>(url);
  }
  getPlayerMatches(playerSlug:string) {
    var url='';   
    url = `${apiUrl}playerMatches/${playerSlug}/`;    
    return this.http.get<Player>(url);
  }

  getMVPOfMatch(matchid:number){
    var url='';   
    url = `${apiUrl}gamedetails/mvp/${matchid}/`;    
    return this.http.get<MVPRow[]>(url);
  }

  canUserVote(matchid:number,userid){
    var url='';   
    url = `${apiUrl}canvotemvp/${matchid}/${userid}`;    
    return this.http.get<boolean>(url);
  }

  addUserVote(addedUser,token) {
    var url='';   
    url = `${apiUrl}mvp/add/`;    
    return this.http.post(url,addedUser,{headers:{authorization: `Bearer ${token}`}});
  }
  
  
  getPlayersOfMatch(matchid:number){
    var url='';   
    url = `${apiUrl}gamedetails/players/${matchid}/`;    
    return this.http.get<Match>(url);
  }
}

import { animate, state, style, transition, trigger,query,animateChild, group } from '@angular/animations';
import { Component, OnInit } from '@angular/core';
import {isMobile} from 'detect-touch-device';
import { ApiNewsService } from 'src/app/shared/services/api-news.service';
import { SliderNews } from 'src/app/shared/models/slidernews';
import { Images } from 'src/app/shared/models/images';

@Component({
  selector: 'app-slider',
  templateUrl: './slider.component.html',
  styleUrls: ['./slider.component.scss'],
  animations: [
    trigger('openClose', [     
      state('open', style({        
        transform: 'translateX(0)',  
        opacity:1   
      })),
      state('closed', style({
        transform: 'translateX(100%)',  
        opacity:0         
      })),
      transition('* <=> *', [
        group([
          query('@*',animateChild()),
         animate('0.5s')       
       ])
      ]),
    ]),
    trigger('updown',[
      state('open',style({
        transform: 'translateY(0)',  
        opacity:1   
      })),
      state('closed',style({
        transform: 'translateY(100%)',  
        opacity:0  
      })),
      transition('* <=> *', [
        animate('.5s .3s')
      ]),
     
    ])
  ],
  
})
export class SliderComponent implements OnInit {

  constructor(private apiNews:ApiNewsService) { }
  curSlide=0;
  timeLeft: number = 6;
  interval;
  isMobile=isMobile;
  slides:SliderNews[];

  ngOnInit(): void {    
    let ghostSlide=new SliderNews();
    let ghostImage=new Images();
    ghostImage.img_path="images/unnamed.png";
    ghostSlide.Landscape=ghostImage;
    ghostSlide.Portrait=ghostImage;
    ghostSlide.sli_title="ghost";
    this.slides=[ghostSlide];
    this.apiNews.getSlider().subscribe(sln=>{
      this.slides=sln;
      if(sln.length>1){
       this.startTimer();
      }
    })
  }

  startTimer(){
    this.timeLeft=5;
    this.interval=setInterval(()=>{
     this.timeLeft--;
      if(this.timeLeft==0)
        this.processNextSlide();
    },1000)
  }
  setSlide(slide){
    clearInterval(this.interval);
    this.curSlide=slide;
    this.startTimer();
  }
  processNextSlide(){
    clearInterval(this.interval);
    this.curSlide=(this.curSlide+1)%this.slides.length;
    this.startTimer();
  }
  processPrevSlide(){
    clearInterval(this.interval);
    this.curSlide=(this.curSlide+this.slides.length-1)%this.slides.length;
    this.startTimer();
  }
  compareSlide(slide):boolean{
    if(this.curSlide===slide){
      return true;
    }
    return false;
  }

  swipeNext(event){
    //alert('sliding');
    this.processNextSlide();
  }
  swipePrev(event){
    //alert('sliding');
    this.processPrevSlide();
  }
}

import { _ParseAST } from '@angular/compiler';
import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { Match, Season } from 'src/app/shared/models/match';
import { News } from 'src/app/shared/models/news';
import { Player } from 'src/app/shared/models/player';
import { ApiPlayersService } from 'src/app/shared/services/api-players.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-player-profile-page',
  templateUrl: './player-profile-page.component.html',
  styleUrls: ['./player-profile-page.component.scss']
})
export class PlayerProfilePageComponent implements OnInit {

  constructor(private playerApi:ApiPlayersService, private router:ActivatedRoute,private meta:Meta, private titleService: Title) { }
  name:string;
  surname:string;
  photo:string;
  birthdate:Date;
  position:string;
  history:string;
  curPlayer:Player;
  matches:Match[];
  visibleMatches:Match[];
  seasons:Season[];
  visibleSeason:Season;
  news:News[];
  ngOnInit(): void {
    this.router.params.subscribe(params=>{      
      const playerSlug=params.slug;
      this.playerApi.getPlayer(playerSlug).subscribe(player=>{
        this.curPlayer=player;
        this.name=this.curPlayer.plr_name;
        this.surname=this.curPlayer.plr_surname;
        this.birthdate=this.curPlayer.plr_birthdate;
        this.history=this.curPlayer.plr_history;
        this.position=this.curPlayer.positions.filter(_pos=>(_pos.playerpositions.plp_order==1))[0].pos_name;
        if(this.curPlayer.LargeImage!=null){
          this.photo=environment.imagePath+this.curPlayer.LargeImage.img_path;
        }
        else if(this.curPlayer.SmallImage!=null){
          this.photo=environment.imagePath+this.curPlayer.SmallImage.img_path;
        }else {
          this.photo=environment.imagePath+'images/silhuette.png';
        }
        this.news=this.curPlayer.news;
        this.titleService.setTitle(this.name+' '+this.surname+' - Α.Ο. Κέρκυρα');
        this.meta.addTags([
          { name: 'keywords', content: '' },
          { name: 'robots', content: 'index, follow' },
          {name:'description',content:this.history},
          {property:'title', content:this.name+' '+this.surname+' - Α.Ο. Κέρκυρα'},
          {property:'og:title',content:this.name+' '+this.surname+' - Α.Ο. Κέρκυρα'},
          {property:'og:image',content:this.photo}
        ]);
      });
      this.playerApi.getPlayerMatches(playerSlug).subscribe(_playerwithMatches=>{
        this.matches=_playerwithMatches.matches;
        this.seasons=_playerwithMatches.seasons.sort((a,b)=>(a.ses_startdate>b.ses_startdate)?1:-1);
        let curSeason=(this.seasons.find(_ses=>_ses.ses_current));
        if(curSeason!=null){          
          this.visibleSeason=curSeason;
          this.visibleMatches=this.matches.filter(_mct=>_mct.league.lea_season==curSeason.ses_id);
        }else{
          this.visibleSeason=this.seasons[0];
          this.visibleMatches=this.matches.filter(_mctch=>(_mctch.league.season!=null && _mctch.league.season.ses_id==this.visibleSeason.ses_id));
        }
        
       
        
       
      });
    })
  }

  numSequence(n: number): Array<number> { 
    return Array(n); 
  } 

  filterSeasonData(sesid:number){
    //console.log(this.matches);
    this.visibleSeason=this.seasons.find(_ses=>_ses.ses_id==sesid);
    this.visibleMatches=this.matches.filter(_mct=>_mct.league.lea_season==sesid);
  }

}


import { Component, OnInit } from '@angular/core';
import { FanmapService } from 'src/app/shared/services/fanmap.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-map-thanks',
  templateUrl: './map-thanks.component.html',
  styleUrls: ['./map-thanks.component.scss']
})
export class MapThanksComponent implements OnInit {

  constructor(private fansMapApi:FanmapService) { }
  environment=environment;
  points=[];
  lat = 36.686365;
  lng = -24.3856037;
  ngOnInit(): void {
    this.fansMapApi.getMapPoints().subscribe(_res=>{
      //console.log(_res);
      this.points=_res.map(_it=>{return {lat:JSON.parse(_it.fmp_coords).coords.lat, lng:JSON.parse(_it.fmp_coords).coords.lng, label:_it.fmp_name}});
    })
  }

}

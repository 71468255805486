<div class="container newsletter" *ngIf="!thank">
    Για να λαμβάνετε ενημερώσεις σχετικά με τον Α.Ο. Κέρκυρα, συμπληρώστε το e-mail σας παρακάτω:
   <form [formGroup]="form" (ngSubmit)="onSubmit()">
       <div class="row align-items-center">
           <div class="col"><input type="email" formControlName="email" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.email.errors }" />
            <div *ngIf="submitted && f.email.errors" class="invalid-feedback">     
                    
                <div *ngIf="f.email.errors.required">Tο e-mail είναι απαραίτητο</div>
                <div *ngIf="f.email.errors.pattern">Tο e-mail δεν είναι έγκυρο</div>
            </div></div>
           <div class="col"><button [disabled]="loading" class="swipeButton">
            <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
            <span>ΕΓΓΡΑΦΗ</span>
            
        </button>
         </div>
       </div>   
       <div class="row">
           <div class="col"><p *ngFor="let alert of alerts" class="pt-2">
            <ngb-alert [type]="alert.type" (close)="close(alert)">{{ alert.message }}</ngb-alert>
        </p>    </div>
       </div>
   </form>
</div>
<div class="container newsletter text-center" *ngIf="thank">
    <h4>Ευχαριστούμε! ❤️💙</h4>
</div>
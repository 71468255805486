
<div class="singleNewsPreview"   [ngClass]="{'touch':isMobile,'ghost':(headerTitle=='ghost')}">
    <div class="imageMask"><div class="imageHolder" routerLink="/article/{{link}}" [style.background-image]="headerImageUrl"></div></div>
    <div class="text-holder">
        <h4>{{category}}</h4>
        <h3 routerLink="/article/{{link}}" [innerHtml]="headerTitle"></h3>
    </div>
    <div class="snp-footer">
        <div class="before"><svg-icon src="assets/images/clock-svgrepo-com.svg" [svgStyle]="{'width.px':14,'height.px':14,'fill':'#5f1a37ff'}" ></svg-icon><span>Πριν απο {{before}}</span></div>
        <div><svg-icon src="assets/images/share-button-svgrepo-com.svg" [svgStyle]="{'width.px':20,'height.px':20,'fill':'#5f1a37ff'}" (click)="showShareDialogVar=true"></svg-icon></div>
    </div>
    <div class="socialShare" [ngClass]="{'active':showShareDialogVar}"><share-button [theme]="'circles-dark'"
        [button]="'facebook'"
        
        [url]="externalLink"
        [autoSetMeta]="false"
       ></share-button>
       <share-button [theme]="'circles-dark'"
       [button]="'twitter'"
      
       [url]="externalLink"
       [autoSetMeta]="false"
      ></share-button>
      <share-button [theme]="'circles-dark'"
      [button]="'messenger'"
      
      [url]="externalLink"
      [autoSetMeta]="false"
     ></share-button>
     <share-button [theme]="'circles-dark'"
     [button]="'email'"
    
     [url]="externalLink"
     [autoSetMeta]="false"
    ></share-button>
    <share-button [theme]="'circles-dark'"
    [button]="'copy'"
    
    [url]="externalLink"
    [autoSetMeta]="false"
   ></share-button>
    <svg-icon src="assets/images/iconmonstr-x-mark-thin.svg" [svgStyle]="{'width.px':24,'height.px':24,'fill':'#e5446dff'}" (click)="showShareDialogVar=false"></svg-icon></div>
</div>
import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { Player } from 'src/app/shared/models/player';
import { ApiMatchesServiceService } from 'src/app/shared/services/api-matches-service.service';
import { ApiNewsService } from 'src/app/shared/services/api-news.service';
import { ApiPlayersService } from 'src/app/shared/services/api-players.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-player-list',
  templateUrl: './player-list.component.html',
  styleUrls: ['./player-list.component.scss']
})
export class PlayerListComponent implements OnInit {
  goalies:Player[];
  defenders:Player[];
  midfielders:Player[];
  forwards:Player[];
  staff:Player[];
  environment=environment;
  constructor(private router:Router, private newsApi:ApiNewsService, private matchApi:ApiMatchesServiceService, private playerApi:ApiPlayersService,private meta:Meta, private titleService: Title) { }

  ngOnInit(): void {
    this.playerApi.getCurrentPlayers(environment.currentSeason).subscribe(_res=>{
      this.goalies=_res.filter(_player=>_player.positions.filter(_pos=>_pos.pos_name=="Τερματοφύλακας").length);
      this.defenders=_res.filter(_player=>_player.positions.filter(_pos=>_pos.pos_name=="Αμυντικός").length);
      this.midfielders=_res.filter(_player=>_player.positions.filter(_pos=>_pos.pos_name=="Μέσος").length);
      this.forwards=_res.filter(_player=>_player.positions.filter(_pos=>_pos.pos_name=="Επιθετικός").length);
      this.staff=_res.filter(_player=>_player.positions.filter(_pos=>_pos.pos_name=="Τεχνικός").length).sort((a,b)=>(a.positions[0].pos_name=="Προπονητής")?-1:1);
      //console.log(this.goalies);
    })
    this.titleService.setTitle('Ομάδα - Α.Ο. Κέρκυρα');
        this.meta.addTags([
          { name: 'keywords', content: '' },
          { name: 'robots', content: 'index, follow' },
          {name:'description',content:'Το τρέχον ρόστερ της ομάδας μας'},
          {property:'title', content:'Ομάδα - Α.Ο. Κέρκυρα'},
          {property:'og:title',content:'Ομάδα - Α.Ο. Κέρκυρα'},
          {property:'og:image',content:'https://www.aokerkyra.com/images/ao_kerkyra_1968_400x400.png'}
        ]);
     
  }

}

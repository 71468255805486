<div class="container section mt-5">
    <div class="row  mb-1"><div class="col p-0"><div class="topbar"><h2>ΧΑΡΤΗΣ ΦΙΛΑΘΛΩΝ</h2></div></div></div>
    <div class="row mt-1 no-gutters">
        <div class="col-lg-9 col-sm-12 map-container">
            <agm-map [latitude]="lat" [longitude]="lng" [streetViewControl]="false" [zoom]="2" class="map" [gestureHandling]="'cooperative'" [styles]="['mapokerkyra']">
                <agm-marker [latitude]="marker.lat" [longitude]="marker.lng" [title]="marker.label" [openInfoWindow]="true" [iconUrl]="'/images/mappin_aok.png'" *ngFor="let marker of points"></agm-marker>                
              </agm-map>
        </div>
        <div class="col-lg-3 col-sm-12 what-is p-2">
            <h3>ΕΥΧΑΡΙΣΤΟΥΜΕ</h3>
            για την εγγραφή σας στο "Χάρτη Φιλάθλων Α.Ο. Κέρκυρα".
            <br/><br/>
            H συμμετοχή σας θα επιβεβαιωθεί και σύντομα θα δείτε το δικό σας στίγμα στον χάρτη μας!
            
        </div>
    </div>
</div>
import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router, CanActivateChild, ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(private authService: AuthService,
    private router: Router, private activatedRoute:ActivatedRoute) { } 
 

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      //console.log(localStorage);
    if(this.authService.isLoggedIn){
      return true;
    }else{      
      //let parent=route.parent.url.map((urls)=>urls.path).reduce((acc,nxt)=>acc.concat(nxt+'/'));
      //this.router.navigate(['../../auth'],{relativeTo:route});    
      
      let cur=route.routeConfig.path;
      this.authService.backPath=state.url;
      console.log('backpath='+this.authService.backPath);
      let parentUrl=state.url.replace(cur,'');
      return this.router.navigate([parentUrl+'/auth']);
    }
  }
  
  
}

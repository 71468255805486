import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ImageCategory } from 'src/app/shared/models/images';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-photostories',
  templateUrl: './photostories.component.html',
  styleUrls: ['./photostories.component.scss']
})
export class PhotostoriesComponent implements OnInit {
  @Input()
  imcObj:ImageCategory;
  constructor(private sanitizer:DomSanitizer) { }
  title:string;
  preview;
  url:string;
  ngOnInit(): void {
    if(this.imcObj!=null){
      this.title=this.imcObj.imc_title;
      this.url="/photostory/"+this.imcObj.imc_id;
      
      if(this.imcObj.images!=null && this.imcObj.images.length){
        let previewTemp=environment.imagePath+this.imcObj.images[0].img_path.replace('originals','thumbs');
        this.preview=this.sanitizer.bypassSecurityTrustResourceUrl("url('"+previewTemp+"')");
      }else{
        this.preview="_blank";
      }

      //console.log(this.preview);
    }
  }

}

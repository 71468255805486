import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ArticleListComponent } from './articles/article-list/article-list.component';
import { ArticleComponent } from './articles/article/article.component';
import { ClubPageComponent } from './club/club-page/club-page.component';
import { ContactPageComponent } from './contact-page/contact-page.component';
import { ConfirmComponent } from './fans/confirm/confirm.component';
import { LoginComponent } from './fans/Email/login/login.component';
import { RegisterComponent } from './fans/Email/register/register.component';
import { FansPageComponent } from './fans/fans-page/fans-page.component';
import { ForgotComponent } from './fans/forgot/forgot.component';
import { MapLoginComponent } from './fans/map-login/map-login.component';
import { MapRegisterComponent } from './fans/map-register/map-register.component';
import { MapThanksComponent } from './fans/map-thanks/map-thanks.component';
import { MapViewComponent } from './fans/map-view/map-view.component';
import { TicketsMembersPageComponent } from './fans/tickets-members-page/tickets-members-page.component';
import { FullpageComponent } from './games/fullpage/fullpage.component';
import { GamesCenterComponent } from './games/games-center/games-center.component';
import { ListgamesComponent } from './games/listgames/listgames.component';
import { MaxiLeagueComponent } from './games/maxi-league/maxi-league.component';

import { MvpThanksComponent } from './games/mvp/mvp-thanks/mvp-thanks.component';
import { MvpVoteScreenComponent } from './games/mvp/mvp-vote-screen/mvp-vote-screen.component';
import { MvpVoteComponent } from './games/mvp/mvp-vote/mvp-vote.component';
import { MvpComponent } from './games/mvp/mvp.component';
import { StatsComponent } from './games/stats/stats.component';
import { HistoryPageComponent } from './history/history-page/history-page.component';
import { LeagueTableComponent } from './history/league-table/league-table.component';
import { SeasonMatchesComponent } from './history/season-matches/season-matches.component';
import { SeasonPageComponent } from './history/season-page/season-page.component';
import { SeasonRosterComponent } from './history/season-roster/season-roster.component';
import { PageComponent } from './homepage/page/page.component';
import { MembersFormComponent } from './members-form/members-form.component';
import { Notfound404Component } from './notfound404/notfound404.component';
import { PhotostoryPageComponent } from './photostories/photostory-page/photostory-page.component';
import { PlayerListComponent } from './players/player-list/player-list.component';
import { PlayerProfilePageComponent } from './players/player-profile-page/player-profile-page.component';
import { AuthMvpGuard } from './shared/guards/auth-mvp.guard';
import { AuthGuard } from './shared/guards/auth.guard';


const routes: Routes = [
  { path: '', component: PageComponent },
  {path:'article',  
  loadChildren: () => import('./articles/article.module')
                       .then(m => m.ArticleModule),},      
  {path:'match/:id',component:FullpageComponent,
    children: [
      {path: '', redirectTo: 'report',pathMatch:'full'}, 
      {path: 'report', component: ArticleComponent,data:{type:'matchPage'}},
      {path: 'related', component: ArticleListComponent,data:{type:'matchPage'}},
      {path: 'photostory',component:PhotostoryPageComponent,data:{type:'matchPage'}},
      {path: 'mvp',component:MvpComponent,children:[
        {path:'',redirectTo:'vote',pathMatch:'full'},
        {path:'auth',  
      loadChildren: () => import('./modules/authentication/authentication.module')
                           .then(m => m.AuthenticationModule),},      
        {path:'vote',component: MvpVoteScreenComponent,canActivate:[AuthGuard]},
        {path:'thanks',component: MvpThanksComponent,canActivate:[AuthGuard]},        
      ]}
    ]},  
  {path:'matches',component:GamesCenterComponent,
    children:[
      {path: '', redirectTo: 'monthly',pathMatch:'full'}, 
      {path:'monthly',component:ListgamesComponent,data:{type:'upcoming'}},
      {path:'program/:league',component:ListgamesComponent,data:{type:'league'}},
      {path:'leagueTable/:league', component:MaxiLeagueComponent},
      {path:'stats/:season', component:StatsComponent},
    ]
  }, 
 

  {path:'players', component:PlayerListComponent},
  {path:'player/:slug', component:PlayerProfilePageComponent},
  {path:'fans', component:FansPageComponent,
    children:[
      {path:'',component:MapViewComponent,},      
      {path:'addPoint',component: MapRegisterComponent,canActivate:[AuthGuard]},
      {path:'thanks',component: MapThanksComponent,canActivateChild:[AuthGuard]}, 
      {path:'auth',  
      loadChildren: () => import('./modules/authentication/authentication.module')
                           .then(m => m.AuthenticationModule),},    
    ]
  },
  {path:'tickets', component:TicketsMembersPageComponent},
  {path:'club', component:ClubPageComponent},
  {path:'history', component:HistoryPageComponent},
  {path:'season/:id', component:SeasonPageComponent,
  children:[
    {path:'',redirectTo:'matches',pathMatch:'full'},
    {path:'matches',component:SeasonMatchesComponent},
    {path:'roster', component:SeasonRosterComponent},
    {path:'table/:id', component:LeagueTableComponent},
  ]},
  {path:'photostory/:id',component:PhotostoryPageComponent,data:{type:'standalone'}},
  {path:'contact',component:ContactPageComponent},
  {path:'members/register',component:MembersFormComponent},
  {path:'404',component:Notfound404Component},
  //{path:'**',redirectTo: '/404'}
];
//const noTopRoutes=routes.filter(_rt=>_rt.path=='fans')[0].children;

@NgModule({
  imports: [RouterModule.forRoot(routes,{onSameUrlNavigation: 'reload'})],
  exports: [RouterModule]
})
export class AppRoutingModule { }

import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AnalyticsService } from 'src/app/shared/services/analytics.service';
import { AuthService } from 'src/app/shared/services/auth.service';
import { ToastService } from 'src/app/shared/services/toast-service.service';

@Component({
  selector: 'app-mail-login',
  templateUrl: './mail-login.component.html',
  styleUrls: ['./mail-login.component.scss']
})
export class MailLoginComponent implements OnInit {

  form: FormGroup;
  loading = false;
  submitted = false;
  alerts=[];
  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private toastApi:ToastService,
    private authApi:AuthService,
    private analyticsService:AnalyticsService
  ) { }

  get f() { return this.form.controls; }
    backroute=this.authApi.backPath;
   
  ngOnInit(): void {
    this.form = this.formBuilder.group({
      email: ['', [Validators.required,Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$")]],
      password: ['', [Validators.required,]]      
  }
  
  );
   
   console.log(this.backroute)
  }

  close(alert) {    
    this.alerts.splice(this.alerts.indexOf(alert), 1);
  }

 

  onSubmit(){
    //console.log(this.form.value);
    this.loading=true;
    this.submitted = true;    
    if(this.form.invalid){
      this.loading=false;
      return;
    }
    this.authApi.mailLogin(this.form.value.email,this.form.value.password,this.backroute).    
    catch(error=>{
      this.loading=false;     
      this.analyticsService.trackEvent('Fans map','Login','e-mail');
     if(error.code=='auth/user-not-found')      
      this.alerts.push({type:'danger',message:'Δεν υπάρχει χρήστης με αυτά τα στοιχεία'})
    if(error.code=='auth/wrong-password')
      this.alerts.push({type:'danger',message:'Τα στοιχεία που πληκτρολογήσατε δεν είναι σωστά!'})
    })
   
  }

}

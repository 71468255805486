<div class="container mt-md-5">
    <div class="row">
        <div class="col">
            <div ngbDropdown class="d-inline-block" *ngIf="newsList">
                <button class="btn btn-outline-primary" id="dropdownBasic1" ngbDropdownToggle>{{ddLabel}}</button>
                <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
                  <button ngbDropdownItem routerLink="/article/category/all">Όλα</button>
                  <button ngbDropdownItem routerLink="/article/category/nea-ths-proponhshs">Νέα προπόνησης</button>
                  <button ngbDropdownItem routerLink="/article/category/dioikhtika-nea">Διοικητικά Νέα</button>
                  <button ngbDropdownItem routerLink="/article/category/metagrafes-apodesmeyseis">Μεταγραφές - Αποδεσμεύσεις</button>
                  <button ngbDropdownItem routerLink="/article/category/synenteykseis">Συνεντεύξεις</button>
                </div>
        </div>
    </div>
</div>
    <div class="row" >
        <div class="col-lg-3 col-md-4" *ngFor="let item of rows">            
            <app-single-new-preview  [newsObj]="item">                
            </app-single-new-preview>
        </div>       
    </div>
    <div class="row" *ngIf="rows==null">
        <div class="col text-center">Δεν βρέθηκαν άρθρα!</div>
    </div>
    <div class="row mt-5" *ngIf="morePage && rows!=null"><div class="col text-center"><button class="swipeButton"  (click)="getMoreData()"><span>ΠΕΡΙΣΣΟΤΕΡΑ</span></button></div></div>
</div>
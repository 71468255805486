<div class="container mt-5">
    <div class="row mb-5">
        <div class="col text-center"><button class="swipeButton" *ngIf="type=='upcoming'"
                (click)="getPrevMonth()"><span>ΠΡΟΗΓΟΥΜΕΝΑ</span></button></div>
    </div>
    <div *ngFor="let monthitem of months">
        <div class="row">
            <div class="col month">
                <h3>{{monthitem.monthName | removeTonation | uppercase}}</h3>
            </div>
        </div>
        <div *ngFor="let item of monthitem.matches">
            <app-gamerow [item]="item"></app-gamerow>
        </div>
        <div *ngIf="monthitem.matches.length==0">
            <span class="badge badge-secondary">Δεν βρέθηκαν αγώνες για αυτόν τον μήνα.</span>
        </div>
    </div>

    <div class="row mt-5">
        <div class="col text-center"><button class="swipeButton" *ngIf="type=='upcoming'"
                (click)="getNextMonth()"><span>ΕΠΟΜΕΝΑ</span></button></div>
    </div>
</div>
import { TemplateRef } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { SwPush } from '@angular/service-worker';
import { Sponsor } from 'src/app/shared/models/sponsor';
import { CookieService } from 'src/app/shared/services/cookie.service';
import { NotificationSubscribeServiceService } from 'src/app/shared/services/notification-subscribe-service.service';
import { SponsorsService } from 'src/app/shared/services/sponsors.service';
import { ToastService } from 'src/app/shared/services/toast-service.service';
import { environment } from 'src/environments/environment';


@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})

export class FooterComponent implements OnInit {
  readonly VAPID_PUBLIC_KEY = "BLwHXMXHDEUkR2JSb0isfwHMOFGaKq92sh3vUMUBsD2SjoY2ApN722m87sx4jXFZYggmwzFuEcgvm0XsCmbU6ZE";
  constructor(public toastApi:ToastService, public cookieService:CookieService, private swPush: SwPush , private notifService:NotificationSubscribeServiceService, private sponsorsService:SponsorsService   ) { }
  showCookiePop:boolean=false;
  forcedCookiePop:boolean=false;
  showNotifs:boolean=false;
  tier1Sponsors:Sponsor[];
  tier2Sponsors:Sponsor[];
  tier3Sponsors:Sponsor[];
  environment=environment;
  cookiePack;
  ngOnInit(): void {
    this.forcedCookiePop=this.cookieService.forcePopup;
    this.cookieService.cookieStatus().subscribe(_show=>{
      this.cookiePack=_show;
      this.showCookiePop=!_show.cookiesAccepted;
    })
    this.swPush.subscription.subscribe(_sub=>{
      if(_sub==null){
        this.showNotifs=true;
      }
    })
    this.sponsorsService.getSponsors('first').subscribe((t1)=>{
      
      this.tier1Sponsors=t1;
    })
    this.sponsorsService.getSponsors('second').subscribe((t2)=>{
      this.tier2Sponsors=t2;
    })
    this.sponsorsService.getSponsors('supporter').subscribe((t3)=>{
      this.tier3Sponsors=t3;
    })

  }

  isTemplate(toast) { return toast.textOrTpl instanceof TemplateRef; }

  subscribeToNotifications(){   
    this.swPush.requestSubscription({
        serverPublicKey: this.VAPID_PUBLIC_KEY
    })
    .then((sub) => {
      //console.log(sub);
      this.showNotifs=false;
      this.notifService.addSubscriber({obj:sub}).subscribe(_res=>console.log(_res),_err=>console.log(_err))
    }
      )
    .catch(err => console.error("Could not subscribe to notifications", err));
}

}

<div class="container mt-sm-5">
    <div class="row topbar mb-1"><h2 class="col">Photostory: {{title}}</h2></div>
    <div class="row">
        <div class="col p-0">
    <ngx-masonry  style="width:100%;margin:0;" class="row" [ordered]="true" [options]="{columnWidth:'.col-lg-3',percentPosition: true}" >
        <div ngxMasonryItem class="masonry-item col-lg-3 col-sm-4 p-1 m-0" *ngFor="let item of myImages;let i=index" (click)="open(i)">
            <img src="{{item.preview}}" width="100%"  />
       </div>
      </ngx-masonry>   
</div>
</div>
</div>
import { trigger, transition, style, animate } from '@angular/animations';
import { Component, Input, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { Match } from 'src/app/shared/models/match';
import { ApiMatchesServiceService } from 'src/app/shared/services/api-matches-service.service';

@Component({
  selector: 'app-gamerow',
  templateUrl: './gamerow.component.html',
  styleUrls: ['./gamerow.component.scss'],
  animations: [
    trigger('fade', [      
      transition('void => *', [
        style({opacity: 0}),
        animate(1000, style({opacity: 1}))
      ]),
      transition('* => void', [
        animate(1000, style({opacity: 0}))
      ])
    ])

]
})
export class GamerowComponent implements OnInit {
  @Input('item')
  item:Match;

  hasMvpVotes$:Observable<any>;

  constructor(private matchService:ApiMatchesServiceService) { }

  ngOnInit(): void {
    this.hasMvpVotes$=this.matchService.getMatchHasMvp(this.item.mch_id);
  }

}

import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import {isMobile} from 'detect-touch-device';
import { News } from 'src/app/shared/models/news';
import { environment } from 'src/environments/environment';
import {Utils} from 'src/app/shared/utils';

@Component({
  selector: 'app-single-new-preview',
  templateUrl: './single-new-preview.component.html',
  styleUrls: ['./single-new-preview.component.scss']
})
export class SingleNewPreviewComponent implements OnInit {
  headerImageUrl;
  @Input()
  newsObj:News;
  headerTitle:string;  
  headerImage:string;
  before:string;
  category:string;
  link:string;
  externalLink:string;
  constructor(private sanitizer:DomSanitizer) { }
  showShareDialogVar=false;
  isMobile=isMobile;
  ngOnInit(): void {
    //console.log(this.newsObj);
    if(this.newsObj.images!=null && this.newsObj.images.length){
      this.headerImage=environment.imagePath+this.newsObj.images.filter(i=>i.newsimages.nwi_isthumb!="1")[0].img_path;
      this.headerImageUrl=this.sanitizer.bypassSecurityTrustResourceUrl("url('"+this.headerImage+"')");
      //console.log(environment.imagePath+this.newsObj.images.filter(i=>i.newsimages.nwi_isthumb!="1")[0]);      
    }else{
      this.headerImage='/images/unnamed.webp';
      this.headerImageUrl="url('"+this.headerImage+"')";
    }
    if(this.newsObj.new_date){
      this.before=Utils.timeSince(new Date(this.newsObj.new_date));
    }
    this.headerTitle=decodeURIComponent(this.newsObj.new_title);
    if(this.newsObj.newscategories && this.newsObj.newscategories.length)
     this.category=this.newsObj.newscategories[0].nwc_title;
   
    this.link=this.newsObj.new_slug;
    this.externalLink=environment.rootUrl+'article/'+this.link;
  }



}

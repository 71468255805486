import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Season } from 'src/app/shared/models/match';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-season-item',
  templateUrl: './season-item.component.html',
  styleUrls: ['./season-item.component.scss'],
  
})
export class SeasonItemComponent implements OnInit {
  @Input()
  seasonObject:Season;
  constructor(private sanitizer:DomSanitizer) { }
  title:string;
  url:string;
  preview;
  ngOnInit(): void {
    if(this.seasonObject!=null){
      this.title=this.seasonObject.ses_title;
      this.url="/season/"+this.seasonObject.ses_id;
      
      if(this.seasonObject.image!=null){     
        let previewTemp=environment.imagePath+this.seasonObject.image.img_path;   
        this.preview=this.sanitizer.bypassSecurityTrustResourceUrl("url('"+previewTemp+"')");
      }else{
        this.preview=this.sanitizer.bypassSecurityTrustResourceUrl("url('"+environment.imagePath+'images/unnamed.jpg'+"')");
      }

      //console.log(this.preview);
    }
  }

}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { throwError } from 'rxjs';
import { environment } from 'src/environments/environment';
import { FansmapPoint } from '../models/fansmap-point';


const apiUrl = environment.serverapi;

@Injectable({
  providedIn: 'root'
})


export class FanmapService {
  constructor(private http: HttpClient, private router:ActivatedRoute) { }
  handleError(error) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // client-side error
      errorMessage = `Error: ${error.error.message}`;
    } else {
      // server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    //window.alert(errorMessage);
    return throwError(errorMessage);
  }


  addMapUser(addedUser,token) {
    var url='';   
    url = `${apiUrl}fansmap/add/`;    
    return this.http.post(url,addedUser,{headers:{authorization: `Bearer ${token}`}});
  }

  

  getMapUser(uid){
    var url='';   
    url = `${apiUrl}fansmap/${uid}/`;  
    return this.http.get<FansmapPoint>(url);
  }

  getMapPoints(){
    var url='';   
    url = `${apiUrl}fansmap/`;  
    return this.http.get<FansmapPoint[]>(url);
  }
}

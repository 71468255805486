import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Meta, Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { NgRecaptcha3Service } from 'ng-recaptcha3';
import { AnalyticsService } from '../shared/services/analytics.service';
import { AuthService } from '../shared/services/auth.service';
import { ContactService } from '../shared/services/contact.service';
import { MailchimpService } from '../shared/services/mailchimp.service';
import { ToastService } from '../shared/services/toast-service.service';

@Component({
  selector: 'app-members-form',
  templateUrl: './members-form.component.html',
  styleUrls: ['./members-form.component.scss']
})
export class MembersFormComponent implements OnInit {

  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private toastApi: ToastService,
    private authApi: AuthService,
    private contactService: ContactService,
    private rcp: NgRecaptcha3Service,
    private mc: MailchimpService,
    private meta:Meta, 
    private titleService: Title,
    private analyticsService:AnalyticsService
  ) { }
  notsubmitted = true;
  form: FormGroup;
  loading = false;
  submitted = false;
  alerts = [];
  ngOnInit(): void {
    this.rcp.init('6Lf1ceUUAAAAAEAjwaDJXUApGRNKdO5PWJ4tWXoH').then(status => { console.log(status) });
    this.form = this.formBuilder.group({
      email: ['', [Validators.required, Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$")]],
      name: ['', [Validators.required,]],
      surname: ['', [Validators.required,]],
      fathername: ['', [Validators.required,]],
      mothername: ['', [Validators.required,]],
      birthyear: ['', [Validators.required,]],
      birthplace: ['', []],
      profession: ['', []],
      street: ['', [Validators.required,]],
      streetNo: ['', [Validators.required,]],
      zip: ['', [Validators.required,]],
      area: ['', [Validators.required,]],
      city: ['', [Validators.required,]],
      tel: ['', [Validators.required,]],
      tel2: ['', []],
      tel3: ['', []],
      acceptTerms: [false, [Validators.requiredTrue]],
      acceptTerms2: [false, [Validators.requiredTrue]],
      acceptNewsletter: [false]
    });
    this.titleService.setTitle('Φόρμα εγγραφής μελών - Α.Ο. Κέρκυρα');
    this.meta.addTags([
      { name: 'keywords', content: 'φόρμα εγγραφής μελών' },
      { name: 'robots', content: 'index, follow' },
      {name:'description',content:'Φόρμα εγγραφής μελών'},
      {property:'title', content:'Φόρμα εγγραφής μελών - Α.Ο. Κέρκυρα'},
      {property:'og:title',content:'Φόρμα εγγραφής μελών - Α.Ο. Κέρκυρα'},
      {property:'og:image',content:'https://www.aokerkyra.com/images/ao_kerkyra_1968_400x400.png'}
    ]);  
  }

  get f() { return this.form.controls; }


  onSubmit() {
    this.loading = true;
    this.submitted = true;
    if (this.form.invalid) {
      //console.log(this.form.value);
      this.loading = false;
      return;
    }
    this.rcp.getToken().then(token => {
      const formData = this.form.value;
      formData.recaptchaToken = token;
      //console.log(formData);
      this.contactService.sendMembersForm(formData).subscribe(_res => {
        this.loading = false;
        this.notsubmitted = false;
        this.analyticsService.trackEvent('Member form','Sent','');
        if (this.form.controls.acceptNewsletter.value) {
          this.mc.subscribe({ email: this.form.controls.email.value }).subscribe(_res => {
            this.analyticsService.trackEvent('Newsletter','Newsletter subscription','Members registration');
            this.toastApi.show('Εγγραφήκατε επιτυχώς στο Newsletter του A.O. Kέρκυρα',{class:'bg-yale text-timberwolf'})
          })
        }
      }, _err => {
        this.loading = false;
        //console.log(_err);
      })
      // send data with token to backend
      //this.http.post(url,formData) ....
    })

  }

  public ngOnDestroy() {
    this.rcp.destroy();
  }

}

import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { News } from 'src/app/shared/models/news';
import { ApiNewsService } from 'src/app/shared/services/api-news.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-club-page',
  templateUrl: './club-page.component.html',
  styleUrls: ['./club-page.component.scss']
})
export class ClubPageComponent implements OnInit {

  constructor(private apiNews:ApiNewsService,private meta:Meta, private titleService: Title) { }
  rows:News[];
  environment=environment;

  ngOnInit(): void {
    this.apiNews.getNewsPaged(1,'dioikhtika-nea').subscribe(_news=>{
      this.rows=_news.rows;
    })
    this.titleService.setTitle('Σύλλογος - Α.Ο. Κέρκυρα');
    this.meta.addTags([
      { name: 'keywords', content: 'σύλλογος, διοιξητικό συμβούλιο' },
      { name: 'robots', content: 'index, follow' },
      {name:'description',content:'Σύλλογος - Α.Ο. Κέρκυρα'},
      {property:'title', content:'Σύλλογος - Α.Ο. Κέρκυρα'},
      {property:'og:title',content:'Σύλλογος - Α.Ο. Κέρκυρα'},
      {property:'og:image',content:'https://aokerkyra.com/images/ao_kerkyra_1968_400x400.png'}
    ]);  
  }

}

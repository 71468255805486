import { ViewportScroller } from '@angular/common';
import { Component } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router, Scroll } from '@angular/router';
import { SwPush } from '@angular/service-worker';
import { filter } from 'rxjs/operators';
import { AnalyticsService } from './shared/services/analytics.service';
import { NotificationSubscribeServiceService } from './shared/services/notification-subscribe-service.service';
import { PwaUpdateService } from './shared/services/pwa-update.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'aokerkyra';
  readonly VAPID_PUBLIC_KEY = "BLwHXMXHDEUkR2JSb0isfwHMOFGaKq92sh3vUMUBsD2SjoY2ApN722m87sx4jXFZYggmwzFuEcgvm0XsCmbU6ZE";

    constructor(
      private viewportScroller:ViewportScroller, 
      private swPush: SwPush,     
      private router:Router, 
      private route:ActivatedRoute,
      private notifService:NotificationSubscribeServiceService, 
      private gaService:AnalyticsService,
      private pwaUpdateService:PwaUpdateService) {      
      
      
      console.log('%cΚαλωσήλθατε στον A.O. Kέρκυρα ❤️💙!','background: linear-gradient(to right,#5f1a37, #274c77); color: #fff; padding:20px;font-size:30px;');
      
      this.subscribeToNotifications();      
      
      this.swPush.notificationClicks.subscribe((item)=>{               
        if (item.notification.data.url) {
          //top.location.href=item.notification.data.goto;
          this.router.navigateByUrl(item.notification.data.url);
        }
      })

      this.router.events.subscribe(event => {
        if(event instanceof NavigationEnd){
          this.gaService.trackVirtualPageview('page',event.urlAfterRedirects);
         }
      })
      
      this.router.events.pipe(filter((e): e is Scroll => e instanceof Scroll)).subscribe(e => {
        if (e.position) {
          // backward navigation          
          this.viewportScroller.scrollToPosition(e.position);
        } else if (e.anchor) {
          // anchor navigation
          this.viewportScroller.scrollToAnchor(e.anchor);
        } else {
          // forward navigation          
            //console.log(e.routerEvent.url);
            if(!e.routerEvent.url.includes('fans')){
              this.viewportScroller.scrollToPosition([0, 0]);
            }
          }        
         
        })
      }
    

    subscribeToNotifications(){   
        this.swPush.requestSubscription({
            serverPublicKey: this.VAPID_PUBLIC_KEY
        })
        .then((sub) => {
          //console.log(sub);
          this.notifService.addSubscriber({obj:sub}).subscribe(
            _res=>{
              this.gaService.trackEvent('Notfications','subscribed','');
            },
            _err=>console.log(_err))
        }
          )
        .catch(err => console.error("Could not subscribe to notifications", err));
    }
  
}

import { Component, OnInit } from '@angular/core';
import { Match } from 'src/app/shared/models/match';
import { ApiMatchesServiceService } from 'src/app/shared/services/api-matches-service.service';

@Component({
  selector: 'app-today',
  templateUrl: './today.component.html',
  styleUrls: ['./today.component.scss']
})
export class TodayComponent implements OnInit {
  rows:Match[]=[];
  constructor(private matchService:ApiMatchesServiceService) { }
  
  ngOnInit(): void {
    this.matchService.getTodayMatches().subscribe(_res=>{
      this.rows=_res;
    });
  }

}
